import React, { useEffect, useState } from 'react'
import style from './style.module.sass'
import { useTheme } from '@material-ui/core/styles'
import { Done, DoneAll, Add } from '@material-ui/icons'

import closeIcon from '../../../../assets/close-circle.svg'
import { Rnd } from 'react-rnd'
import ButtonWithPop from '../../Overview/ButtonWithPop'
import { useTranslation } from 'react-i18next'
import { convertPipeToNewLine, setAutoPosition, stripHtml } from '../../../../utils'
import config from '../../../../config'
import {
    allowlistText
} from '../../../../actions'
import { useSelector, useDispatch } from 'react-redux'


export default function LanguageToolModal({
    handleChangeInText,
    handleAddToIgnoreList,
    popupText,
    closePopupWindow,
    cumulativeEditor,
    cumulativeElement,
    innerKey
}) {
    const { t } = useTranslation()

    const dispatch = useDispatch()

    // get popover
    const popups = useSelector(state => state.analyseData.popover.popups)
    const currentPopup = useSelector(state => state.analyseData.popover.currentPopup)
    const popupData = popups[currentPopup].data
    const description = popupData?.description || popupData?.category?.description
    const enabledSections = useSelector(state => state.userData.user?.enabled_frontend_sections || [])
    const modalRef = React.createRef()

    let suggestions = popups[currentPopup].data.suggestion || []
    const realPosition = popups[currentPopup].data.position

    const { theme: { bgColors, borderColors, colors } } = useTheme()
    const [valueChanged, setValueChanged] = useState(false)
    const [newText, setNewText] = useState('')

    let left = cumulativeElement.left + 60
    let top = cumulativeElement.top + cumulativeEditor.top

    const [positionX, setPositionX] = useState(left)
    const [positionY, setPositionY] = useState(top)

    useEffect(() => {
        const [newX, newY] = setAutoPosition(
            modalRef.current?.clientHeight,
            modalRef.current?.clientWidth,
            window.innerHeight,
            window.innerWidth,
            top,
            left)
        setPositionY(newY)
        setPositionX(newX)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [left, top, modalRef.current, window.innerHeight, window.innerWidth, suggestions]
    )

    useEffect(() => {
        setNewText(popupText)
        setValueChanged(false)
    }, [popupText]
    )

    useEffect(() => {
        if (newText === popupText)
            setValueChanged(false)
    }, [newText, popupText]
    )

    return (
        // eslint-disable-next-line jsx-a11y/no-access-key
        <Rnd
            onDragStop={(e, d) => {
                setPositionX(d.x)
                setPositionY(d.y)
            }}
            position={{ x: positionX, y: positionY }}
            default={{
                width: 640
            }} style={{ cursor: 'default', zIndex: 1000 }}
            accessKey={config.accessKeys.toolTip}
            tabIndex={0}                                                                     
            key={innerKey}>
            <div ref={modalRef} style={{ backgroundColor: bgColors.bgPrimary0 }} className={`${style.mainCont} pb-3`}>
                <div
                    style={{ backgroundColor: bgColors.bgPrimary300 }}
                    className={`${style.head} d-flex justify-content-between p-1 pl-3 pr-3`}>
                    <p style={{ backgroundColor: bgColors.bgPrimary300 }}
                        className={`m-0 ${style.title}`}>{t('Spelling issues')}</p>
                    <button className={`border-0 bg-transparent pr-1 pl-2`} onClick={() => {
                        closePopupWindow()
                    }} onMouseDown={e => e.stopPropagation()} data-close-modal>
                        <img src={closeIcon} alt="" />
                    </button>
                </div>
                <div
                    className={`${style.fieldPart} d-flex align-items-start justify-content-between pl-3 pr-3 p-1 mt-2 mb-2`}>

                    <input type="text"
                        value={newText}
                        style={{ backgroundColor: bgColors.bgPrimary200 }}
                        onMouseDown={e => e.stopPropagation()}
                        onChange={e => {
                            setValueChanged(true)
                            setNewText(e.target.value)
                        }} />

                    <div className={`d-flex`} style={{ gridGap: '10px' }}>
                        {valueChanged &&
                            <ButtonWithPop
                                text={valueChanged ? t('replace here') : t('ignore here')}
                                typeOfCount={''}
                                valueChanged={valueChanged}
                                onClick={() => {
                                    if (!valueChanged) {
                                        handleAddToIgnoreList(currentPopup, newText, '', 'languageTool', newText, realPosition)
                                    } else {
                                        handleChangeInText(currentPopup, newText, '', 'languageTool')
                                    }
                                    closePopupWindow()
                                }}
                            />
                        }

                        <ButtonWithPop
                            text={valueChanged ? t('replace everywhere in the document') : t('ignore everywhere in the document')}
                            typeOfCount={'all'}
                            valueChanged={valueChanged}
                            onClick={() => {
                                if (!valueChanged) {
                                    handleAddToIgnoreList(currentPopup, newText, popupText, 'languageTool', newText, -1)
                                    //handleAddToIgnoreList('languageTool', newText, -1);
                                } else {
                                    handleChangeInText(currentPopup, newText, popupText, 'languageTool')
                                }
                                closePopupWindow()
                            }}
                        />
                        {enabledSections.indexOf('allowedlist') > -1 ?
                            <ButtonWithPop text={t('add entry to my allowed list')}>
                                <Add style={{ height: '20px' }} />
                                <span style={{ padding: '0 5px 0 0' }}
                                    onClick={() => {
                                        dispatch(allowlistText(newText))
                                        closePopupWindow()
                                    }
                                    }
                                >{t('allowed')}</span>
                            </ButtonWithPop> : null}
                    </div>
                </div>
                <div className={`${style.rows} ml-3 mr-3 m-1`} style={{ borderColor: borderColors.borderPrimary100 }}>
                    {
                        suggestions.map((text, i) => (
                            <div key={i} className={`${style.row} p-1`}
                                style={{ borderColor: borderColors.borderPrimary100 }}>
                                <div className={`d-flex align-items-start`}>
                                    <p className={`m-0`}>{text.value}</p>
                                </div>
                                <div className={`d-flex align-items-center`} style={{ gridGap: '5px' }}>
                                    <ButtonWithPop
                                        text={t('replace here')}
                                        typeOfCount={''}
                                        valueChanged={valueChanged}
                                        onClick={() => {
                                            handleChangeInText(currentPopup, text.value, '', 'languageTool')
                                            closePopupWindow()
                                        }}

                                    >
                                        <Done style={{ fill: colors.colorProgress100, height: 24 }} />
                                    </ButtonWithPop>
                                    <ButtonWithPop
                                        text={t('replace everywhere in the document')}
                                        typeOfCount={''}
                                        valueChanged={valueChanged}
                                        onClick={() => {
                                            handleChangeInText(currentPopup, text.value, popupText, 'languageTool')
                                            closePopupWindow()
                                        }}
                                    >
                                        <DoneAll style={{ fill: colors.colorProgress100, height: 24 }} />
                                    </ButtonWithPop>
                                </div>
                            </div>
                        ))
                    }
                </div>
                {description &&
                    <div style={{
                        backgroundColor: 'rgba(206, 224, 255, .20)',
                        borderColor: borderColors.borderPrimary200
                    }}
                        className={`${style.desc} ml-3 mt-3 mr-3 p-1`}
                        dangerouslySetInnerHTML={{ __html: stripHtml(convertPipeToNewLine(description, true), config.dangerousTags) }} />
                }
            </div>

        </Rnd>
    )
}
