import { Button, Modal } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
    analyseSetAiMode,
    userSaveOption,
    userSetOption,
} from "../../../../actions";
import closeIcon from "../../../../assets/close-circle.svg";
import ComBotGreen from "../../../../assets/combot-green.svg"
import ComBotGrey from "../../../../assets/combot-grey.svg"
import TLServiceComponent from "../../../../contexts/TLServiceComponent";
import { useActions } from "../../../../utils/action-helper";
import AnalyseButton from "./AnalyseButton";
import News from "./News";
import style from "./style.module.sass";
import { checkAiText } from "../../../../utils"
import AiButton from "./AiButton"
import ComponentWithPop from "../../../common/ComponentWithPop"
import config from "../../../../config"

export default function InitialTab({
  handleOnAnalyseClick,
  analyseButtonEnable,
  setAnalyseButtonEnable
}) {
  const {
    theme: { bgColors },
  } = useTheme();
  const { t } = useTranslation();
  const [displayModal, setDisplayModal] = useState(false);
  const [onHover, setOnHover] = useState(false);
  const token = useSelector((state) => state.userData.token);
  const userId = useSelector((state) => state.userData.user.id);
  const enabledSections = useSelector(
    (state) => state.userData.user?.enabled_frontend_sections || []
  );
  const aiMode = useSelector((state) => state.analyseData.aiMode)
  const text = useSelector((state) => state.analyseData.text)
  const aiMaxTextSize = useSelector((state) => state.userData.user.ai_settings?.max_text_size || {})

  const tlService = React.useContext(TLServiceComponent);
  const { actionUserSaveOption, actionUserSetOption, aAnalyseSetAiMode } =
    useActions({
      actionUserSetOption: userSetOption,
      actionUserSaveOption: userSaveOption(tlService),
      aAnalyseSetAiMode: analyseSetAiMode,
    });

    const aiButtonEnable = checkAiText(text, aiMaxTextSize, 'Assistant')

    const handleOnAiClick = () => {
      if (aiButtonEnable) {
        aAnalyseSetAiMode(!aiMode)
      }
    }  

  return (
    <div className={`${style.mainCont}`}>
      <Modal open={displayModal} onClose={() => setDisplayModal(false)} style={{ width: '50%', margin: '0 auto', marginTop: '20%', height: '300px' }}>
        <div className={`${style.modalMainCont} pb-3`} style={{ backgroundColor: bgColors.bgPrimary0 }}>
          <div className={`${style.head} d-flex justify-content-between p-1 pl-3`}
            style={{ backgroundColor: bgColors.bgPrimary400 }}>
            <p className={`m-0 ${style.title}`} style={{ color: 'white', fontSize: '20px' }}>
              <button
                style={{position: "absolute", right: 5, top : 0}}
                className={`border-0 bg-transparent`} onClick={() => setDisplayModal(false)}>
                <img src={closeIcon} alt="" />
              </button>
              <br/>
              <p style={{marginTop: "-10px"}}>
                            {t('setting-admin')}
                            </p>
            </p>
          </div>
          <br />
          <div>
            <Button color='primary' variant='contained'
              onClick={() => {
                setDisplayModal(false);
                actionUserSetOption('tonality_visibility', false);
                actionUserSaveOption(token, userId, 'tonality_visibility', "false")
                            }
                            }
              style={{ marginLeft: '40%', marginTop: '4%' }}>
              {t('yes')}
            </Button>
            <Button color='primary' variant='contained'
              onClick={() => { setDisplayModal(false); }}
              style={{ marginLeft: '4%', marginTop: '4%' }}>
              {t('no')}
            </Button>
          </div>
        </div>
      </Modal>
      <AnalyseButton
        handleOnAnalyseClick={handleOnAnalyseClick}
        analyseButtonEnable={analyseButtonEnable}
        setAnalyseButtonEnable={setAnalyseButtonEnable}
        tabIndex={config.tabIndexes.analyseButton}
      />
      {enabledSections.indexOf("double-interface") > -1 && (
        aiButtonEnable ? (
          <AiButton
            onClick={handleOnAiClick}
            onHover={setOnHover}
            backgroundColor={onHover ? bgColors.bgPrimary200 : bgColors.bgPrimary0}
            cursor="pointer"
            imageSrc={ComBotGreen}
            tabIndex={config.tabIndexes.ainsteinButton}
          />
        ) : (
          <ComponentWithPop text={t("ai_text_too_long")}>
            <AiButton
              onClick={handleOnAiClick}
              enabled={false}
              backgroundColor="#e0e0e0"
              cursor="not-allowed"
              imageSrc={ComBotGrey}
            />
          </ComponentWithPop>
        )
      )}
      <News />
    </div>
  )
}
