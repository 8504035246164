import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {  Paper } from '@material-ui/core'
import TLServiceComponent from "../../../../contexts/TLServiceComponent"
import { useSelector } from "react-redux"
import { checkUserRight, convertJSONToObject, convertLanguagesToShort, onlyUnique } from "../../../../utils"
import { useActions } from "../../../../utils/action-helper"
import {
  adminGetAllGroups,
  clearAllGroups,
  tableSetData, tableSetDefaults,
  tableSetOtherData,
  tableSetPages,
  tableSetRowCount, tableSetSelectAll, tableSetSelectAllIds
} from "../../../../actions"
import Spinner from "../../../Spinner"
import UniversalTable from "../../../common/UniversalTable"
import TableMassActionComponent from "./TableMassActionComponent/TableMassActionComponent"
import TableInnerComponent from "./TableInnerComponent/TableInnerComponent"
import style from './table.module.sass'
import { Redirect } from "react-router-dom"
import UserProfileWrapper from "../../UserProfileWrapper/UserProfileWrapper"
import TerminologyAddEditModal from "./TerminologyAddEditModal"
import siteLanguages from "../../../../config/sitelanguages.json"
import AddNew from "./AddNew"


function createData(id, category_type, parent_id, description, name, itemCount, settings, rights, global_visible, locale_name, owner) {
  return { id, category_type, parent_id, description, name, itemCount, settings, rights, global_visible, locale_name, owner }
}

export default function TermTable() {

  const { t, i18n: { language: i18nLanguage } } = useTranslation()
  const tlService = useContext(TLServiceComponent)
  const archiveLanguage = useSelector(state => state.userData.archiveLanguage)
  const token = useSelector(state => state.userData.token)

  const filters = useSelector(state => state.tableData.filters)
  const limit = useSelector(state => state.tableData.limit)
  const order = useSelector(state => state.tableData.order || 'asc')
  const orderBy = useSelector(state => state.tableData.orderBy || 0)
  const currentPage = useSelector(state => state.tableData.currentPage)
  const selectAll = useSelector(state => state.tableData.selectAll)
  const rowCount = useSelector(state => state.tableData.rowCount)
  const selectedCompany = useSelector(state => state.tableData.companyId)
  const selectedGroup = useSelector(state => state.tableData.groupId)
  const user = useSelector(state => state.userData.user)
  const textDisabledColumns = useSelector(state => state.userData.user?.user_options?.disabled_term_cats_columns || '[]')

  const disabledColumns = convertJSONToObject(textDisabledColumns, [])

  const {
    aTableSetDefaults,
    aTableSetData,
    aTableSetOtherData,
    aTableSetRowCount, aTableSetSelectAllIds,
    aTableSetPages, aTableSetSelectAll, aAdminClearAllGroups, aAdminGetAllGroups
  } = useActions({
    aTableSetData: tableSetData,
    aTableSetOtherData: tableSetOtherData,
    aTableSetRowCount: tableSetRowCount,
    aTableSetPages: tableSetPages,
    aTableSetDefaults: tableSetDefaults,
    aTableSetSelectAll: tableSetSelectAll,
    aTableSetSelectAllIds: tableSetSelectAllIds,
    aAdminClearAllGroups: clearAllGroups,
    aAdminGetAllGroups: adminGetAllGroups(tlService)
  })

  const [spinner, setSpinner] = useState(false)
  const [terminologyModal, setTerminologyModal] = useState(false)
  const [currentTerm, setCurrentTerm] = useState()
  const [visibility, setVisibility] = useState(false)
  const [mainBox, setMainBox] = useState([])
  const [categoryType, setCategoryType] = useState('')
  const [color, setColor] = useState("")
  const [letterRows, setLetterRows] = useState([])

  const defaultPagination = [
    100,
    500
  ]
  const defaultColumns = [
    {
      id: 'category_type',
      type: 'string',
      disablePadding: false,
      label: `${t('admin_texttypes_column-category_type')}`,
      filter_value: 'category_type',
      orderBy_value: 'category_type',
      translate: true
    },
    {
      id: 'language',
      type: 'string',
      disablePadding: false,
      label: `${t('admin_term_column-language')}`,
      filter_value: 'locale_name',
      orderBy_value: 'locale_name'
    },
    {
      id: 'name',
      type: 'string',
      disablePadding: false,
      label: `${t('admin_texttypes_column-name')}`,
      filter_value: 'name',
      orderBy_value: 'name'
    },
    {
      id: 'made_for',
      type: 'string',
      disablePadding: false,
      label: `${t('admin_term_column-made_for')}`,
      filter_value: 'parent_id',
      orderBy_value: 'parent_id'
    },
    {
      id: 'term_count',
      type: 'term_count',
      disablePadding: false,
      label: `${t('admin_term_term-count')}`,
      filter_value: 'term_count',
      orderBy_value: 'term_count'
    },
    {
      id: 'update_time',
      type: 'date',
      disablePadding: false,
      label: `${t('admin_term_update-time')}`,
      filter_value: 'update_time',
      orderBy_value: 'update_time'
    }
  ]
  const defaultFilters = [
    {
      id: 'company_id',
      name: 'Company',
      type: 'catalog',
      searchField: 'company_id',
      searchTemplate: '={value}',
      source: 'companies',
      label: t('Company')
    },
    {
      id: 'group_id',
      name: 'Group',
      type: 'catalog',
      searchField: 'user_group_id',
      searchTemplate: '={value}',
      source: 'groups',
      label: t('Groups')
    },
  ]

  const extraSettings = {
    language: archiveLanguage,
    fullSearchFilter: [],
  }


  // on mount
  useEffect(() => {
    aTableSetDefaults(defaultPagination, defaultFilters, disabledColumns, defaultColumns, 'asc', 'parent_id')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [archiveLanguage])

  useEffect(() => {
   
    if (selectedCompany) {
      aAdminGetAllGroups(token, selectedCompany)
    } else {
      aAdminClearAllGroups()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompany])
  
  const runRequest = async (limit, requestLimits, requestFilters) => {
    // make a request
    try {
      const res = await tlService.getFilteredTermCategories(token,requestLimits, requestFilters)
      if (res?.data?.length) {
        const currentData = res.data.map(d => {
          return { ...d, opened: false, language: d.locale_name ? (siteLanguages[convertLanguagesToShort(d.locale_name)].payloadName !== undefined
            ? t(siteLanguages[convertLanguagesToShort(d.locale_name)].name) :  "") :  "", made_for: d.parent_id === 0 ? t("admin_term-cats_column-our_company") :  t("admin_term-cats_column-all")}
        })
        aTableSetData(currentData)
        aTableSetOtherData({ terms_fullcount: res.fullcount})
        aTableSetRowCount(res.data.length)
        if (limit > 0) {
          aTableSetPages(Math.ceil(res.fullcount / limit))
        }
      }
      if(res?.data?.length){
        let arry = []
      res?.data.forEach(el => {
        if (el !== undefined) {
          arry.push(createData(el?.id, el?.category_type, el?.parent_id, el?.description, el?.name, el?.term_count, el?.settings, el?.rights, el?.global_visible, el?.locale_name, el?.owner))
        }
      })
      setLetterRows([...arry])
      }
      return true
    } catch (e) {
      console.log(e)
    }
    aTableSetData([])
    aTableSetOtherData({})
    aTableSetRowCount(0)
  }

  const prepareRequest = ( limit, order, orderBy, currentPage, filters) => {
    const requestLimits = {
      limit,
      order: order === 'asc' ? 0 : 1,
      order_by: orderBy ? orderBy : 'parent_id',
      offset: limit * (currentPage - 1),
    }
    const requestFilters = {
      name: filters?.full_text_search_filter?.value && `${i18nLanguage}%${filters?.full_text_search_filter?.value}%`,
      company_id: selectedCompany > 0 ? selectedCompany : undefined,
      user_group_id: selectedGroup > 0 ? selectedGroup : undefined,
    }
    return { requestLimits, requestFilters }
  }


  const makeRequest = async ( limit, order, orderBy, currentPage, filters) => {
    const {
      requestLimits,
      requestFilters,
    } = prepareRequest( limit, order, orderBy, currentPage, filters)
    await runRequest(limit, requestLimits, requestFilters)
  }

  const getAllIds = async ( fullCount, limit, order, orderBy, currentPage, filters, maxChunk) => {
    const ids = []
    const {
      requestLimits,
      requestFilters
    } = prepareRequest( limit, order, orderBy, currentPage, filters)
    for (let offset = 0; offset < fullCount; offset += maxChunk) {
      const currLimit = { ...requestLimits, limit: maxChunk, offset }
      try {
        const res = await tlService.getFilteredTermCategories(token, currLimit, requestFilters)
        if (res?.data?.length) {
          for (const re of res.data) {
            ids.push(re.id)
          }
        }
      } catch (e) {
        console.log(e)
      }
    }
  
    return ids.filter(onlyUnique)
  }

  const reloadPage = () => {
    reloadAll(limit, order, orderBy, currentPage, filters, setSpinner)
  }

  function reloadAll( limit, order, orderBy, currentPage, filters, setSpinner) {
    setSpinner(true)
    makeRequest( limit, order, orderBy, currentPage, filters).then(() => setSpinner(false))
  }

  // filter if change
  useEffect(() => {
    reloadAll(limit, order, orderBy, currentPage, filters, setSpinner)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filters), limit, order, orderBy, currentPage, selectedCompany, selectedGroup])

  const handleSelectAll = async () => {
    setSpinner(true)
    const fullCount = rowCount || 0
    if (!selectAll) {
      const allIds = await getAllIds(fullCount, limit, order, orderBy, currentPage, filters, 1000)
      if (allIds?.length) {
        aTableSetSelectAllIds(allIds)
        aTableSetSelectAll(true)
      }
    } else {
      aTableSetSelectAllIds([])
      aTableSetSelectAll(false)
    }
    setSpinner(false)
  }

  if (!checkUserRight(user, [701, 702, 703]))
    return <Redirect to={"/"} />

  return (
    <UserProfileWrapper>

      <div className={`${style.mainCont}`}>
        <Paper className={`mb-5`}>
          {spinner && <Spinner />}
          <TerminologyAddEditModal
            visibility={visibility}
            setVisibility={setVisibility}
            color={color}
            setColor={setColor}
            mainBox={mainBox}
            setMainBox={setMainBox}
            callEndPoint={reloadPage}
            currentTerm={currentTerm}
            open={terminologyModal}
            setOpen={setTerminologyModal}
            categoryType={categoryType}
            setCategoryType={setCategoryType}
            letterRows={letterRows}
          />
        <div style={{ float: 'right', marginBottom: '10px', marginRight: '8px' }}>
        {checkUserRight(user, [701, 702, 703]) &&
                <AddNew
                  visibility={visibility}
                  setVisibility={setVisibility}
                  color={color}
                  setColor={setColor}
                  categoryType={categoryType}
                  setCategoryType={setCategoryType}
                  mainBox={mainBox}
                  setMainBox={setMainBox}
                  setCurrentTerm={setCurrentTerm}
                  setTerminologyModal={setTerminologyModal}
                />
              }
        </div>
          <UniversalTable 
            defaultColumns={defaultColumns}
            reloadAll={reloadAll}
            handleSelectAll={handleSelectAll}
            getAllIds={getAllIds}
            noDataText={t('admin_term_no-data')}
            selectAllText={t('admin_term_select-all-terms')}
            MassActionComponent={TableMassActionComponent}
            InnerComponent={TableInnerComponent}
            extraSettings={extraSettings}
            style={style}
            type='term_cats'
            setTerminologyModal={setTerminologyModal}
            setCurrentTerm={setCurrentTerm}
            callEndPoint={reloadPage}
            enableExtraFilters
          />
        </Paper>
      </div>
      </UserProfileWrapper>
  )
}