import style from "./style.module.sass";
import SemiCircleProgressBar from "react-progressbar-semicircle";
import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@material-ui/core/styles";
import { convertNumberToStringLocally } from "../../../utils";
import config from "../../../config"

export default function HIXWrapper({ hix, target }) {
    const { theme: { borderColors, colors } } = useTheme();
    const { t, i18n: { language: i18nLanguage } } = useTranslation();
    const percentage = hix * 5;
    const color = hix < target / 2 ? "#dd0d08" : hix < target ? "#da7d27" : "#a8c721";
    
    return (
        <div className={style.bars} title={t("accessibility_analysis_hix")} tabIndex={config.tabIndexes.hixWrapper}>
            <p className={style.bar_title} style={{ color: colors.colorPrimary500 }}>{t("Readability")}</p>
            <SemiCircleProgressBar diameter={174} percentage={percentage} background={"#ffffff"} stroke={color} strokeWidth={15} />
            <div className={style.progressDesc}>
                <p style={{ color: colors.colorPrimary500 }} className={`m-0 ${style.title}`}>{t("HIX_analysis_minidash")}</p>
                <p className={`m-0 ${style.percentage}`} style={{ color: colors.colorPrimary1000 }}>{convertNumberToStringLocally(hix, i18nLanguage)}</p>
                <div style={{ borderColor: borderColors.borderPrimary1000 }} className={`${style.maxSize} d-flex justify-content-center`}>
                    <p style={{ color: colors.colorPrimary500 }} className={`m-0 ${style.zielwert}`}>{t("Target value")}:</p>
                    <p className={`m-0 ${style.zielwertNum} ml-1`}>{convertNumberToStringLocally(target, i18nLanguage)}</p>
                </div>
            </div>
        </div>
    );
}