import React from "react";
import style from './style.module.sass'

import ComponentWithPop from '../../common/ComponentWithPop'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@material-ui/core/styles'
import { Close } from "@material-ui/icons"
import config from "../../../config"

export default function AiHead({ handleDiscardText }) {
  const { t } = useTranslation();
  const { theme: { colors } } = useTheme()

  return (
    <div className={`${style.aiHeader}`}>
      <div style={{ color: colors.colorPrimary900 }} className={`d-inline-block`} onClick={handleDiscardText} tabIndex={config.tabIndexes.aiDiscardButton}>
          <ComponentWithPop text={`${t('ai_reject_text_tt')}`} >
            <button style={{ backgroundColor: "#bf6a86" }} className={`d-flex ${style.textback}`}>
              <p className={`m-0 pl-1`} style={{ cursor: "pointer" }} >
                {t('ai_reject_text')}
              </p>
              <Close style={{ cursor: "pointer", marginLeft: 8 }} />

            </button>
          </ComponentWithPop>
      </div>

    </div>
  )

}