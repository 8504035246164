import { appendFilters, appendLimits, getResource } from '../utils'

export default class TLService {

  _apiBase = ''

  static localGetAPIBase = async () => {
    try {
      const response = await fetch('/config/backend_connection.json', {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
      const backendConfig = await response.json()
      return backendConfig?.textLabApiBase || process.env.REACT_APP_TL_API_BASE
    } catch (e) {
      return process.env.REACT_APP_TL_API_BASE
    }
  }

  localGetResource = async (url, method, body, token, json, extraHeaders) => {
    if (!this._apiBase) {
      this._apiBase = await TLService.localGetAPIBase()
    }
    return await getResource(this._apiBase, url, method, body, token, json, extraHeaders)
  }

  /* for tables! */

  /* Archive folders*/
  getFilteredArchiveFolders = async (token, limits = {}, filters = {}) => {
    let filter = new URLSearchParams()
    filter = appendLimits(limits, filter)
    filter = appendFilters(filters, filter)
    return await this.localGetResource('/archive/folder?' + filter.toString(), 'GET', null, token)
  }

  addArchiveFolder = async (token, body) => {
    return await this.localGetResource('/archive/folder', 'post', body, token)
  }

  editArchiveFolder = async (token, id, body) => {
    return await this.localGetResource(`/archive/folder/${id}`, 'put', body, token)
  }

  deleteArchiveFolder = async (token, id) => {
    return await this.localGetResource(`/archive/folder/${id}`, 'delete', null, token)
  }

  /* Archive folders*/

  getFilteredArchive = async (token, limits = {}, filters = {}) => {
    let filter = new URLSearchParams()
    filter = appendLimits(limits, filter)
    filter = appendFilters(filters, filter)
    return await this.localGetResource('/archive?' + filter.toString(), 'GET', null, token)
  }

  getArchiveStatistic = async (token, id_list, return_values, order, order_by, locale_name) => {
    return await this.localGetResource('/archive/statistic', 'POST', {
      id_list,
      return_values,
      order,
      order_by,
      locale_name
    }, token)
  }

  deleteFilteredArchiveFolders = async (token, id) => {
    return await this.localGetResource(`/archive/folder/${id}`, 'delete', null, token)
  }

  addPersonalArchiveFolders = async (token, folder_name, parent, user_id) => {
    return await this.localGetResource('/archive/folder', 'post', {
      folder_name, parent, user_id
    }, token)
  }

  addGroupArchiveFolders = async (token, folder_name, parent, user_group_id) => {
    return await this.localGetResource('/archive/folder', 'post', {
      folder_name, parent, user_group_id
    }, token)
  }

  addFirmaArchiveFolders = async (token, folder_name, parent, company_id) => {
    return await this.localGetResource('/archive/folder', 'post', {
      folder_name, parent, company_id
    }, token)
  }

  editFilteredArchiveFolders = async (token, id, folder_name, parent) => {
    return await this.localGetResource(`/archive/folder/${id}`, 'put', {
      folder_name, parent
    }, token)
  }

  getFilteredArchiveTags = async (token, limits = {}, filters = {}) => {
    let filter = new URLSearchParams()
    filter = appendLimits(limits, filter)
    filter = appendFilters(filters, filter)
    return await this.localGetResource('/archive/tag?' + filter.toString(), 'GET', null, token)
  }

  addUserArchives = async (token, name, version, benchmark_id, text, result, settings, hix, clix, folder, locale_name, tags, user_id) => {
    return await this.localGetResource(`/archive`, 'post', {
      name, version, benchmark_id, text, result, settings, folder, tags, user_id
    }, token)
  }

  addGroupArchives = async (token, name, version, benchmark_id, text, result, settings, hix, clix, folder, locale_name, tags, user_group_id) => {
    return await this.localGetResource(`/archive`, 'post', {
      name, version, benchmark_id, text, result, settings, folder, tags, user_group_id
    }, token)
  }

  addCompanyArchives = async (token, name, version, benchmark_id, text, result, settings, hix, clix, folder, locale_name, tags, company_id) => {
    return await this.localGetResource(`/archive`, 'post', {
      name, version, benchmark_id, text, result, settings, folder, tags, company_id
    }, token)
  }

  benchmarkExportData = async (token, comment, template, result) => {
    return await this.localGetResource(`/benchmark/export/doc`, 'post', {
      comment, template, result
    }, token)
  }

  // Users:
  getUser = async (token, limits = {}, filters = {}) => {
    let filter = new URLSearchParams()
    filter = appendLimits(limits, filter)
    filter = appendFilters(filters, filter)
    return await this.localGetResource('/user?' + filter.toString(), 'GET', null, token)
  }

  // Archive:
  archiveExcelValues = async (token, language, id_list) => {
    return await this.localGetResource(`/archive/export/values`, 'post', {
      language, id_list
    }, token)
  }

  archiveExportExcel = async (token, template, values, id_list) => {
    return await this.localGetResource(`/archive/export/excel`, 'post', {
      template, values, id_list
    }, token)
  }

  archiveWordExportData = async (token, comment, template, values, id_list) => {

    return await this.localGetResource(`/archive/export/doc`, 'post', {
      comment, template, values, id_list
    }, token)
  }

  getArchive = async (token, limits = {}, filters = {}) => {
    filters.return_values = JSON.stringify(['id', 'analyse_id', 'archive_name', 'owner', 'version', 'result.formulaHix', 'result.formulaCLIX', 'result.formulaSPIX', 'tag_name', 'folder_id', 'benchmark_id', 'user_id', 'user_group_id', 'company_id', 'create_time', 'update_time', 'delete_time'])
    filters.by_last = 1
    filters.exact_result = 0
    return await this.getFilteredArchive(token, limits, filters)
  }

  getArchiveVersion = async (token, limits = {}, filters = {}) => {
    filters.return_values = JSON.stringify(['id', 'version', 'tag_name'])
    filters.by_last = 0
    filters.exact_result = 1
    return await this.getFilteredArchive(token, limits, filters)
  }

  getArchiveResult = async (token, limits = {}, filters = {}) => {
    filters.return_values = JSON.stringify(['id', 'result', 'archive_name', 'benchmark_id', 'result.formulaSPIX'])
    filters.by_last = 1
    filters.exact_result = 0
    return await this.getFilteredArchive(token, limits, filters)
  }

  getArchiveById = async (token, id) => {
    return await this.localGetResource('/archive/' + id, 'GET', null, token)
  }

  addArchivesVersion = async (token, analyse_id, version, benchmark_id, text, result, settings, hix, clix, folder, tags) => {
    return await this.localGetResource(`/archive/analyse/${encodeURIComponent(analyse_id)}`, 'post', {
      version, benchmark_id, text, result, settings, folder, tags
    }, token)
  }

  editArchive = async (token, analyse_id, body) => {
    return await this.localGetResource(`/archive/analyse/${encodeURIComponent(analyse_id)}`, 'put', body, token)
  }

  editArchiveVersion = async (token, analyse_id, version, body) => {
    return await this.localGetResource(`/archive/version/${encodeURIComponent(analyse_id)}/${encodeURIComponent(version)}`, 'put', body, token)
  }

  deleteArchive = async (token, analyse_id) => {
    return await this.localGetResource(`/archive/analyse/${encodeURIComponent(analyse_id)}`, 'delete', null, token)
  }

  deleteArchiveVersion = async (token, analyse_id, version) => {
    return await this.localGetResource(`/archive/version/${encodeURIComponent(analyse_id)}/${encodeURIComponent(version)}`, 'delete', null, token)
  }

  getFilteredTermReplacement = async (token, limits = {}, filters = {}) => {
    if (filters['category_id']) {
      let filter = new URLSearchParams()
      filter = appendLimits(limits, filter)
      filter = appendFilters(filters, filter)
      filter.delete('category_id')
      filter.delete('term_id')
      console.log('/term/replacement?' + filter.toString())
      return await this.localGetResource(`/term/replacement/${filters['category_id']}/${filters['term_id']}?` + filter.toString(), 'GET', null, token)
    }
    return false
  }

  getFilteredUsers = async (token, limits = {}, filters = {}) => {
    let filter = new URLSearchParams()
    filter = appendLimits(limits, filter)
    filter = appendFilters(filters, filter)
    //  console.log('getFilteredUsers','/user?' + filter.toString())
    return await this.localGetResource('/user?' + filter.toString(), 'GET', null, token)
  }

  getFilteredGroups = async (token, limits = {}, filters = {}) => {
    let filter = new URLSearchParams()
    filter = appendLimits(limits, filter)
    filter = appendFilters(filters, filter)
    //  console.log('getFilteredGroups', '/usergroup?' + filter.toString())
    return await this.localGetResource('/usergroup?' + filter.toString(), 'GET', null, token)
  }

  getFilteredCompanies = async (token, limits = {}, filters = {}) => {
    let filter = new URLSearchParams()
    filter = appendLimits(limits, filter)
    filter = appendFilters(filters, filter)
    return await this.localGetResource('/company?' + filter.toString(), 'GET', null, token)
  }

  getFilteredTermCategories = async (token, limits = {}, filters = {}) => {
    let filter = new URLSearchParams()
    filter = appendLimits(limits, filter)
    filter = appendFilters(filters, filter)
    return await this.localGetResource('/term_category?' + filter.toString(), 'GET', null, token)
  }

  getFilteredTermCategoriesName = async (token, limits = {}, filters = {}) => {
    filters.return_values = JSON.stringify(['id', 'name'])
    let filter = new URLSearchParams()
    filter = appendLimits(limits, filter)
    filter = appendFilters(filters, filter)
    return await this.localGetResource('/term_category?' + filter.toString(), 'GET', null, token)
  }

  getFilteredTermByCategory = async (token, limits = {}, filters = {}) => {
    if (filters['category_id']) {
      let filter = new URLSearchParams()
      filter = appendLimits(limits, filter)
      filter = appendFilters(filters, filter)
      filter.delete('category_id')
      return await this.localGetResource(`/term/bycategory/${filters['category_id']}?` + filter.toString(), 'GET', null, token)
    }
    return false
  }

  /* for tables! */

  /*Allowed List*/

  getFilteredAllowedList = async (token, limits = {}, filters = {}) => {
    let filter = new URLSearchParams()
    filter = appendLimits(limits, filter)
    filter = appendFilters(filters, filter)
    return await this.localGetResource('/allowlist?' + filter.toString(), 'GET', null, token)
  }

  editAllowedList = async (token, id, words, lemma, tag, term_category_id, check_words, user_id, description, settings) => {

    return await this.localGetResource(`/allowlist/${id}`, 'put', {
      words, lemma, tag, term_category_id, check_words, user_id, description, settings
    }, token)
  }

  updateWhitelist = async (token, id, state) => {
    return await this.localGetResource(`/allowlist/${id}`, 'put', {
      state
    }, token)
  }

  deleteAllowedList = async (token, id) => {
    return await this.localGetResource(`/allowlist/${id}`, 'delete', null, token)
  }

  addAllowedList = async (token, words, lemma, tag, term_category_id, check_words, user_id, description, settings) => {
    return await this.localGetResource(`/allowlist`, 'post', {
      words, lemma, tag, term_category_id, check_words, user_id, description, settings
    }, token)
  }

  /* Allowed List */

  //*  text tone * //

  addTextTone = async (token, words, lemma, tag, term_category_id, check_words, user_id, replacements, description, settings) => {
    return await this.localGetResource(`/textbin`, 'post', {
      words, lemma, tag, term_category_id, check_words, user_id, replacements, description, settings
    }, token)
  }

  getFilteredTextbin = async (token, limits = {}, filters = {}) => {
    let filter = new URLSearchParams()
    filter = appendLimits(limits, filter)
    filter = appendFilters(filters, filter)
    return await this.localGetResource('/textbin?' + filter.toString(), 'GET', null, token)
  }

  deleteTextbin = async (token, id) => {
    return await this.localGetResource(`/textbin/${id}`, 'delete', null, token)
  }

  updateTextbin = async (token, id, state) => {
    return await this.localGetResource(`/textbin/${id}`, 'put', {
      state
    }, token)
  }

  editTextbin = async (token, id, words, lemma, tag, term_category_id, check_words, user_id, description, replacements, settings) => {

    return await this.localGetResource(`/textbin/${id}`, 'put', {
      words, lemma, tag, term_category_id, check_words, user_id, description, replacements, settings
    }, token)
  }

  //*  text tone * //

  /* thin client */

  forgotPassword = async (language, email) => {
    return await this.localGetResource('/user/forgot-password', 'POST', { language, email }, null, true)
  }

  resetPassword = async (language, password, code) => {
    return await this.localGetResource('/user/reset-password', 'POST', { language, password, code }, null, true)
  }

  /* thin client */

  /* TermCategory */
  addTermCategory = async (token, locale_name, name, description, category_type, settings, global_visible, parent_id) => {
    return await this.localGetResource(`/term_category`, 'post', {
      locale_name, name, description, category_type, settings, global_visible, parent_id
    }, token)
  }

  updateTermCategory = async (token, id, locale_name, name, description, category_type, settings, global_visible) => {
    return await this.localGetResource(`/term_category/${id}`, 'put', {
      locale_name, name, description, category_type, settings, global_visible
    }, token)
  }

  deleteTermCategory = async (token, id, clear = 0) => {
    let filter = new URLSearchParams()
    clear && filter.append('clear', clear.toString())
    return await this.localGetResource(`/term_category/${id}?` + filter.toString(), 'delete', null, token)
  }

  calculateCategory = async (token, id) => {
    return await this.localGetResource(`/term_category/${id}/calculate`, 'get', null, token)
  }

  addTermCategoryUGC = async (token, term_category_id, user_id, user_group_id, company_id) => {
    return await this.localGetResource(`/term_category/ugc`, 'post', {
      term_category_id,
      user_id,
      user_group_id,
      company_id
    }, token)
  }

  deleteTermCategoryUGC = async (token, category_id, uid, gid, cid) => {
    return await this.localGetResource(`/term_category/ugc/${category_id}/${uid}/${gid}/${cid}`, 'delete', null, token)
  }

  /* Term */
  addTerm = async (token, category_id, words, lemma, tag, check_words, settings, description, global_visible = 1) => {
    // enabled = true,
    return await this.localGetResource(`/term/${category_id}`, 'post', {
      words, lemma, tag, check_words,
      // enabled,
      global_visible, description, settings
    }, token)
  }

  editTerm = async (token, id, category_id, words, lemma, tag, check_words, description, global_visible = true) => {
    return await this.localGetResource(`/term/${category_id}/${id}`, 'put', {
      check_words, global_visible, description, words, lemma, tag,
    }, token)
  }

  deleteTerm = async (token, category_id, id) => {
    return await this.localGetResource(`/term/${category_id}/${id}`, 'delete', null, token)
  }

  /* TermReplacement */
  deleteTermReplacement = async (token, replacement_id, term_id, category_id) => {
    return await this.localGetResource(`/term/replacement/${category_id}/${term_id}/${replacement_id}`, 'delete', null, token)
  }

  addTermReplacement = async (token, term_id, category_id, words, lemma, tag,
    description = undefined, global_visible = 1,
    settings = undefined, status = undefined,
    term_global_visible = undefined, term_enabled = undefined,
    term_settings = undefined, term_check_words = undefined, term_description = undefined
  ) => {
    return await this.localGetResource(`/term/replacement/${category_id}/${term_id}`, 'post', {
      words, lemma, tag, status, description, settings, global_visible,
      term_global_visible, term_enabled, term_settings, term_check_words, term_description
    }, token)
  }

  /* User */
  loginUser = async (identifier, password, ttl = 0) => {
    return await this.localGetResource('/user/login', 'POST', { identifier, password, ttl })
  }

  loginUserLDAP = async (serviceId, ttl = 0) => {
    return await this.localGetResource('/user/login', 'POST', {
      identifier: 'ldap_login',
      password: 'ldap_password', ttl
    }, null, true, { 'Service-Id': serviceId })
  }

  loginUserShibboleth = async (serviceId, ttl = 0) => {
    return await this.localGetResource('/user/login', 'POST', {
      identifier: 'Shibboleth_login',
      password: 'Shibboleth_password', ttl
    }, null, true, { 'Service-Id': serviceId })
  }

  meUser = async (token) => {
    return await this.localGetResource('/user/me', 'GET', null, token)
  }

  deleteUser = async (token, id) => {
    return await this.localGetResource(`/user/${id}`, 'DELETE', null, token)
  }

  addUser = async (token, user_group_id, country_id, user_type_id, language_id, user_name, email, password, first_name,
    last_name, street, house_number, zip_code, city, enabled, license_id) => {
    return await this.localGetResource(`/user`, 'post', {
      user_group_id, country_id, user_type_id, language_id, user_name,
      email, password, first_name, last_name, street,
      house_number, zip_code, city, enabled, license_id
    }, token)
  }

  updateUser = async (token, id, {
    user_group_id,
    country_id,
    user_type_id,
    language_id,
    user_name,
    email,
    password,
    first_name,
    last_name,
    street,
    house_number,
    zip_code,
    city,
    enabled,
    license_id,
    old_password = ''
  }) => {
    return await this.localGetResource(`/user/${id}`, 'put', {
      user_group_id, country_id, user_type_id, language_id, user_name,
      email, password, first_name, last_name, street,
      house_number, zip_code, city, enabled, license_id, old_password
    }, token)
  }

  addUserOption = async (token, id, option, value) => {
    value = typeof value === 'object' ? JSON.stringify(value) : value
    return await this.localGetResource(`/user/${id}/option`, 'post', { option, value }, token)
  }

  addUserRight = async (token, id, right_id, user_group_id) => {
    return await this.localGetResource(`/user/${id}/right`, 'post', { right_id, user_group_id }, token)
  }

  deleteUserRight = async (token, id, right_id = null, user_group_id = null) => {
    let filter = new URLSearchParams()
    right_id && filter.append('right_id', right_id)
    user_group_id && filter.append('user_group_id', user_group_id)
    return await this.localGetResource(`/user/${id}/right?` + filter.toString(), 'delete', null, token)
  }

  deleteUserOption = async (token, id, option = '') => {
    return await this.localGetResource(`/user/${id}/option` + (option ? `?option=${option}` : ''), 'delete', null, token)
  }

  getUserTypes = async () => {
    return await this.localGetResource(`/usertype`, 'get', null, null)
  }

  /* Group */
  addGroup = async (token, company_id, name) => {
    return await this.localGetResource(`/usergroup`, 'post', { company_id, name }, token)
  }
  deleteGroup = async (token, id) => {
    return await this.localGetResource(`/usergroup/${id}`, 'delete', null, token)
  }
  updateGroup = async (token, id, company_id, name) => {
    return await this.localGetResource(`/usergroup/${id}`, 'put', { company_id, name }, token)
  }
  addGroupOption = async (token, id, option, value) => {
    value = typeof value === 'object' ? JSON.stringify(value) : value
    return await this.localGetResource(`/usergroup/${id}/option`, 'post', { option, value }, token)
  }
  deleteGroupOption = async (token, id) => {
    return await this.localGetResource(`/usergroup/${id}/option`, 'delete', null, token)
  }

  /* Company */
  getCompanyById = async (token, id) => {
    return await this.localGetResource(`/company/${id}`, 'GET', null, token)
  }
  addCompany = async (token, company_licenses, name) => {
    return await this.localGetResource(`/company`, 'post', { company_licenses, name }, token)
  }
  updateCompany = async (token, id, name) => {
    return await this.localGetResource(`/company/${id}`, 'put', { name }, token)
  }
  deleteCompany = async (token, id) => {
    return await this.localGetResource(`/company/${id}`, 'delete', null, token)
  }
  addCompanyOption = async (token, id, option, value) => {
    value = typeof value === 'object' ? JSON.stringify(value) : value
    return await this.localGetResource(`/company/${id}/option`, 'post', { option, value }, token)
  }
  deleteAllCompanyOption = async (token, id) => {
    return await this.localGetResource(`/company/${id}/option`, 'delete', null, token)
  }
  addCompanyLicense = async (token, id, license_id) => {
    return await this.localGetResource(`/company/${id}/license`, 'post', { license_id }, token)
  }
  deleteCompanyLicense = async (token, id, license_id) => {
    return await this.localGetResource(`/company/${id}/license?license_id=${license_id}`, 'delete', null, token)
  }

  /* Benchmark */
  runBenchmark = async (token, id, locale_name, text) => {
    return await this.localGetResource(`/benchmark/${id}`, 'post', { locale_name, text }, token)
  }
  getBenchmarks = async (token) => {
    return await this.localGetResource(`/benchmark`, 'get', null, token)
  }

  getFilteredBenchmarks = async (token, limits = {}, filters = {}) => {
    let filter = new URLSearchParams()
    filter = appendLimits(limits, filter)
    filter = appendFilters(filters, filter)
    //  console.log('getFilteredUsers','/user?' + filter.toString())
    return await this.localGetResource('/benchmark?' + filter.toString(), 'GET', null, token)
  }

  getBenchmarkTemplates = async (token) => {
    return await this.localGetResource(`/benchmark_template`, 'get', null, token)
  }
  getAllBenchmarkTemplates = async (token) => {
    return await this.localGetResource('/benchmark_template', 'GET', null, token)
  }
  addBenchmark = async (token, name, description, benchmark_template_id, calcelements_setting, return_values, settings) => {
    //   console.log({name, description, benchmark_template_id, calcelements_setting, return_values, settings})
    return await this.localGetResource(`/benchmark`, 'post', {
      name,
      description,
      benchmark_template_id,
      calcelements_setting,
      return_values,
      settings
    }, token)
  }
  updateBenchmark = async (token, id, name, description, calcelements_setting, return_values, settings) => {
    //  console.log(id, name, description, calcelements_setting, return_values, settings)
    return await this.localGetResource(`/benchmark/${id}`, 'put', {
      name,
      description,
      calcelements_setting,
      return_values,
      settings
    }, token)
  }
  deleteBenchmark = async (token, id) => {
    return await this.localGetResource(`/benchmark/${id}`, 'delete', null, token)
  }
  addBenchmarkUGC = async (token, benchmark_id, user_id, user_group_id, company_id) => {
    return await this.localGetResource(`/benchmark/ugc`, 'post', {
      benchmark_id,
      user_id,
      user_group_id,
      company_id
    }, token)
  }
  deleteBenchmarkUGC = async (token, benchmark_id, uid, gid, cid) => {
    // console.log(`/benchmark/ugc/${benchmark_id}/${uid}/${gid}/${cid}`)
    return await this.localGetResource(`/benchmark/ugc/${benchmark_id}/${uid}/${gid}/${cid}`, 'delete', null, token)
  }

  /* Right */
  getAllRights = async (token) => {
    return await this.localGetResource('/right', 'GET', null, token)
  }
  deleteRight = async (token, id) => {
    return await this.localGetResource(`/right/${id}`, 'DELETE', null, token)
  }
  addRight = async (token, id, user_type_id) => {
    return await this.localGetResource(`/right`, 'post', { id, user_type_id }, token)
  }

  abbreviationUpload = async (token, language_id, data) => {
    return await this.localGetResource('/abbreviation/upload', 'POST', { language_id, data }, token)
  }
  abbreviationDownload = async (token, language_id) => {
    return await this.localGetResource('/abbreviation/download?language_id=' + language_id, 'GET', null, token)
  }

  exceptionWordDownload = async (token, language_id) => {
    return await this.localGetResource('/exception-words/download?language_id=' + language_id, 'GET', null, token)
  }
  exceptionWordUpload = async (token, language_id, data) => {
    return await this.localGetResource('/exception-words/upload', 'POST', { language_id, data }, token)
  }

  /* get data*/
  getLanguages = async () => {
    return await this.localGetResource('/language', 'GET', null, null)
  }
  getCountries = async () => {
    return await this.localGetResource('/country', 'GET', null, null)
  }
  getCountryLanguages = async () => {
    return await this.localGetResource('/country_languages', 'GET', null, null)
  }
  getLocales = async () => {
    return await this.localGetResource('/locale', 'GET', null, null)
  }
  getMultilang = async () => {
    return await this.localGetResource('/multilang', 'GET', null, null)
  }
  getAllOptions = async (token) => {
    return await this.localGetResource('/options', 'GET', null, token)
  }
  getAllCalcelements = async (token) => {
    return await this.localGetResource('/calcelements', 'GET', null, token)
  }
  getAllReturnValues = async (token = null) => {
    return await this.localGetResource('/return_values', 'GET', null, token)
  }
  getAllTextTypes = async () => {
    return await this.localGetResource('/texttype', 'GET', null, null)
  }
  getAllSettings = async (token) => {
    return await this.localGetResource('/settings', 'GET', null, token)
  }
  getLicenses = async () => {
    return await this.localGetResource(`/license`, 'get', null, null)
  }

  getTreeTager = async (token, locale_name, text) => {
    return await this.localGetResource(`/treetager`, 'post', { locale_name, text }, token)
  }

  getTextTypes = async (token) => {
    return await this.localGetResource(`/texttype`, 'get', null, token)
  }

  /* system */
  ping = async () => {
    return await this.localGetResource('/ping', 'GET', null, null)
  }

  clearAllCache = async (token, cachetype = 0, id = undefined) => {
    let filter = new URLSearchParams()
    cachetype !== undefined && filter.append('cachetype', cachetype.toString())
    // id !== undefined && filter.append('id', '["'+id.toString()+'"]');
    id !== undefined && filter.append('id', id)
    console.log('clearAllCache', `/clearcache?` + filter.toString())
    return await this.localGetResource(`/clearcache?` + filter.toString(), 'get', null, token)
  }

  convertPdf = async (token, fileName, data) => {
    return await this.localGetResource(`/convert/pdf`, 'POST', { file_name: fileName, data }, token)
  }
  /* Messages */
  getSuggestion = async (token, language, word, benchmark_id) => {
    let filter = new URLSearchParams()
    word && filter.append('word', word)
    language && filter.append('language', language)
    language && filter.append('id', benchmark_id)
    return await this.localGetResource('/suggestion?' + filter.toString(), 'GET', null, token)
  }

  /* Messages */
  getFilteredMessages = async (token, limits = {}, filters = {}) => {
    let filter = new URLSearchParams()
    filter = appendLimits(limits, filter)
    filter = appendFilters(filters, filter)
    return await this.localGetResource('/message?' + filter.toString(), 'GET', null, token)
  }

  deleteMessage = async (token, id) => {
    return await this.localGetResource(`/message/${id}`, 'DELETE', null, token)
  }

  setMessageRead = async (token, id) => {
    return await this.localGetResource(`/message/${id}/read`, 'GET', null, token)
  }
  setMessageAnswer = async (token, id, answer) => {
    return await this.localGetResource(`/message/${id}/answer`, 'POST', { answer }, token)
  }
  addMessage = async (token, user_id, level, from_inf, template, text) => {
    return await this.localGetResource(`/message`, 'post', { level, user_id, from_inf, template, text }, token)
  }
  // ltCheck = async (token, language, data, allowList, excludeList) => {
  //     //let filter = new URLSearchParams();
  //     //filter = appendFilters({data, language}, filter);
  //     return await this.localGetResource(`/lt/check`, 'post', {language, data, allowList, excludeList}, token);
  // };
  renewUser = async (token, ttl = 0) => {
    return await this.localGetResource('/user/renew', 'POST', { ttl }, token)
  }

  termBunch = async (token, category_id, body) => {
    return await this.localGetResource(`/term/bunch/${category_id}`, 'POST', body, token)
  }

  netPost = async (token, text, request, num_return = 1) => {
    return await this.localGetResource(`/net`, 'POST', { text, num_return, request }, token)
  }

  getNNPaths = async (token) => {
    return await this.localGetResource(`/net`, 'GET', null, token)
  }

  runRedirect = async (token, data, name) => {
    return await this.localGetResource(`/redirect/${name}`, 'POST', { data }, token)
  }

  getGAQA = async (token) => {
    return await this.localGetResource('/user/2fa/ga', 'GET', null, token)
  }

  disableGAQA = async (token) => {
    return await this.localGetResource('/user/2fa/ga', 'DELETE', null, token)
  }

  proofGAQA = async (token, totp, secret, operation) => {
    return await this.localGetResource('/user/2fa/ga', 'POST', { totp, secret, operation }, token)
  }

  loginGA = async (totp, secret, identifier) => {
    return await this.localGetResource('/user/login/2fa/ga', 'POST', { totp, secret, identifier }, null)
  }

  getLanguageLevel = async (token, locale_name, text) => {
    return await this.localGetResource('/languagelevel', 'post', { locale_name, text }, token)
  }
}
