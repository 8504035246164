import React, { Fragment, useContext, useEffect, useState } from 'react'
import style from './style.module.sass'
import { useTheme } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { Box, Button, Collapse, TableCell, TableRow } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { useActions } from '../../../../utils/action-helper'
import {
 messagesUserUnread,
  tableSetData,
  tableSetSelected,
} from '../../../../actions'
import TLServiceComponent from '../../../../contexts/TLServiceComponent'
import Checkbox from '@material-ui/core/Checkbox'
import {
  convertedValue,
  dateToFullString,
  convertLocalDateToUTCDate
} from '../../../../utils'
import { Check, Delete } from '@material-ui/icons'
import { useRouteMatch } from 'react-router-dom'
import config from '../../../../config'
import UndefinedTemplateMessage from '../../../../assets/messages/UndefinedTemplateMessage'
import Spinner from '../../../Spinner'

export default function TableInnerComponent ({
  row,
  index,
  isBlueRow
}) {
  const { theme: { bgColors, colors } } = useTheme()
  const { t, i18n: { language: i18nLanguage } } = useTranslation()
  const tlService = useContext(TLServiceComponent)

  const selected = useSelector(state => state.tableData.selected)
  const disabledColumns = useSelector(state => state.tableData.disabledColumns)
  const data = useSelector(state => state.tableData.data)
  const columns = useSelector(state => state.tableData.columns)
  const token = useSelector(state => state.userData.token)
  const userId = useSelector(state => state.userData.user.id)
  const disableInfoMessages = useSelector(state => state.userData.user?.user_options?.disable_info_messages || false)

  const [spinner, setSpinner] = useState(false)

  const { params: { id } } = useRouteMatch()
  let mainBackground =  isBlueRow ? bgColors.bgPrimary0 : bgColors.bgBlue100
  let fontWeight = 'normal'


  const {
    aTableSetData,
    aTableSetSelected,
    aMessagesUserUnread
  } = useActions({
    aTableSetData: tableSetData,
    aTableSetSelected: tableSetSelected,
    aMessagesUserUnread: messagesUserUnread(tlService),
  })

  useEffect(() => {
    const intId = parseInt(id)
    if (intId > 0) {
      if (row.id === intId) {
        handleOpenItem(index)
        setTimeout(() => scrollToId(intId), 500)
      } else if (row.opened) {
        handleOpenItem(index)
      }
    }
  }, [id])


  const scrollToId = id => {
    const elem = document.getElementById(id)
    if (elem){
      elem.scrollIntoView({
        behavior: 'smooth'
      })
    }
  }

  const handleMarkRead = async id => {
    try {
      await tlService.setMessageRead(token, id)
      aMessagesUserUnread(token, userId, !disableInfoMessages)
    } catch (e) {
      console.log(e)
    }
    mainBackground = bgColors.bgPrimary200
    row['read_time'] = dateToFullString(convertLocalDateToUTCDate(new Date()))
    const tData = [...data]
    aTableSetData(tData)
  }

  const handleDelete =  async (id) => {
    setSpinner(true)
    try {
      await tlService.deleteMessage(token, id)
      aMessagesUserUnread(token, userId, !disableInfoMessages)
      const tData = [...data]
      tData.splice(index, 1)
      aTableSetData(tData)
    } catch (e) {
      console.log(e)
    }
    setSpinner(false)
  }

  const handleSelectItem = (id) => {
    const tSelected = [...selected]
    const idx = tSelected.indexOf(id)
    if (idx > -1) {
      tSelected.splice(idx, 1)
    } else {
      tSelected.push(id)
    }
    aTableSetSelected(tSelected.sort())
  }

  const handleOpenItem = (inx) => {
    if (!row?.opened) {
      window.history.replaceState(null, null, `/profile/messages/${row.id}#${row.id}`)
      handleMarkRead(row.id)
    }
    const tData = [...data]
    tData[inx]['opened'] = !row?.opened
    aTableSetData(tData)
  }

  const EmptyCell = () => {
    return <TableCell
      onClick={() => handleOpenItem(index)}
      className={style.emptyColumns}
      component="th"
      scope="row"
      padding="none"/>
  }

  /* VIEW */
  const isItemSelected = selected.includes(row.id)
  const TemplateMessageComp = config.messagesTemplates[row.template]


  if (!row['read_time']){
    fontWeight = 'bold'
  }

  if (spinner)
    return <div className={'w-100 align-content-center text-center align-items-center'}><Spinner /></div>

  return (
    <>
      <TableRow
        style={{ backgroundColor: mainBackground}}
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.id}
      >
        <TableCell className={style.cell} padding="checkbox" onClick={() => handleSelectItem(row.id)}>
          <a id={row.id}/>
          <Checkbox
            checked={isItemSelected}
            color="primary"
            inputProps={{ 'aria-labelledby': `enhanced-table-checkbox-${index}` }}
          />
        </TableCell>
        {columns.map((col, ind) =>
          <Fragment key={ind}>
            {disabledColumns.includes(col.id) ? <EmptyCell/> :
              <>
                <TableCell
                  onClick={() => handleOpenItem(index)}
                  className={style.otherColumns}
                  component="th"
                  style={{ fontWeight: fontWeight }}
                  scope="row"
                  padding="none">
                  {
                    convertedValue(row[col.id], col.type, i18nLanguage, t)
                  }
                </TableCell>
              </>
            }
          </Fragment>)
        }
        <TableCell className={`${style.cell} d-flex`} align="right">
          <div className={style.buttonCell}>
            <Button variant="contained" color="primary" size="small"
                    style={{
                      padding: '4px',
                      minWidth: '30px',
                      backgroundColor: bgColors.bgProgress20,
                      color: colors.colorPrimary0
                    }}
                    onClick={() => handleDelete(row.id)}>
              <Delete fontSize="small"/>
            </Button>
          </div>
          {!row['read_time'] && <div className={style.buttonCell}>
            <Button variant="contained" color="primary" size="small"
                    onClick={() => handleMarkRead(row.id)}
                    style={{
                      padding: '4px',
                      minWidth: '30px',
                      backgroundColor: bgColors.bgBlue1000,
                      color: colors.colorPrimary0
                    }}>
              <Check fontSize="small"/>
            </Button>
          </div>}
        </TableCell>
      </TableRow>
      <TableRow className={`${style.accordion}`}
                style={{ backgroundColor: isItemSelected ? bgColors.bgBlue300 : isBlueRow ? bgColors.bgPrimary0 : bgColors.bgBlue100 }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse className={'p-1'} in={row.opened} timeout="auto" unmountOnExit>
            <Box style={{ color: colors.colorPrimary500, gridGap: 5 }}
                 className={`mb-4 d-flex justify-content-between pt-2`}
                 margin={1}>
              <div className={'d-flex text-center w-100'}>
                <div style={{ width: '100%', maxHeight: '200px', overflowY: 'scroll' }}>
                  {config.messagesTemplates[row.template] !== undefined ? <TemplateMessageComp row={row}/> :
                    <UndefinedTemplateMessage row={row}/>}
                </div>
                <div>
                </div>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}