import style from "./style.module.sass";
import React from "react";
import {useTheme} from "@material-ui/core/styles";
import NewsMultilang from "../../../../multilang-components/News";
import {useTranslation} from "react-i18next";

export default function  News () {
    const {theme: {bgColors, colors}} = useTheme();
    const {t} = useTranslation();
    return (
        <div style={{backgroundColor: bgColors.bgPrimary0}} className={`${style.news}`} title={t('accessibility_analysis_news-channel')}>
            <h3 style={{color: colors.colorPrimary600}} className={`${style.textNewsTitle}`}>&nbsp;  {t("Textlab-News")}</h3>
            <NewsMultilang/>
        </div>
    )

}