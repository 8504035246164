import React from 'react'
import { Editor } from '@tinymce/tinymce-react'
import { useTranslation } from 'react-i18next'
import { convertLanguagesToShort } from '../../utils'

const TinyEditor = ({
                        handleOnEditorKeyUp,
                        handleOnEditorInit,
                        handleOnEditorChange,
                        handleOnMouseOver,
                        handleOnMouseClick,
                        handleOnSelectorChange,
                        editorId,
                        text,
                        initialValue = '',
                        tabIndex = 0
                    }) => {
    const { i18n: { language: i18nLanguage } } = useTranslation()

    const handleEditorInit = (event, editor) => {
        if (handleOnEditorInit) {
            handleOnEditorInit(event, editor)
        }
        const iframe = editor.iframeElement
        if (iframe) {
            iframe.setAttribute('tabindex', tabIndex.toString())
        }
    }

    return (
        <Editor
            onSelectionChange={(event, editor) => handleOnSelectorChange(event, editor)}
            onEditorChange={(text, editor) => handleOnEditorChange(text, editor)}
            onKeyUp={(key, editor) => handleOnEditorKeyUp(key, editor)}
            onInit={handleEditorInit}
            onMouseOver={(event, editor) => handleOnMouseOver(event, editor)}
            onMouseUp={(event, editor) => handleOnMouseClick(event, editor)}
            id={editorId}
            initialValue={initialValue}
            value={text}
            init={{
                element_format: 'xhtml',
                promotion: false,
                language: convertLanguagesToShort(i18nLanguage),
                valid_elements: "*[*]",
                extended_valid_elements: '*[*]',
                cleanup: false,
                verify_html: false,
                branding: false,
                width: '100%',
                plugins: ' preview searchreplace autolink  directionality code visualblocks visualchars fullscreen image link media codesample table charmap pagebreak anchor insertdatetime advlist lists charmap ',
                menubar: '',
                toolbar: ' undo redo | bold italic underline strikethrough | fontfamily fontsize styles | alignleft aligncenter alignright alignjustify  | numlist bullist | forecolor backcolor removeformat | pagebreak charmap  | fullscreen  preview |  link anchor codesample | ltr rtl',
                toolbar_sticky: true,
                image_advtab: true,
                indent: false,
                entity_encoding: 'raw',
                force_br_newlines: false,
                paste_auto_cleanup_on_paste: false,
                remove_linebreaks: false,
                convert_urls: false,
                content_css: '/static/tinymce-inner-styles/light.css',
                link_list: [],
                image_list: [],
                image_class_list: [
                    { title: 'None', value: '' },
                    { title: 'Some class', value: 'class-name' }
                ],
                importcss_append: true,
                height: 630,
                image_caption: true,
                quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote ',
                noneditable_noneditable_class: 'mceNonEditable',
                toolbar_mode: 'sliding',
                contextmenu: 'link image imagetools table',
            }}
        />
    )
}

export default TinyEditor