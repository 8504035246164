import React from "react"
import style from "./style.module.sass"
import HIXWrapperAi from "./HIXWrapperAi"
import FLESCHWrapperAi from "./FLESCHWrapperAi"
import { useSelector } from "react-redux"
import CLIXWrapperAi from "./CLIXWrapperAi"
import { roundWithPrecision } from "../../../../utils"
import { Refresh } from "@material-ui/icons"
import { useTheme } from "@material-ui/core/styles"

export default function TopIndicatorsBarAi({
  extResultData = undefined,
  extCurrentBenchmarkSettings = undefined,
  handleRefresh,
  refreshBtnLeft,
  tabIndex = 0
}) {
  const {
    theme: { bgColors },
  } = useTheme()

  const reduxResultData = useSelector(
    (state) => state.analyseData.result.resultData
  )
  const resultData = extResultData ? extResultData : reduxResultData

  const reduxCurrentBenchmarkSettings = useSelector(
    (state) => state.analyseData.result.benchmark
  )
  const currentBenchmarkSettings = extCurrentBenchmarkSettings
    ? extCurrentBenchmarkSettings
    : reduxCurrentBenchmarkSettings

  const targetHix =
    currentBenchmarkSettings?.settings?.formulaHix?.values?.target || 0
  const targetFlesch =
    currentBenchmarkSettings?.settings?.formulaFlesch?.values?.target || 0
  const targetCLIX =
    currentBenchmarkSettings?.settings?.formulaCLIX?.values?.target || 0

  const showRefreshButton = resultData["formulaHix"] !== undefined || resultData["formulaFlesch"] !== undefined || resultData["formulaCLIX"] !== undefined

  return (
    <div className={`${style.mainCont}`}>
      <div className={`d-flex ${style.barsCont}`}>
        {resultData["formulaHix"] !== undefined ? (
          <HIXWrapperAi
            hix={roundWithPrecision(resultData.formulaHix, 2)}
            target={targetHix}
          />
        ) : (
          resultData["formulaFlesch"] !== undefined && (
            <FLESCHWrapperAi
              flesch={roundWithPrecision(resultData.formulaFlesch, 2)}
              target={targetFlesch}
            />
          )
        )}
        {resultData["formulaCLIX"] !== undefined && (
          <CLIXWrapperAi
            clix={roundWithPrecision(resultData.formulaCLIX, 2)}
            target={targetCLIX}
          />
        )}
      { showRefreshButton && (
            <div style={{ order: refreshBtnLeft && -1, maxHeight:'1em'}} onClick={handleRefresh} tabIndex={tabIndex}>
              <Refresh
                style={{
                  cursor: "pointer",
                  color: bgColors.bgPrimary600,
                  fontSize: 22,
                  marginTop: -10,                 
                }}
              />
            </div>
          )}
      </div>
    </div>
  )
}