import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import style from './style.module.scss'
import { useTranslation } from 'react-i18next'
import MainPageWrapper from '../../Main-page-wrapper'
import { ArrowLeftIcon } from '@material-ui/pickers/_shared/icons/ArrowLeftIcon'
import { NavLink } from 'react-router-dom'
import { Button } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import logo from '../Header/textLabLogo.png'
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas'
import RadialBar from '../UI/RadialBar'
import Tonality from '../Home/Tonality'
import VocabularyComponent from '../UI/tags-cloud/VocabularyComponent'
import SingleDistribution from '../Home/SingleDistribution'
import config from '../../../config'
import { PreLoaderCont } from '../HIX/PreLoaderCont'
import {
  getScale, getValue,
  isObjectEmpty,
  chooseColor,
  roundWithPrecision,
  checkClixNotZero,
  countTermsWithPositions,
  convertLanguagesToShort,
  removeTagMark,
  stripHtml
} from '../../../utils'
import VColoredLinearChart from '../UI/VColoredLinearChart'

export default function SingleCombine () {
  let date = new Date()
  // Redux
  const resultData = useSelector(state => state.analyseData.result.resultData)
  const fileName = useSelector(state => state.analyseData.fileName)
  const currentBenchmarkSettings = useSelector(state => state.analyseData.result.benchmark)
  const userName = useSelector(state => state.userData.user.user_name)
  const fullText = removeTagMark(useSelector(state => state.analyseData.text))
  const benchmark = useSelector(state => state.analyseData.result.benchmark)

  const pdfRef = useRef()
  const pdfRef1 = useRef()
  const pdfRef2 = useRef()
  const pdfRef3 = useRef()
  const pdfRef4 = useRef()

  const { t, i18n: { language: i18nLanguage } } = useTranslation()
  const { theme: { bgColors } } = useTheme()
  const benchmarkName = useRef()

  const targetCLIX = currentBenchmarkSettings?.settings?.formulaCLIX?.values?.target || 0
  const targetHix = currentBenchmarkSettings?.settings?.formulaHix?.values?.target || 0
  const targetFlesch = currentBenchmarkSettings?.settings?.formulaFlesch?.values?.target || 0

  const themeColors = {
    red: '#b00014',
    yellow: '#dd540a',
    green: '#9db10d',
    blue: '#415C98'
  }

  // Get benchmark name:
  benchmarkName.current = !!benchmark && benchmark.name[i18nLanguage]

  const tonalityRows = Object.entries(config.tonalityParameters).reduce((acc, [key, val]) => {
    if (!isObjectEmpty(resultData[key])) {
      const arraySize = roundWithPrecision((val.scaleTo[0] - val.scaleFrom[0]) / val.scaleStep) + 1
      const blocks = [...Array(arraySize).keys()].map(x => val.scaleFrom[0] + x * val.scaleStep)
      const exactlyActual = resultData[key]
      const actualInRange = blocks.findIndex(val => exactlyActual <= val)

      return [
        ...acc,
        {
          blocks,
          leftText: t(val.scaleFrom[1]),
          rightText: t(val.scaleTo[1]),
          rangePosition: blocks[actualInRange],
        }
      ]
    }
    return acc
  }, [])

  const downloadPDf = () => {
    const inputs = [pdfRef1.current, pdfRef2.current, pdfRef3.current, pdfRef4.current]
    let doc = new jsPDF({ compress: true })
    let isFirstPage = true

    const addPageToPDF = async (input, addNewPage) => {
      if (!input) return // Skip if input is null or undefined

      const canvas = await html2canvas(input)
      const imgData = canvas.toDataURL('image/png')
      const imgWidth = 210
      const imgHeight = (canvas.height * imgWidth) / canvas.width
      const position = 10

      if (addNewPage) doc.addPage('l', 'mm', 'a4')
      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST')
    }
    // Loop through each input and add to PDF if not null or undefined
    const addPagesSequentially = async () => {
      for (let i = 0; i < inputs.length; i++) {
        await addPageToPDF(inputs[i], !isFirstPage) 
        isFirstPage = false 
      }
      doc.save('file.pdf')
    }
    addPagesSequentially()
  }
  // ********************************************************************
  // ********************************************************************
  // ****************************CLIX Details **************************
  // ********************************************************************
  // ********************************************************************

  let clixList
  let heading = []
  let corporateList = []
  let termList = []

  if (!isObjectEmpty(currentBenchmarkSettings)) {
    clixList = Object.keys(currentBenchmarkSettings?.settings).reduce((acc, val, index) => {
      // if clix parameter is more than 0
      if (currentBenchmarkSettings?.settings[val].values?.clix !== 0 && !!currentBenchmarkSettings?.settings[val].values?.clix) {
        // define needed values
        let scalePercent = 0
        let targetPercent = 0
        let localPercent = 0
        let name = ''
        let color = 'green'
        let localTarget = 0
        let countNumber = 0
        let section = ''

        if (!val.startsWith('list')) {
          // not a number - value from resultData
          name = t(val)
          const currentSettings = currentBenchmarkSettings?.settings[val]?.values
          localTarget = getValue(currentSettings, 'target', 50)
          const localScaleFrom = getValue(currentSettings, 'scaleFrom', 0)
          const localScaleTo = getValue(currentSettings, 'scaleTo', 100)
          const localTargetYellowGreen = getValue(currentSettings, 'yellowGreen', 50)
          const localTargetYellowRed = getValue(currentSettings, 'yellowRed', 50)
          // check if value exist in result object
          if (resultData[val] !== undefined) {
            countNumber = Array.isArray(resultData[val]) ? resultData[val].length : resultData[val]
            if (config.fullCountBase[val] === '') {
              // ih fullCountBase == "" means use value directly
              localPercent = resultData[val]
            } else {
              // if fullCountBase exist use value from it, or by default use countWords
              const fullValueCount = config.fullCountBase[val] !== undefined ? config.fullCountBase[val] : 'countWords'
              localPercent = countNumber / (resultData[fullValueCount] / 100)
            }
            // convert real % into  scale size percent
            const [scalePercentTmp, targetPercentTmp] = getScale(localPercent, localTarget, localScaleFrom, localScaleTo)
            targetPercent = targetPercentTmp
            scalePercent = scalePercentTmp
            color = chooseColor(localPercent, localTargetYellowGreen, localTargetYellowRed)
          } else if (['colorBlue', 'colorGreen', 'colorRed', 'colorYellow'].indexOf(val) > -1) {
            // value is in dataColor object
            localPercent = resultData[val] / (resultData?.countWords / 100)
            // convert real % into  scale size percent
            const [scalePercentTmp, targetPercentTmp] = getScale(localPercent, localTarget, localScaleFrom, localScaleTo)
            targetPercent = targetPercentTmp
            scalePercent = scalePercentTmp
            color = chooseColor(localPercent, localTargetYellowGreen, localTargetYellowRed)
          }
          section = ''
        } else if (val === 'listTerms') {
          // if it's a number - it's  Terms
          // countNumber = countTermsWithPositions(currentBenchmarkSettings?.settings.listTerms[val]);
          const termsSettings = currentBenchmarkSettings?.settings['listTerms']?.values['_list'] || {}
          const { dataTermsClix: ids } = checkClixNotZero(currentBenchmarkSettings?.settings)
          termList = ids?.length && ids.reduce((acc, currentValue, index) => {
            // if it's a number - it's  Terms
            countNumber = countTermsWithPositions(resultData.dataTerms[currentValue]?.result)
            const localSettings = termsSettings[currentValue]
            localTarget = getValue(localSettings, 'target', 50)
            const localScaleFrom = getValue(localSettings, 'scaleFrom', 0)
            const localScaleTo = getValue(localSettings, 'scaleTo', 100)
            const localTargetYellowGreen = getValue(localSettings, 'yellowGreen', 50)
            const localTargetYellowRed = getValue(localSettings, 'yellowRed', 50)
            localPercent = countNumber / (resultData.countWords / 100)
            name = resultData.dataTerms[currentValue]?.category?.name ? resultData.dataTerms[currentValue].category.name[convertLanguagesToShort(i18nLanguage)] : ''
            // Add avaible name in the config to display later:
            config.clixDisplay.map(element => {
              if (element.title === 'Terminology') {
                element.subCat.push(name)
              }
            })
            // convert real % into  scale size percent
            const [scalePercentTmp, targetPercentTmp] = getScale(localPercent, localTarget, localScaleFrom, localScaleTo)
            targetPercent = targetPercentTmp
            scalePercent = scalePercentTmp
            color = chooseColor(localPercent, localTargetYellowGreen, localTargetYellowRed)
            section = 'Terminology'

            return [...acc, {
              id: index,
              localTarget: roundWithPrecision(localTarget, 1),
              targetPercent,
              scalePercent,
              localPercent: roundWithPrecision(localPercent, 1),
              name,
              val: currentValue,
              color,
              countNumber,
            }]
          }, [])
        } else if (val === 'listCorporateLanguageTerms') {
          // if it's a number - it's  Terms
          const termsSettings = currentBenchmarkSettings?.settings['listCorporateLanguageTerms']?.values['_list'] || {}
          const { dataCorporateLanguageClix: ids } = checkClixNotZero(currentBenchmarkSettings?.settings)
          // if it's a number - it's  Terms
          corporateList = ids?.length && ids.reduce((acc, currentValue, index) => {
            countNumber = countTermsWithPositions(resultData.dataCorporateLanguage[currentValue]?.result)
            const localSettings = termsSettings[currentValue]
            localTarget = getValue(localSettings, 'target', 50)
            const localScaleFrom = getValue(localSettings, 'scaleFrom', 0)
            const localScaleTo = getValue(localSettings, 'scaleTo', 100)
            const localTargetYellowGreen = getValue(localSettings, 'yellowGreen', 50)
            const localTargetYellowRed = getValue(localSettings, 'yellowRed', 50)
            localPercent = countNumber / (resultData.countWords / 100)
            name = resultData.dataCorporateLanguage[currentValue]?.category?.name ? resultData.dataCorporateLanguage[currentValue].category.name[convertLanguagesToShort(i18nLanguage)] : ''
            // Add avaible name in the config to display later:
            config.clixDisplay.map(element => {
              if (element.title === 'CorporateLanguage') {
                element.subCat.push(name)
              }
            })
            // convert real % into  scale size percent
            const [scalePercentTmp, targetPercentTmp] = getScale(localPercent, localTarget, localScaleFrom, localScaleTo)
            targetPercent = targetPercentTmp
            scalePercent = scalePercentTmp
            color = chooseColor(localPercent, localTargetYellowGreen, localTargetYellowRed)
            section = 'CorporateLanguage'

            return [...acc, {
              id: index,
              localTarget: roundWithPrecision(localTarget, 1),
              targetPercent,
              scalePercent,
              localPercent: roundWithPrecision(localPercent, 1),
              name,
              val: currentValue,
              color,
              countNumber,
              section
            }]
          }, [])
        }
        return [...acc, {
          id: index,
          localTarget: roundWithPrecision(localTarget, 1),
          targetPercent,
          scalePercent,
          localPercent: roundWithPrecision(localPercent, 1),
          name,
          val,
          color,
          countNumber,
          section
        }]
      }
      return acc
    }, [])

    //  remove corporate list duplicate and take only object:
    const uniqueCorporateList = (!!corporateList?.length && Array.isArray(corporateList)) && corporateList.reduce((accumulator, current) => {
      if (!accumulator.find((item) => item.name === current.name)) {
        accumulator.push(current)
      }
      accumulator = accumulator.filter(el => typeof el === 'object')
      return accumulator
    }, [])

    //  remove term list duplicate and take only object:
    const uniqueTermList = (!!termList?.length && Array.isArray(termList)) && termList.reduce((accumulator, current) => {
      if (!accumulator.find((item) => item.name === current.name)) {
        accumulator.push(current)
      }
      accumulator = accumulator.filter(el => typeof el === 'object')
      return accumulator
    }, [])

    if (uniqueCorporateList?.length && Array.isArray(uniqueCorporateList)) {
      clixList.push(...uniqueCorporateList)
    }
    if (uniqueTermList?.length && Array.isArray(uniqueTermList)) {
      clixList.push(...uniqueTermList)
    }
  }
  //  // To get the available heading:
  config.clixDisplay.map(el => {
    clixList?.length && clixList.map(list => {
      if (el.subCat.some(element => t(element) === list.name) && typeof list.section === 'string' && list.section.length === 0) {
        heading.push({ 'heading': el.title, 'item': list.name })
      } else if (typeof list.section === 'string' && list.section.length !== 0) {
        heading.push({ 'heading': list.section, 'item': list.name })
      }
    })
  })

  // clix text
  let dash_clix_text
  if (resultData['formulaCLIX'] > 85)
    dash_clix_text = t('dash_clix_text_85')
  else if (resultData['formulaCLIX'] > 70)
    dash_clix_text = t('dash_clix_text_70')
  else if (resultData['formulaCLIX'] > 55)
    dash_clix_text = t('dash_clix_text_55')
  else if (resultData['formulaCLIX'] > 33)
    dash_clix_text = t('dash_clix_text_33')
  else if (resultData['formulaCLIX'] > 16)
    dash_clix_text = t('dash_clix_text_16')
  else
    dash_clix_text = t('dash_clix_text_0')
  const colorCLIX = resultData['formulaCLIX'] < targetCLIX / 2 ? '#dd0d08' : resultData['formulaCLIX'] < targetCLIX ? '#da7d27' : '#a8c721'

  // ********************************************************************
  // ********************************************************************
  // *************************** HIX details: ***************************
  // ********************************************************************
  // ********************************************************************

  /*** SentenceConst ***/
  let counters = []

  if (resultData?.moreSentencesInWords && resultData?.moreSentencesInWords?.length > 0) {
    const vaName = 'moreSentencesInWords'
    const val = resultData[vaName]
    const countNumber = val.length
    const localTarget = currentBenchmarkSettings?.settings[vaName]?.values?.target || -100
    //const localThreshold = currentBenchmarkSettings?.settings[vaName]?.values?.threshold || 0;
    const localTargetYellowGreen = currentBenchmarkSettings?.settings[vaName]?.values?.yellowGreen || -100
    const localTargetYellowRed = currentBenchmarkSettings?.settings[vaName]?.values?.yellowRed || -100
    const localPercent = countNumber / (resultData[config.fullCountBase[vaName]] / 100)
    const localColor = chooseColor(localPercent, localTargetYellowGreen, localTargetYellowRed)
    const textPosition = val.sort((a, b) => b[2] - a[2])[0]

    const text = stripHtml(fullText.substring(resultData.dataTokenizer[textPosition[0]]?.begin, resultData.dataTokenizer[textPosition[1]]?.end))
    counters.push({
      value: roundWithPrecision(localPercent, 0),
      color: themeColors[localColor],
      text: [text],
      title: t('LONG SENTENCES'),
      subTitle: t('Longest sentence'),
      lCount: countNumber,
      lCountMax: resultData?.countSentences || 0,
      lText: [t('Target values are no more than n sentences too long', { number: localTarget })],
      lTitle: t('Sentences are too long'),
      label: '',
      withLinear: false,
      textsComp: false
    })
  }
  if (resultData?.moreSentencesInClauses && resultData?.moreSentencesInClauses?.length > 0) {
    const vaName = 'moreSentencesInClauses'
    const val = resultData[vaName]
    const countNumber = val.length
    const localTarget = currentBenchmarkSettings?.settings[vaName]?.values?.target || -100
    //const localThreshold = currentBenchmarkSettings?.settings[vaName]?.values?.threshold || 0;
    const localTargetYellowGreen = currentBenchmarkSettings?.settings[vaName]?.values?.yellowGreen || -100
    const localTargetYellowRed = currentBenchmarkSettings?.settings[vaName]?.values?.yellowRed || -100
    const localPercent = countNumber / (resultData[config.fullCountBase[vaName]] / 100)
    const localColor = chooseColor(localPercent, localTargetYellowGreen, localTargetYellowRed)
    const textPosition = val.sort((a, b) => b[2] - a[2])[0]

    const text = stripHtml(fullText.substring(resultData.dataTokenizer[textPosition[0]]?.begin, resultData.dataTokenizer[textPosition[1]]?.end))

    counters.push({
      value: roundWithPrecision(localPercent, 0),
      color: themeColors[localColor],
      text: [text],
      title: t('COMPLEX SENTENCES'),
      subTitle: t('Complicated sentence'),
      lCount: countNumber,
      lCountMax: resultData?.countSentences || 0,
      lText: [t('Target value is no more than n sentences that are too complicated', { number: localTarget })],
      lTitle: t('Sentences are too complicated'),
      label: '',
      withLinear: false,
      textsComp: false
    })
  }
  if (resultData?.moreWordsInLetters && resultData?.moreWordsInLetters?.length > 0) {
    const vaName = 'moreWordsInLetters'
    const val = resultData[vaName]
    const countNumber = val.length
    const localTarget = currentBenchmarkSettings?.settings[vaName]?.values?.target || -100
    //const localThreshold = currentBenchmarkSettings?.settings[vaName]?.values?.threshold || 0;
    const localTargetYellowGreen = currentBenchmarkSettings?.settings[vaName]?.values?.yellowGreen || -100
    const localTargetYellowRed = currentBenchmarkSettings?.settings[vaName]?.values?.yellowRed || -100
    const localPercent = countNumber / (resultData[config.fullCountBase[vaName]] / 100)
    const localColor = chooseColor(localPercent, localTargetYellowGreen, localTargetYellowRed)
    const textPosition = val.sort((a, b) => b[1] - a[1]).slice(0, 4)
    //new Array(4).fill(null).map(()=>val[Math.floor(Math.random() * val.length)]);

    const randomText =
      textPosition.reduce((acc, v) => acc + `${stripHtml(fullText.substring(resultData.dataTokenizer[v[0]]?.begin, resultData.dataTokenizer[v[0]]?.end))},`, '').split(',')

    const uniqueText = [...new Set([...randomText])]

    const sortedText = uniqueText.sort((a, b) => b.length - a.length)

    const seperateText = !!sortedText && sortedText.map(el => `${el}<br/>`)

    const text = !!seperateText && seperateText.join('')

    counters.push({
      value: roundWithPrecision(localPercent, 0),
      color: themeColors[localColor],
      text: [text],
      title: t('LONG WORDS'),
      subTitle: t('Longest words'),
      lCount: countNumber,
      lCountMax: resultData?.countWords || 0,
      lText: [t('The target is no more than n words that are too long', { number: localTarget })],
      lTitle: t('Words are too long'),
      label: '',
      withLinear: false,
      textsComp: false
    })
  }
  /*** SentenceConst ***/

  /*** ColumnCharts ***/
  const colorHIX = resultData['formulaHix'] < targetHix / 2 ? '#dd0d08' : resultData['formulaHix'] < targetHix ? '#da7d27' : '#a8c721'
  const colorFLESCH = resultData['formulaFlesch'] < targetFlesch / 2 ? '#dd0d08' : resultData['formulaFlesch'] < targetFlesch ? '#da7d27' : '#a8c721'

  // hix text
  let dash_hix_text
  if (resultData['formulaHix'] > 16.6)
    dash_hix_text = t('dash_hix_text_16_6')
  else if (resultData['formulaHix'] > 13.3)
    dash_hix_text = t('dash_hix_text_13_3')
  else if (resultData['formulaHix'] > 10)
    dash_hix_text = t('dash_hix_text_10')
  else if (resultData['formulaHix'] > 6.6)
    dash_hix_text = t('dash_hix_text_6_6')
  else if (resultData['formulaHix'] > 3.3)
    dash_hix_text = t('dash_hix_text_3_3')
  else
    dash_hix_text = t('dash_hix_text_0')

  // flesch text
  let dash_flesch_text
  if (resultData['formulaFlesch'] > 90)
    dash_flesch_text = t('dash_flesch_text_90')
  else if (resultData['formulaFlesch'] > 80)
    dash_flesch_text = t('dash_flesch_text_80')
  else if (resultData['formulaFlesch'] > 70)
    dash_flesch_text = t('dash_flesch_text_70')
  else if (resultData['formulaFlesch'] > 60)
    dash_flesch_text = t('dash_flesch_text_60')
  else if (resultData['formulaFlesch'] > 50)
    dash_flesch_text = t('dash_flesch_text_50')
  else if (resultData['formulaFlesch'] > 30)
    dash_flesch_text = t('dash_flesch_text_30')
  else
    dash_flesch_text = t('dash_flesch_text_less_30')

  return (
    <MainPageWrapper>
      <div className={style.mainCont}>
        <NavLink to="/dashboard/single">
          <Button variant="contained" color="primary" className={`${style.breadcrumbs}`}>
            <ArrowLeftIcon/>
            {t('dashboard')}
          </Button>
        </NavLink>
        <div className={`${style.variants}`}>
          <div className={`${style.variantsCont}`}>
            <Button variant="contained" color="default" className={`${style.breadcrumbs}`} onClick={downloadPDf}>
              {t('download-pdf')}
            </Button>
          </div>
        </div>
        <br/>
        <br/>
        <div className={style.mainCont} ref={pdfRef}>
          {/* First PAGE: */}
          <div style={{ padding: '50px' }} ref={pdfRef1}>
            <div className={style.AverageBar} style={{ backgroundColor: bgColors.bgPrimary0, padding: '50px', }}>
                            <span style={{ float: 'right' }}>
                                <img src={logo} alt="logo" className={style.img}/>
                            </span>
              <span className={style.info}>
                                {t('pdfreport_date')}: {date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()}
                            </span>
              <span className={style.info}>
                                {t('pdfreport_analyse')}: {!!fileName ? fileName : t('noNameForAnalysis_dashboard_pdfHeader')}
                            </span>
              <span className={style.info}>
                                {t('pdfreport_text_type')}: {benchmarkName.current}
                            </span>
              <span className={style.info}>
                                {t('pdfreport_author')}: {userName}
                            </span>
              <br/>
              {(resultData['formulaCLIX'] !== undefined ||
                  resultData['formulaHix'] !== undefined ||
                  resultData['formulaFlesch'] !== undefined
                ) &&
                <div className={style.title}>
                                    <span className={style.titleLineStart}>
                                    </span>
                  <span>
                                        &nbsp;   {t('pdfreport_title_index')}
                                    </span>
                  <span className={style.titleLine}>
                                    </span>
                </div>}
              {resultData['formulaHix'] !== undefined ? <RadialBar
                  value={roundWithPrecision(resultData['formulaHix'], 1)}
                  max={20}
                  color={'#f0f0f0'}
                  text={dash_hix_text}
                  bg={colorHIX}
                  name={t('HIX_dashboard_chartID')}
                  // targetVal={roundWithPrecision(resultData["formulaHix"], 1)}
                  targetVal={targetHix}
                  withNavLink={false}
                  mainText={t('dash_hix_main_text')}
                  combine={true}
                /> :
                resultData['formulaFlesch'] !== undefined && <RadialBar
                  value={roundWithPrecision(resultData['formulaFlesch'], 1)}
                  max={100}
                  color={bgColors.bgPrimary100}
                  text={dash_flesch_text}
                  bg={colorFLESCH}
                  withNavLink={false}
                  combine={true}
                  name={t('FLESCH_dashboard_chartID')}
                  targetVal={targetFlesch}
                  mainText={t('dash_flesch_main_text')}/>}
              <br/>
              {resultData['formulaCLIX'] !== undefined && <RadialBar
                color={bgColors.bgPrimary100}
                value={roundWithPrecision(resultData['formulaCLIX'], 1)}
                text={dash_clix_text}
                bg={colorCLIX}
                name={t('CLIX_dashboard_chartID')}
                max={100}
                targetVal={targetCLIX}
                mainText={t('dash_clix_main_text')}
                combine={true}
              />}
              <br/>
              {(!isObjectEmpty(resultData) && tonalityRows?.length > 0) && <>
                <div className={style.title}>
                                <span className={style.titleLineStart}>
                                </span>
                  <span>
                                    &nbsp;{t('pdfreport_title_tonality')}
                                </span>
                  <span className={style.titleLine}>
                                </span>
                </div>
                <Tonality center="0" wide={!resultData.dataColorWords}
                          type={'single'}
                /></>}
            </div>
          </div>

          {/* Second PAGE: */}
          {(!isObjectEmpty(clixList) && resultData['formulaCLIX'] !== undefined) && <div style={{ padding: '50px' }} ref={pdfRef2}>
            <div className={style.AverageBar} style={{ backgroundColor: bgColors.bgPrimary0, padding: '50px' }}>
              <div style={{ marginTop: '-30px' }}>
                <div>
                  <div className={style.title}>
                                        <span className={style.titleLineStart}>
                                        </span>
                    <span>
                                            &nbsp; {t('pdfreport_title_terminology')}
                                        </span>
                    <span className={style.titleLine}>
                                        </span>
                  </div>
                    <div style={{ backgroundColor: bgColors.bgPrimary0, borderRadius: '10px', margin: '30px 0 0 0' }}
                         className={style.cells}>
                      <div className={`mb-5`}>
                        <VColoredLinearChart
                          cells={clixList} heading={heading}
                        />
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>}

          {/* Thrird PAGE: */}
          {resultData['formulaHix'] !== undefined && <div style={{ padding: '50px' }} ref={pdfRef3}>
            <div className={style.AverageBar} style={{ backgroundColor: bgColors.bgPrimary0, padding: '50px', }}>
              <>
                <div className={style.title}>
                                    <span className={style.titleLineStart}>
                                    </span>
                  <span>
                                        &nbsp;  {t('pdfreport_title_grammar')}
                                    </span>
                  <span className={style.titleLine}>
                                    </span>
                </div>
                  <div style={{ backgroundColor: bgColors.bgPrimary0, borderRadius: '10px', margin: '30px 0 0 0' }}
                       className={style.cells}>
                    <div className={`mb-5`}>
                      {
                        counters.map((value, index) =>
                          <div key={index} className={`d-flex flex-lg-nowrap flex-md-wrap`} style={{ gridGap: '10px' }}>
                            <div className={`w-100`} style={index > 0 ? { borderTop: `4px solid #e2e1e1` } : {}}>
                              <PreLoaderCont
                                id={'diagram-' + index}
                                withLinear={value.withLinear}
                                value={value.value}
                                label={value.label}
                                color={value.color}
                                textsComp={value.textsComp}
                                text={value.text}
                                title={value.title}
                                subTitle={value.subTitle}
                                lCount={value.lCount}
                                lCountMax={value.lCountMax}
                                lText={value.lText}
                                lTitle={value.lTitle}
                                combine={true}
                              />
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
              </>
            </div>
          </div>}

          {/* Fourth page: */}
          <div style={{ padding: '50px' }} ref={pdfRef4}>
            <div className={style.AverageBar} style={{ backgroundColor: bgColors.bgPrimary0, padding: '50px', }}>
              <>
                {!isObjectEmpty(resultData) && <>
                  <div className={style.title}>
                                    <span className={style.titleLineStart}>
                                    </span>
                    <span>
                                        &nbsp;  {t('pdfreport_title_word')}
                                    </span>
                    <span className={style.titleLine}>
                                    </span>
                  </div>
                  <div style={{ backgroundColor: bgColors.bgPrimary0, borderRadius: '10px', margin: '30px 0 0 0' }}
                       className={style.cells}>
                    <div className={`mb-5`}>
                      <VocabularyComponent combine={true}/>
                    </div>
                  </div>
                </>}
              </>
              <br/>
              <br/>
              {!isObjectEmpty(resultData) && <div style={{ marginTop: '-30px' }}>
                <div className={style.title}>
                                    <span className={style.titleLineStart}>
                                    </span>
                  <span>
                                        &nbsp;  {t('pdfreport_title_word_distribution')}
                                    </span>
                  <span className={style.titleLine}>
                                    </span>
                </div>
                <div style={{ backgroundColor: bgColors.bgPrimary0, borderRadius: '10px', margin: '30px 0 0 0' }}
                     className={style.cells}>
                  <div className={`mb-5`}>
                    <SingleDistribution combine={true}/>
                  </div>
                </div>
              </div>}
            </div>
          </div>
        </div>
      </div>
    </MainPageWrapper>
  )
}
