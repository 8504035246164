import style from "./style.module.sass";
import { useTranslation } from "react-i18next";
import { useTheme } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { useActions } from "../../../../utils/action-helper";
import { analyseSetFilter, analyseSetModalAccordion, analyseSetTopAccordion } from "../../../../actions";
import { Tooltip } from "@material-ui/core";
import config from "../../../../config"

export default function Filter() {

    const { t } = useTranslation();
    const { theme: { bgColors, borderColors } } = useTheme();
    const filter = useSelector(state => state.analyseData.filter);
    const resultData = useSelector(state => state.analyseData.result.resultData);

    const {
        actionAnalyseSetFilter, actionAnalyseSetTopAccordion,
        actionAnalyseSetModalAccordion
    } = useActions({
        actionAnalyseSetFilter: analyseSetFilter,
        actionAnalyseSetTopAccordion: analyseSetTopAccordion,
        actionAnalyseSetModalAccordion: analyseSetModalAccordion
    })

    function handleSwitchFilter(valueName) {
        // close accordions on filter change
        actionAnalyseSetTopAccordion('');
        actionAnalyseSetModalAccordion('');
        actionAnalyseSetFilter({ ...filter, [valueName]: !filter[valueName] })
    }

    return <div className={style.filterbox}>
        <h6 style={{ marginBottom: "17px", fontSize: "14px", fontWeight: "bolder" }}> {t('filter-displayed-parameters')} </h6>
        {/* <br /> */}
        <div className={`${style.aktiv} d-flex justify-content-between align-items-center mb-3`} style={{ marginBottom: "-15px" }}>
            <div style={{ fontSize: "14px", fontWeight: 400 }}>
                {t('filter-by-urgency')} :
            </div>
            <div className={`d-flex align-items-center`} >
                {/* <p className={`mr-2`}>{t("Active")}</p> */}
                <Tooltip title={<h6 style={{ fontSize: "15px" }}>
                    {filter.red ? `${t('hide-most-urgent-parameters')}` : `${t('show-most-urgent-parameters')}`}
                </h6>} className='ml-2 mr-auto'>
                    <div onClick={() => handleSwitchFilter('red')} style={{
                        borderColor: borderColors.borderPrimary200,
                        backgroundColor: filter.red ? bgColors.bgStatusError : bgColors.bgPrimary0
                    }} className={`mr-2 ${style.border}`} tabIndex={config.tabIndexes.red}>
                        <div
                            style={{ backgroundColor: bgColors.bgLightPink }} />
                    </div>
                </Tooltip>
                <Tooltip title={<h6 style={{ fontSize: "15px" }}>
                    {filter.yellow ?
                        `${t('hide-parameters-with-fewer-issues')}` :
                        `${t('show-parameters-with-fewer-issues')}`
                    }
                </h6>} className='ml-2 mr-auto'>
                    <div onClick={() => handleSwitchFilter('yellow')} style={{
                        borderColor: borderColors.borderPrimary200,
                        backgroundColor: filter.yellow ? bgColors.bgProgress60 : bgColors.bgPrimary0
                    }} className={`mr-2 ${style.border}`} tabIndex={config.tabIndexes.yellow}>
                        <div
                            style={{ backgroundColor: bgColors.bgLightYellow }} />
                    </div>
                </Tooltip>
                <Tooltip title={<h6 style={{ fontSize: "15px" }}>
                    {filter.green ?
                        `${t('hide-parameters-without-issues')}` :
                        `${t('show-parameters-without-issues')}`
                    }
                </h6>} className='ml-2 mr-auto'>
                    <div onClick={() => handleSwitchFilter('green')} style={{
                        borderColor: borderColors.borderPrimary200,
                        backgroundColor: filter.green ? bgColors.bgProgress100 : bgColors.bgPrimary0
                    }} className={`mr-2 ${style.border}`} tabIndex={config.tabIndexes.green}>
                        <div
                            style={{ backgroundColor: bgColors.bgLightGreen }} />
                    </div>
                </Tooltip>
                {/* <div onClick={() => handleSwitchFilter('blue')} style={{
                borderColor: borderColors.borderPrimary200,
                backgroundColor: filter.blue ? bgColors.bgStatusInfo : bgColors.bgPrimary0
            }} className={`mr-3 ${style.border}`}>
                <div
                    style={{backgroundColor: !filter.blue ? bgColors.bgStatusInfo : bgColors.bgPrimary0}}/>
            </div> */}
            </div>
        </div>

        <div className={`${style.aktiv} d-flex justify-content-between align-items-center mb-3`} style={{ marginTop: "15px" }}>
            <div style={{ fontSize: "14px", fontWeight: 400 }}>
                {t('show-relevant-parameters-for')} :
            </div>
            <div className={style.hixClix}  >
                {resultData['formulaHix'] !== undefined ?
                    <Tooltip title={<h6 style={{ fontSize: "15px" }}>{filter.hix ? `${t('clear-HIX-parameters')}` : `${t('show-only-HIX-parameters')}`}</h6>} className='ml-2 mr-auto'>
                        <button onClick={() => handleSwitchFilter('hix')} className={`${filter.hix && style.activeHixClix}`} tabIndex={config.tabIndexes.hix}>
                            {t("HIX_analyse_filter")}
                        </button>
                    </Tooltip> :
                    <Tooltip title={<h6 style={{ fontSize: "15px" }}>
                        {filter.flesch ? `${t('clear-FLESCH-parameters')}` : `${t('show-only-FLESCH-parameters')}`}
                    </h6>} className='ml-2 mr-auto'>
                        <button onClick={() => handleSwitchFilter('flesch')} className={`${filter.flesch && style.activeHixClix}`} tabIndex={config.tabIndexes.flesch}>
                            {t("FLESCH_analyse_filter")}
                        </button>
                    </Tooltip>
                }
                {resultData['formulaCLIX'] !== undefined && <Tooltip title={<h6 style={{ fontSize: "15px" }}>
                    {filter.clix ? `${t('clear-CLIX-parameters')}` : `${t('show-only-CLIX-parameters')}`}
                </h6>} className='ml-2 mr-auto'>
                    <button onClick={() => handleSwitchFilter('clix')} className={`${filter.clix && style.activeHixClix}`} tabIndex={config.tabIndexes.clix}>
                        {t("CLIX_analyse_filter")}
                    </button>
                </Tooltip>}
            </div>
        </div>
    </div>
}