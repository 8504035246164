import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import Notification from '../Notification'
import logo from './assets/Component-3–1.svg'
import burger from './assets/burger.svg'
import tlIcon from './assets/tl-Icon.png'
import style from './style.module.sass'
import { useSelector } from 'react-redux'
import BatchProcessingProgressbar from '../../Analysis/BatchProcessing/BatchProcessingProgressbar'
import ArchiveProgressbar from '../../Archive/ArchiveProgressBar'
import DropdownUser from './DropdownUser'
import { isObjectEmpty } from '../../../utils'
import NotificationsIcon from '@material-ui/icons/Notifications'
import Spinner from '../../Spinner'
import TLServiceComponent from '../../../contexts/TLServiceComponent'
import { useActions } from '../../../utils/action-helper'
import { messagePageRefresh, messagesUserUnread } from '../../../actions'
import config from '../../../config'
import { useTranslation } from 'react-i18next'


function Header ({ setOpen, }) {
  const {t} = useTranslation()
  const [openPersonalModalWin, setOpenPersonalModalWin] = useState(false)
  const [openNotify, setOpenNotify] = useState(false)
  const batchProcessingState = useSelector(state => state.batchData)
  const analyseProgressBar = useSelector(state => state.analyseData.analyseProgressBar)
  const messages = useSelector(state => state.userData.messages)
  const disableInfoMessages = useSelector(state => state.userData.user?.user_options?.disable_info_messages || false)
  const token = useSelector(state => state.userData.token)
  const userId = useSelector(state => state.userData.user.id)

  const tlService = React.useContext(TLServiceComponent)
  const iaw = messages.getWarningsAndInfo()
  const [submitted, setSubmitted] = useState(false)

  const {
    actionMessagePageRefresh, actionMessagesUserUnread
  } = useActions({
    actionMessagePageRefresh: messagePageRefresh,
    actionMessagesUserUnread: messagesUserUnread(tlService),
  })

  const handleMarkRead = async id => {
    setSubmitted(true)
    try {
      await tlService.setMessageRead(token, id)
      actionMessagesUserUnread(token, userId, !disableInfoMessages)
      actionMessagePageRefresh(true)
    } catch (e) {
      console.log(e)
    }
    setSubmitted(false)
  }

  return (
    <header className={`${style.header} d-flex justify-content-between`}>
      <div className={style.firstPart}>
        {/* eslint-disable-next-line jsx-a11y/no-access-key */}
        <img src={burger} onClick={() => setOpen(true)} className={style.burger} tabIndex={config.tabIndexes.navHamburger}
          alt="menu-icon" accessKey={config.accessKeys.navbarOpen} title={t('accessibility_header_menu-burger')} />
        <NavLink tabIndex={config.tabIndexes.TlLogo} to="/" exact={true} className={style.logo}><img src={logo} alt=""/></NavLink>
      </div>
      <div className={`${style.secondPart} "d-flex justify-content-between"`}>
        <div style={{
          marginRight: '20px',
          marginTop: '20px',
        }}>
          {(batchProcessingState.running || batchProcessingState.done || batchProcessingState.hasError) ?
            <BatchProcessingProgressbar/> : <></>
          }
          {!isObjectEmpty(analyseProgressBar) ? <ArchiveProgressbar/> : <></>}
        </div>
        <div
          onClick={() => {
            setOpenPersonalModalWin(false)
            setOpenNotify(s => !s)
          }}
          tabIndex={config.tabIndexes.notificationButton}
          title={t('accessibility_header_notifications')}
          className="nav-link dropdown-toggle cursor-pointer" id="alertsDropdown" role="button">
          <NotificationsIcon/>
          {iaw && <span className="badge badge-danger badge-counter">{iaw ? iaw[1] : 0}</span>}
        </div>
        <div>
          <img onClick={() => {
            setOpenPersonalModalWin(s => !s)
            setOpenNotify(false)
          }} src={tlIcon} className={style.avatar}
          tabIndex={config.tabIndexes.avatarButton}
          title={t('accessibility_header_user-avatar')}
          alt="avatar icon"/>
        </div>
      </div>
      {submitted && <Spinner/>}
      <DropdownUser setOpenPersonalModalWin={setOpenPersonalModalWin}
                    openPersonalModalWin={openPersonalModalWin}/>
      <Notification setOpen={setOpenNotify} open={openNotify} handleMarkRead={handleMarkRead}/>
    </header>
  )
}

export default Header
