import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import { Add, Remove } from "@material-ui/icons";
import style from "./style.module.sass";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import React from "react";
import { useTheme } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import config from "../../../../config"

export default function AccordionItem({
    children,
    index,
    windowsKey,
    handleTopAccordionOpen,
    color,
    number,
    title,
    filter
}) {
    const { theme: { bgColors, borderColors, colors } } = useTheme();
    const { t } = useTranslation();
    const topAccordionOpen = useSelector(state => state.analyseData.topAccordionOpen);

    const handleChange = (panel) => (event, isExpanded) => {
        handleTopAccordionOpen(isExpanded ? panel : false);
    };

    return (
        <MuiAccordion expanded={topAccordionOpen === `${windowsKey}`} onChange={handleChange(`${windowsKey}`)} key={windowsKey} square>
            <MuiAccordionSummary expandIcon={
                (topAccordionOpen !== `${windowsKey}`) ? <Add /> : <Remove />
            } style={{ borderTop: index !== 0 ? `1px solid ${borderColors.borderPrimary300}` : "none" }} data-accordion-item='accordion' tabIndex={config.tabIndexes.accordions[index]}>
                <div style={{ borderColor: borderColors.borderPrimary200 }} className={`${style.row} d-flex
                align-rows-center align-items-center ${topAccordionOpen === 0 && style.firstRow} ${topAccordionOpen === `${windowsKey}` &&
                    style.opened}`}>
                    <div className={`${style.number} mr-2`} style={{ backgroundColor: bgColors.bgPrimary200 }}>
                        <div style={{ backgroundColor: title === t("Linguistic climate") ? "blue" : !!filter && !filter["red"] ? bgColors.bgYellow1000 : color }} />
                        <p style={{ color: colors.colorPrimary500 }}>{number}{title === t('Linguistic climate') && "%"}</p>
                    </div>
                    <p style={{ color: topAccordionOpen === `${windowsKey}` ? colors.colorPrimary700 : colors.colorPrimary200, }}
                        className={`m-0 ${style.title}`} title={t(`accessibility_analysis_${windowsKey}`)}>{title}</p>
                </div>
            </MuiAccordionSummary>
            <MuiAccordionDetails>
                {children && children}
            </MuiAccordionDetails>
        </MuiAccordion>
    );

}