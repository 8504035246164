import AccordionItem from "../AccordionItem";
import {convertJSONToObject, filterByHIXCLIXFlesch, roundWithPrecision} from "../../../../../utils";
import React from "react";
import {useTranslation} from "react-i18next";
import {useTheme} from "@material-ui/core/styles";
import AccordionModalRectangleShaped from "../AccordionModal/AccordionModalRectangleShaped";
import config from "../../../../../config";
import {useSelector} from "react-redux";

export default function Tonality({
                                     handleModalAccordionOpen,
                                     handleTopAccordionOpen,
                                     handleOnPopupClick,
                                     getNextPopup,
                                     index,
                                     filter
                                 }) {

    const {t} = useTranslation();
    const {theme: {colors}} = useTheme();
    const themeColors = {
        red: colors.colorProgress20,
        yellow: colors.colorProgress60,
        green: colors.colorProgress100,
        blue: colors.colorProgress120
    };

    const resultData = useSelector(state => state.analyseData.result.resultData);
    const currentBenchmarkSettings = useSelector(state => state.analyseData.result.benchmark);

    // get storage data reducers
    const storageAnalyseTonality = convertJSONToObject(
        useSelector(state => state.userData?.user?.user_options?.analyse_tonality),
        config.defaultAnalyseTonality);

    let num = roundWithPrecision(Object.entries(config.tonalityParameters).reduce((acc, [key,]) => acc + (resultData[key] || 0), 0));
    let topColor = 'green';

    const cells = Object.entries(config.tonalityParameters).reduce((acc, [key, val]) => {

        const numberTarget = storageAnalyseTonality[key] !== undefined ? storageAnalyseTonality[key]
            : currentBenchmarkSettings?.settings[key]?.values?.target;

        const arraySize = roundWithPrecision((val.scaleTo[0] - val.scaleFrom[0]) / val.scaleStep) + 1;
        const ranger = [...Array(arraySize).keys()].map(x => val.scaleFrom[0] + x * val.scaleStep);

        const exactlyActual = resultData[key];

        const actualInRange = ranger.find(val => exactlyActual <= val);
        const targetInRange = ranger.find(val => numberTarget <= val);

        const localTarget = roundWithPrecision(numberTarget, 0);
        const trianglePosition = (localTarget - val.scaleFrom[0]) / ((val.scaleTo[0] - val.scaleFrom[0]) / 100);

        const colorBar = exactlyActual > 0 ? colors.colorProgress60 : colors.colorBlue1000;
        const triangleColor = (actualInRange === targetInRange) ? 'green' : 'red';

        // if filter = ignore
        if (!filter[triangleColor])
            return [...acc];

        // cilx + hix filter
        if (filterByHIXCLIXFlesch(filter, currentBenchmarkSettings?.settings[key]?.values?.clix, key))
            return [...acc];

        topColor = triangleColor === 'red' ? 'red' : (triangleColor === 'yellow' && topColor !== 'red') ? 'yellow' : topColor;

        return [
            ...acc,
            {
                ranger,
                toolTip: t('tonality_' + key + '_tooltip'),
                topNumber: roundWithPrecision(exactlyActual, 0),
                bottomNumber: roundWithPrecision(exactlyActual, 1),
                leftTextColor: colors.colorBlue1000,
                leftText: val.scaleFrom[1],
                rightTextColor: colors.colorProgress60,
                rightText: val.scaleTo[1],
                colorTop: themeColors[triangleColor],
                colorRangeBar: colorBar,
                colorRange: false,
                rangePosition: actualInRange,
                triangleColor: themeColors[triangleColor],
                trianglePosition: `${trianglePosition}%`,
                id: 'Tonality_' + key,
                target: localTarget,
                title: t(key)
            }
        ]
    }, []);

    if (cells.length === 0)
        return null;

    return (
        <AccordionItem
            color={topColor}
            number={num}
            title={t("Tonality")}
            index={index}
            handleTopAccordionOpen={handleTopAccordionOpen}
            windowsKey={'Tonality'}
        >
            <AccordionModalRectangleShaped
                handleOnPopupClick={handleOnPopupClick}
                getNextPopup={getNextPopup}
                handleModalAccordionOpen={handleModalAccordionOpen}
                cells={cells}
                windowsKey={'Tonality'}
            />
        </AccordionItem>
    );
}