import { useTranslation } from "react-i18next";
import { useTheme } from "@material-ui/core/styles";
import AccordionItem from "../AccordionItem";
import {
    chooseColor,
    convertLanguagesToShort,
    countTermsWithPositions,
    filterByHIXCLIXFlesch, getScale, getValue,
    roundWithPrecision
} from "../../../../../utils";
import AccordionModalDefault from "../AccordionModal/AccordionModalDefault";
import { useSelector } from "react-redux";
import React from "react";
import config from "../../../../../config"
import { useActions } from "../../../../../utils/action-helper"
import { analyseSetAccordionItemWithCells } from "../../../../../actions"

export default function Terminology({
    handleModalAccordionOpen,
    handleTopAccordionOpen,
    handleOnPopupClick,
    getNextPopup,
    index,
    filter
}) {
    const { t, i18n: { language: i18nLanguage } } = useTranslation();
    const { theme: { colors } } = useTheme();
    const themeColors = {
        red: colors.colorProgress20,
        yellow: colors.colorProgress60,
        green: colors.colorProgress100,
        blue: colors.colorProgress120
    };
    const resultData = useSelector(state => state.analyseData.result.resultData);
    const currentBenchmarkSettings = useSelector(state => state.analyseData.result.benchmark);
    const { actionAnalyseSetAccordionItemWithCells} = useActions({
        actionAnalyseSetAccordionItemWithCells: analyseSetAccordionItemWithCells
    })

    // skip if HIX filter enabled
    //if (filter['hix'] )
    //    return null;
    let valResultObject = resultData['dataTerms'];

    let topColor = 'green';
    let num = 0

    const termsSettings = currentBenchmarkSettings?.settings['listTerms']?.values['_list'] || {};

    const valResult = Object.values(valResultObject).sort((a, b) => {
        const sorta = termsSettings[a.category.id]?.sort || 0;
        const sortb = termsSettings[b.category.id]?.sort || 0;
        return sorta - sortb;
    });

    // if (!valResult?.length)
    //     return null;
    /*clix: 0
    enabled: false
    scaleFrom: 5
    scaleTo: 0
    sort: 6
    target: 1
    yellowGreen: 0.5
    yellowRed: 1.1*/
    //console.log('valResult', valResult)

    const cells = valResult
        .reduce((acc, valCategory) => {
            const countNumber = countTermsWithPositions(valCategory.result);
            const keyCategory = valCategory.category.id;

            //if (countNumber === 0)
            //    return [...acc];

            const localSettings = termsSettings[keyCategory];
            const localTarget = getValue(localSettings, "target", 50);
            const localTargetYellowGreen = getValue(localSettings, "yellowGreen", 50);
            const localTargetYellowRed = getValue(localSettings, "yellowRed", 50);
            const localScaleFrom = getValue(localSettings, "scaleFrom", 0);
            const localScaleTo = getValue(localSettings, "scaleTo", 100);

            let localPercent = countNumber / (resultData.countWords / 100);
            const localAbsolute = getValue(localSettings, "absolute", false);
            if (localAbsolute) {
                localPercent = countNumber;
            }

            // convert real % into  scale size percent
            const [scalePercent, targetPercent] = getScale(localPercent, localTarget, localScaleFrom, localScaleTo);
            const localColor = chooseColor(localPercent, localTargetYellowGreen, localTargetYellowRed);

            // if filter = ignore
            if (!filter[localColor])
                return [...acc];

            // cilx + hix filter
            if (filterByHIXCLIXFlesch(filter, termsSettings[keyCategory]?.clix, 'terminology'))
                return [...acc];

            num += countNumber;
            topColor = localColor === 'red' ? 'red' : (localColor === 'yellow' && topColor !== 'red') ? 'yellow' : topColor;

            return [
                ...acc,
                {
                    absolute: localAbsolute,
                    toolTip: valCategory.category.description[convertLanguagesToShort(i18nLanguage)],
                    colorBar: themeColors[localColor],
                    colorTop: themeColors[localColor],
                    id: 'Terminology_' + keyCategory,
                    num: roundWithPrecision(countNumber, 0),
                    target: localTarget,
                    targetTriangle: targetPercent,
                    actual: roundWithPrecision(localPercent, 1),
                    scaleWidth: roundWithPrecision(scalePercent, 1),
                    title: valCategory?.category?.name ? valCategory.category.name[convertLanguagesToShort(i18nLanguage)] : ''
                }
            ]
        }, [])

    //const percent = num / (resultData.countWords / 100);

    if (cells.length === 0){
        return null
    } else {
        if (config.accordionsForGreenFilterInfo.includes('Terminology') && topColor !== 'green') {
            actionAnalyseSetAccordionItemWithCells("Terminology")
        }
    }

    return (
        <AccordionItem
            color={themeColors[topColor]}
            number={roundWithPrecision(num)}
            title={t("Terminology")}
            index={index}
            handleTopAccordionOpen={handleTopAccordionOpen}
            windowsKey={'Terminology'}
        >
            <AccordionModalDefault
                handleOnPopupClick={handleOnPopupClick}
                getNextPopup={getNextPopup}
                handleModalAccordionOpen={handleModalAccordionOpen}
                cells={cells}
                enabledTypes={['PositiveTerminology', 'Terminology']}
                windowsKey={'Terminology'}
            />
        </AccordionItem>
    );
}
