import React, { useState } from "react";
import style from "./style.module.sass";
import {useTheme} from '@material-ui/core/styles';
import {NavLink} from "react-router-dom";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import ErrorPopup from "../Analysis/ModalWindows/ErrorModal";

export default function AdministrationsCard({link, title, logo, id}) {

    const {t} = useTranslation();
    const [error, setError] = useState(null);
    const {theme: {bgColors, borderColors, colors}} = useTheme();
    const groups = useSelector(state => state.adminData.groups)
    const companies = useSelector(state => state.adminData.companies)
    const isSingleGroupUser = Object.keys(groups).length === 1
    const isSingleCompanyUser = Object.keys(companies).length === 1
    const linkToGroup = isSingleGroupUser ? `/administration/group/update/${Object.keys(groups)[0]}` : "/administration/groups"
    const linkToCompany = isSingleCompanyUser ? `/administration/company/update/${Object.keys(companies)[0]}` : "/administration/company"
  const linkMap = {
    1: linkToGroup,
    4: linkToCompany
  }
    return (
    <div style={{borderColor: borderColors.borderPrimary300, backgroundColor: bgColors.bgPrimary0}}
         className={`${style.card} pr-4 pl-4 pt-3`} title={t(`accessibility_admin_card-${title}`)} >
            <ErrorPopup error={error} setError={setError}/>
        <div className={`d-flex align-items-center mb-4`}>
          {logo && logo}
          <NavLink to={linkMap[id] || link} style={{color: colors.colorPrimary800}} className={`m-0 ${style.title}`}>{t(title)}</NavLink> 
        </div>
    </div>
  )
}

