import React, { useEffect, useState } from 'react'
import style from './style.module.sass'
import { useTheme } from '@material-ui/core/styles'
import { Done, DoneAll, Add } from '@material-ui/icons'

import closeIcon from '../../../../assets/close-circle.svg'
import { Rnd } from 'react-rnd'
import ButtonWithPop from '../../Overview/ButtonWithPop'
import { useTranslation } from 'react-i18next'
import TLServiceComponent from '../../../../contexts/TLServiceComponent'
import { useActions } from '../../../../utils/action-helper'
import { analyseSetPopoverSuggestion } from '../../../../actions'
import { setAutoPosition, stripHtml } from '../../../../utils'
import config from '../../../../config'
import {
    allowlistText
} from '../../../../actions'
import { useSelector, useDispatch } from 'react-redux'

export default function SpellModal({
                                       closePopupWindow,
                                       handleChangeInText,
                                       handleAddToIgnoreList,
                                       popupText,
                                       cumulativeEditor,
                                       cumulativeElement,
                                       innerKey
                                   }) {
    const { t } = useTranslation()

    const dispatch = useDispatch()

    const tlService = React.useContext(TLServiceComponent)
    const { actionAnalyseSetPopoverSuggestion } = useActions({
        actionAnalyseSetPopoverSuggestion: analyseSetPopoverSuggestion(tlService),
    })


    // get popover
    const popups = useSelector(state => state.analyseData.popover.popups)
    const currentPopup = useSelector(state => state.analyseData.popover.currentPopup)
    const token = useSelector(state => state.userData.token)
    const textLanguage = useSelector(state => state.analyseData.textLanguage)
    const useBenchmark = useSelector(state => state.analyseData.useBenchmark)
    const modalRef = React.createRef()
    const enabledSections = useSelector(state => state.userData.user?.enabled_frontend_sections || [])

    let suggestions = popups[currentPopup].data.suggestion || []
    const realPosition = popups[currentPopup].data.position

    const { theme: { bgColors, borderColors, colors } } = useTheme()
    const [valueChanged, setValueChanged] = useState(false)
    const [newText, setNewText] = useState('')

    let left = cumulativeElement.left + 60
    let top = cumulativeElement.top + cumulativeEditor.top

    const [positionX, setPositionX] = useState(left)
    const [positionY, setPositionY] = useState(top)

    useEffect(() => {
            const [newX, newY] = setAutoPosition(
                modalRef.current?.clientHeight,
                modalRef.current?.clientWidth,
                window.innerHeight,
                window.innerWidth,
                top,
                left)
            console.log('newX, newY', newX, newY)
            setPositionY(newY)
            setPositionX(newX)
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [left, top, modalRef.current, window.innerHeight, window.innerWidth, suggestions]
    )

    // on load get suggestion
    useEffect(() => {
        if (!suggestions || suggestions.length === 0) {
            actionAnalyseSetPopoverSuggestion(token, textLanguage, popupText, currentPopup, useBenchmark)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [popupText, currentPopup])
    /*
        useEffect(() => {
            setPositionY(s => s + suggestions.length * 15)
        }, [suggestions]);
    */

    useEffect(() => {
            setNewText(popupText)
            setValueChanged(false)
        }, [popupText]
    )

    useEffect(() => {
            if (newText === popupText)
                setValueChanged(false)
        }, [newText, popupText]
    )

    return (
        // eslint-disable-next-line jsx-a11y/no-access-key
        <Rnd
            onDragStop={(e, d) => {
                setPositionX(d.x)
                setPositionY(d.y)
            }}
            position={{ x: positionX, y: positionY }}
            default={{
                width: 640
            }} style={{ cursor: 'default', zIndex: 1000 }}
            accessKey={config.accessKeys.toolTip}
            tabIndex={0}
            key={innerKey}>
            <div ref={modalRef} style={{ backgroundColor: bgColors.bgPrimary0 }} className={`${style.mainCont} pb-3`}>
                <div
                    style={{ backgroundColor: bgColors.bgPrimary300 }}
                    className={`${style.head} d-flex justify-content-between p-1 pl-3 pr-3`}>
                    <p style={{ backgroundColor: bgColors.bgPrimary300 }}
                       className={`m-0 ${style.title}`}>{t('Spelling issues')}</p>
                    <button className={`border-0 bg-transparent pr-1 pl-2`} onClick={() => {
                        closePopupWindow()
                    }} onMouseDown={e => e.stopPropagation()} data-close-modal>
                        <img src={closeIcon} alt=""/>
                    </button>
                </div>
                <div
                    className={`${style.fieldPart} d-flex align-items-start justify-content-between pl-3 pr-3 p-1 mt-2 mb-2`}>

                    <input type="text"
                           value={newText}
                           style={{ backgroundColor: bgColors.bgPrimary200 }}
                           onMouseDown={e => e.stopPropagation()}
                           onChange={e => {
                               setValueChanged(true)
                               setNewText(e.target.value)
                           }}/>

                    <div className={`d-flex`} style={{ gridGap: '10px' }}>
                        <ButtonWithPop
                            text={valueChanged ? t('replace here') : t('ignore here')}
                            typeOfCount={''}
                            valueChanged={valueChanged}
                            onClick={() => {
                                if (!valueChanged) {
                                    handleAddToIgnoreList(currentPopup, newText, '', 'spell', newText, realPosition)
                                } else {
                                    handleChangeInText(currentPopup, newText, '', 'spell')
                                }
                                closePopupWindow()

                            }}
                        />

                        <ButtonWithPop
                            text={valueChanged ? t('replace everywhere in the document') : t('ignore everywhere in the document')}
                            typeOfCount={'all'}
                            valueChanged={valueChanged}
                            onClick={() => {
                                if (!valueChanged) {
                                    handleAddToIgnoreList(currentPopup, newText, popupText, 'spell', newText, -1)
                                } else {
                                    handleChangeInText(currentPopup, newText, popupText, 'spell')
                                }
                                closePopupWindow()
                            }}
                        />
                        {enabledSections.indexOf('allowedlist') > -1 ?
                            <ButtonWithPop text={t('add entry to my allowed list')}>
                                <Add style={{ height: '20px' }}/>
                                <span style={{ padding: '0 5px 0 0' }}
                                      onClick={() => {
                                          dispatch(allowlistText(newText))
                                          closePopupWindow()
                                      }
                                      }
                                >{t('allowed')}</span>
                            </ButtonWithPop> : null}
                    </div>
                </div>
                <div className={`${style.rows} ml-3 mr-3 m-1`} style={{ borderColor: borderColors.borderPrimary100 }}>
                    {
                        suggestions.map((text, i) => (
                            <div key={i} className={`${style.row} p-1`}
                                 style={{ borderColor: borderColors.borderPrimary100 }}>
                                <div className={`d-flex align-items-start`}>
                                    <p className={`m-0`}>{text}</p>
                                </div>
                                <div className={`d-flex align-items-center`} style={{ gridGap: '5px' }}>
                                    <ButtonWithPop
                                        text={t('replace here')}
                                        typeOfCount={''}
                                        valueChanged={valueChanged}
                                        onClick={() => {
                                            handleChangeInText(currentPopup, text, '', 'spell')
                                            closePopupWindow()
                                        }}

                                    >
                                        <Done style={{ fill: colors.colorProgress100, height: 24 }}/>
                                    </ButtonWithPop>
                                    <ButtonWithPop
                                        text={t('replace everywhere in the document')}
                                        typeOfCount={''}
                                        valueChanged={valueChanged}
                                        onClick={() => {
                                            handleChangeInText(currentPopup, text, popupText, 'spell')
                                            closePopupWindow()
                                        }}
                                    >
                                        <DoneAll style={{ fill: colors.colorProgress100, height: 24 }}/>
                                    </ButtonWithPop>
                                </div>
                            </div>
                        ))
                    }
                </div>
                {t('Spell Modal Down Text') &&
                    <div style={{
                        backgroundColor: 'rgba(206, 224, 255, .20)',
                        borderColor: borderColors.borderPrimary200
                    }}
                         className={`${style.desc} ml-3 mt-3 mr-3 p-1`}
                         dangerouslySetInnerHTML={{ __html: stripHtml(t('Spell Modal Down Text'), config.dangerousTags) }}/>
                }
            </div>

        </Rnd>
    )
}
