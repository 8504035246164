import React, { useState } from "react";
import style from "./style.module.sass";
import { useTheme } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import arrow from "../../../Profile/ProfileWrapper/assets/arrow.svg";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import config from "../../../../config";
import { checkUserRight } from "../../../../utils";
import { useSelector } from "react-redux";
import ErrorPopup from "../../../Analysis/ModalWindows/ErrorModal";

function Dashboard() {
  const { t } = useTranslation();
  const user = useSelector(state => state.userData.user);
  const groups = useSelector(state => state.adminData.groups)
  const [error, setError] = useState(null);
  const { theme: { borderColors, colors } } = useTheme();

  // const settingPopUp = () => {
  //   setError({ text: t("be-patient"), header: t("be-patient") });
  // }
  const isSingleGroupUser = Object.keys(groups).length === 1 
  const linkToGroup = isSingleGroupUser ? `/administration/group/update/${Object.keys(groups)[0]}` : "/administration/groups"

  return (
    <div className={style.mainCont}>
      <NavLink to="/administration" exact={true}>
        <ErrorPopup error={error} setError={setError} />
        <Button color="primary" variant="contained" title='accessibility_admin_to-administration'
          className={`d-flex align-items-center ${style.breadcrumb}`}>
          <img src={arrow} className={`mr-1`} alt="" />
          {t("Administration")}
        </Button>
      </NavLink>
      <ul style={{ borderColor: borderColors.borderPrimary400 }}>
        {
          config.administration.map(l => {
            if (l?.rights?.length && !checkUserRight(user, l.rights))
              return null
            return (
              <li key={l.id} className={``} style={{ borderColor: borderColors.borderPrimary400 }}>
                <div title={t(`accessibility_admin_card-${l.title}`)}>
                  <NavLink
                    className={style.link}
                    style={{ color: colors.colorPrimary400 }}
                    to={l.id === 1 ? linkToGroup : l.link}>
                    <p className={`m-0`}>
                      {t(l.id) === "2" ? t("Word lists") : t(l.title)}
                    </p>
                  </NavLink>
                </div>
              </li>
            )
          })
        }
      </ul>
    </div>
  )
}

export default Dashboard;
