import style from "./style.module.sass";
import React from "react";
import {useTheme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import config from "../../../../config";
import {useSelector} from "react-redux";

export default function Tonality() {
    const {theme: {bgColors, colors}} = useTheme();
    const {t} = useTranslation();
    const resultData = useSelector(state => state.analyseData.result.resultData);

    return <div className={`${style.secondPart}`} title={t('accessibility_analysis_tonality')} tabIndex={config.tabIndexes.tonalityWrapper}>
        <p style={{color: colors.colorPrimary500}} className={`${style.title}`}>{t("Tonality")}</p>
        <div className={style.table}>
            {
                Object.entries(config.tonalityParameters).map(([key, val]) => {
                    if (resultData[key] !== undefined) {
                        const width = (resultData[key] - val.scaleFrom[0]) / ((val.scaleTo[0] - val.scaleFrom[0]) / 100);

                        return (
                            <span key={`span-${key}`} className={`${style.row}`}>
                                <p style={{color: colors.colorPrimary500}}
                                   className={`m-0 ${style.row_title}`}>{t(val.nameOnTop)}</p>
                                <div style={{backgroundColor: bgColors.bgPrimary200}} className={style.bg}>
                                    <div style={{width: `${width}%`, backgroundColor: bgColors.bgBlue1000}}
                                         className={style.ranger}/>
                                </div>
                            </span>
                        );
                    }
                    return null;
                })
            }
        </div>
    </div>;
}