import React, { useEffect, useState } from 'react'
import style from './style.module.sass'
import Spinner from '../../Spinner'
import ProfileWrapper from '../ProfileWrapper'
import TLServiceComponent from '../../../contexts/TLServiceComponent'
import { useSelector } from 'react-redux'
import { useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import TableMassActionComponent from './TableMassActionComponent'
import TableInnerComponent from './TableInnerComponent/TableInnerComponent'
import UniversalTable from '../../common/UniversalTable'
import { useActions } from '../../../utils/action-helper'
import {
  messagesUserUnread,
  tableSetData,
  tableSetDefaults,
  tableSetOtherData,
  tableSetPages,
  tableSetRowCount,
  tableSetSelectAll, tableSetSelectAllIds,
  userSaveOption,
  userSetOption,
} from '../../../actions'
import { convertJSONToObject, isObjectEmpty, onlyUnique } from '../../../utils'
import { FormControlLabel, Switch } from '@material-ui/core'
import { useLocation } from 'react-router-dom'

export default function Messages () {
  const tlService = React.useContext(TLServiceComponent)
  const token = useSelector((state) => state.userData.token)
  const filters = useSelector(state => state.tableData.filters)
  const limit = useSelector(state => state.tableData.limit)
  const order = useSelector(state => state.tableData.order || 'desc')
  const orderBy = useSelector(state => state.tableData.orderBy || 'create_time')
  const currentPage = useSelector(state => state.tableData.currentPage)
  const otherData = useSelector(state => state.tableData.otherData)
  const selectAll = useSelector(state => state.tableData.selectAll)
  const userId = useSelector(state => state.userData.user.id)
  const disableEmailMessages = useSelector(state => state.userData.user?.user_options?.disable_email_messages || false)
  const disableInfoMessages = useSelector(state => state.userData.user?.user_options?.disable_info_messages || false)
  const textDisabledMessagesColumns = useSelector(state =>
    state.userData.user?.user_options?.disabled_messages_columns || '["answer_time","read_time"]')

  const disabledColumns = convertJSONToObject(textDisabledMessagesColumns, [])
  const refreshOnRead = useSelector(state => state.userData.refreshMsgPage)
  const { theme: { borderColors, colors } } = useTheme()
  const { t } = useTranslation()

  const [spinner, setSpinner] = useState(false)

  function useQuery() {
    return new URLSearchParams(useLocation().search)
  }

  const query = useQuery()
  const unsubscribeEmail = query.get('disable_email')
  const {
    aTableSetDefaults,
    aTableSetData,
    aTableSetOtherData,
    aTableSetRowCount, aTableSetSelectAllIds,
    aTableSetPages, aTableSetSelectAll,
    actionUserSetOption,actionUserSaveOption,
    aMessagesUserUnread
  } = useActions({
    aTableSetData: tableSetData,
    aTableSetOtherData: tableSetOtherData,
    aTableSetRowCount: tableSetRowCount,
    aTableSetPages: tableSetPages,
    aTableSetDefaults: tableSetDefaults,
    aTableSetSelectAll: tableSetSelectAll,
    aTableSetSelectAllIds: tableSetSelectAllIds,
    actionUserSetOption: userSetOption,
    actionUserSaveOption: userSaveOption(tlService),
    aMessagesUserUnread: messagesUserUnread(tlService),
  })


  const defaultPagination = [
    50,
    100,
    500
  ]
  const defaultColumns = [
    {
      id: 'header',
      type: 'string',
      disablePadding: false,
      label: `${t('message_header')}`,
      filter_value: 'header',
      orderBy_value: 'header'
    },
    {
      id: 'from_inf',
      type: 'string',
      disablePadding: false,
      label: `${t('message_from_inf')}`,
      filter_value: 'from_inf',
      orderBy_value: 'from_inf'
    },
    {
      id: 'level',
      type: 'translated_string',
      disablePadding: false,
      label: `${t('message_level')}`,
      filter_value: 'level',
      orderBy_value: 'level'
    },
    {
      id: 'create_time',
      type: 'date',
      disablePadding: false,
      label: `${t('message_create-time')}`,
      filter_value: 'create_time',
      orderBy_value: 'create_time'
    },
    {
      id: 'answer_time',
      type: 'date',
      disablePadding: false,
      label: `${t('message_answer-time')}`,
      filter_value: 'answer_time',
      orderBy_value: 'answer_time'
    },
    {
      id: 'read_time',
      type: 'date',
      disablePadding: false,
      label: `${t('message_read-time')}`,
      filter_value: 'read_time',
      orderBy_value: 'read_time'
    }
  ]
  const defaultFilters = [
    {
      id: 'level',
      name: 'Level',
      type: 'selector',
      searchField: 'level',
      searchTemplate: '={value}',
      source: [{name: t('info'), id: 'info'},{name: t('warning'), id: 'warning'}],
      label: t('Level')
    },
  ]
  const extraSettings = {
   // fullSearchFilter: [['|archive_name', 'lc=%{value}%'], ['|version', 'lc=%{value}%']]
  }

  const runRequest = async (limit, requestLimits, requestFilters) => {
    // make a request
    try {
      const res = await tlService.getFilteredMessages(token,requestLimits, requestFilters)
      if (res?.data?.length) {
        aTableSetData(res.data)
        aTableSetOtherData({ messages_fullcount: res.fullcount})
        aTableSetRowCount(res.data.length)
        if (limit > 0) {
          aTableSetPages(Math.ceil(res.fullcount / limit))
        }
      }
      return true
    } catch (e) {
      console.log(e)
    }
    aTableSetData([])
    aTableSetOtherData({})
    aTableSetRowCount(0)
  }

  const getAllIds = async ( fullCount, limit, order, orderBy, currentPage, filters, maxChunk) => {
    const ids = []
    const {
      requestLimits,
      requestFilters
    } = prepareRequest( limit, order, orderBy, currentPage, filters)
    requestLimits.return_values = ["id"]
    for (let offset = 0; offset < fullCount; offset += maxChunk) {
      const currLimit = { ...requestLimits, limit: maxChunk, offset }
      try {
        const res = await tlService.getFilteredMessages(token, currLimit, requestFilters)
        if (res?.data?.length) {
          for (const re of res.data) {
            ids.push(re.id)
          }
        } else {
          break
        }
      } catch (e) {
        console.log(e)
      }
    }
    return ids.filter(onlyUnique) // remove duplicates
  }

  const handleSelectAll = async () => {
    setSpinner(true)
    const fullCount = otherData?.messages_fullcount
    if (!selectAll) {
      const allIds = await getAllIds(fullCount, limit, order, orderBy, currentPage, filters, 1000)
      if (allIds?.length) {
        aTableSetSelectAllIds(allIds)
        aTableSetSelectAll(true)
      }
    } else {
      aTableSetSelectAllIds([])
      aTableSetSelectAll(false)
    }
    setSpinner(false)
  }

  const prepareRequest = ( limit, order, orderBy, currentPage, filters) => {
    const requestLimits = {
      limit,
      order: order === 'desc' ? 1 : 0,
      order_by: orderBy ? orderBy : 'create_time',
      offset: limit * (currentPage - 1)
    }
    //filters
    let extendedFilter = []
    for (const fil of Object.values(filters)) {
      if (fil !== undefined && !isObjectEmpty(fil.filter)) {
        extendedFilter = [...extendedFilter, ...fil.filter]
      }
    }
    extendedFilter.push(['&level','!=alert'])
    if (disableInfoMessages) {
      extendedFilter.push(['&level','!=info'])
    }
    extendedFilter.push(['&user_id',`=${userId}`])
    const requestFilters = {
      extended_filter: JSON.stringify(extendedFilter)
    }
    return { requestLimits, requestFilters }
  }

  const makeRequest = async ( limit, order, orderBy, currentPage, filters) => {
    const {
      requestLimits,
      requestFilters,
    } = prepareRequest( limit, order, orderBy, currentPage, filters)
    await runRequest(limit, requestLimits, requestFilters)
  }

  function reloadAll( limit, order, orderBy, currentPage, filters, setSpinner) {
    setSpinner(true)
    makeRequest( limit, order, orderBy, currentPage, filters).then(() => setSpinner(false))
  }

  useEffect(() => {
    if (unsubscribeEmail === 'true') {
      actionUserSetOption('disable_email_messages', true)
      actionUserSaveOption(token, userId, 'disable_email_messages', JSON.stringify(true))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  } , [unsubscribeEmail])

  // on mount
  useEffect(() => {
    aTableSetDefaults(defaultPagination, defaultFilters, disabledColumns, defaultColumns, 'desc', 'create_time')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // filter if change
  useEffect(() => {
    reloadAll(limit, order, orderBy, currentPage, filters, setSpinner)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filters), limit, order, orderBy, currentPage, disableInfoMessages, refreshOnRead])

  const handleDisablePreference = (option) => (e) => {
    const value = e.target.checked
    actionUserSetOption(option, value)
    actionUserSaveOption(token, userId, option, JSON.stringify(value))
    if (option === 'disable_info_messages') {
      aMessagesUserUnread(token, userId, !value)
    }
  }

  return (
    <ProfileWrapper>
      <div style={{ position: 'absolute', left: '50%', transform: 'translate(-50%, 0)', top: '1px' }} className='d-flex'>
        <FormControlLabel 
          control={<Switch checked={disableEmailMessages} onChange={handleDisablePreference('disable_email_messages')} name="disableEmailMessages" color="primary" />}
          label={t('message_page_disable-email-messages')} />
        <FormControlLabel  style={{ marginLeft: '20px' }}
          control={<Switch checked={disableInfoMessages} onChange={handleDisablePreference('disable_info_messages')} name="disableInfoMessages" color="primary" />}
          label={t('message_page_disable-info-messages')} />
      </div>
      <div
        style={{
          color: colors.colorPrimary500,
          borderColor: borderColors.borderPrimary1000,
        }}
     className={`${style.mainCont}`}
      >
        {spinner && <Spinner/>}
        <UniversalTable
          enableSelectAll = {false}
          defaultColumns={defaultColumns}
          reloadAll={reloadAll}
          handleSelectAll={handleSelectAll}
          getAllIds={getAllIds}
          noDataText={t('message_page_no-data')}
          selectAllText={t('message_page_select-all-pages')}
          MassActionComponent={TableMassActionComponent}
          InnerComponent={TableInnerComponent}
          extraSettings={extraSettings}
          style={style}
          enableExtraFilters
          type='messages'
        />
      </div>
    </ProfileWrapper>
  )
}
