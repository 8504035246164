import style from "./style.module.sass";
import linguisticRanger from "../../assets/linguisticRanger.svg";
import React from "react";
import {useTheme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import config from "../../../../config"

export default function LinguisticClimate() {
    const {theme: {bgColors, colors}} = useTheme();
    const {t} = useTranslation();

    const resultData = useSelector(state => state.analyseData.result.resultData);
    const currentBenchmarkSettings = useSelector(state => state.analyseData.result.benchmark);

    const targetBlue = currentBenchmarkSettings?.settings?.colorBlue?.values?.target || 0;
    const targetGreen = currentBenchmarkSettings?.settings?.colorGreen?.values?.target || 0;
    const targetRed = currentBenchmarkSettings?.settings?.colorRed?.values?.target || 0;
    const targetYellow = currentBenchmarkSettings?.settings?.colorYellow?.values?.target || 0;
    const countWords = resultData?.countWords || 1;

    const percentBlue = resultData?.colorBlue / (countWords / 100);
    const percentGreen = resultData?.colorGreen / (countWords / 100);
    const percentRed = resultData?.colorRed / (countWords / 100);
    const percentYellow = resultData?.colorYellow / (countWords / 100);


    const climateRangers = [
        {name: t('rational'), bg: "#415C98", width: `${percentBlue}%`, stickLeft: `${targetBlue}%`},
        {name: t('konservativ'), bg: "#3EAA1B", width: `${percentGreen}%`, stickLeft: `${targetGreen}%`},
        {name: t('innovative'), bg: "#EDC43B", width: `${percentYellow}%`, stickLeft: `${targetYellow}%`},
        {name: t('emotional'), bg: "#BF1A1A", width: `${percentRed}%`, stickLeft: `${targetRed}%`},
    ]


    return <div className={style.firstPart} title={t('accessibility_analysis_color-words')} tabIndex={config.tabIndexes.colorWordsWrapper}>
        <p style={{color: colors.colorPrimary500}} className={`${style.title}`}>{t("Linguistic climate")}</p>
        <div className={style.table}>
            {
                climateRangers.map(r => (
                    <span key={r.name} className={`${style.row}`}>
                        <p style={{color: colors.colorPrimary500}} className={`${style.row_title} m-0`}>{r.name}</p>
                        <div style={{backgroundColor: bgColors.bgPrimary200}} className={style.bg}>
                            <img style={{left: r.stickLeft}} className={style.whiteStick} src={linguisticRanger}
                                 alt={""}/>
                            <div style={{background: r.bg, width: r.width}} className={style.ranger}/>
                        </div>
            </span>
                ))
            }
        </div>
    </div>;
}