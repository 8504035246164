import React from 'react'
import style from "./style.module.sass";
import {useTheme} from '@material-ui/core/styles';
import config from "../../../../config";
import Filter from "./Filter";
import {useSelector} from "react-redux";
import { useTranslation } from 'react-i18next'

export default function Accordion({
                                      handleModalAccordionOpen,
                                      handleTopAccordionOpen,
                                      handleOnPopupClick,
                                      getNextPopup
                                  }) {

    const {theme: {bgColors}} = useTheme();
    const {t} = useTranslation()
    const resultData = useSelector(state => state.analyseData.result.resultData);
    const result = useSelector(state => state.analyseData.result);
    const filter = useSelector(state => state.analyseData.filter);
    const accordionItemsWithCells = useSelector(state => state.analyseData.accordionItemsWithCells)

    return (
        <div className={`${style.mainCont} pt-0`}>
            <div className={`${style.header} p-2 mb-4`} style={{backgroundColor: bgColors.bgPrimary0}}>
                <div style={{marginTop: "10px"}}> 
                    <Filter/>
                </div>
            </div>
            {/* eslint-disable-next-line jsx-a11y/no-access-key */}
            <div className={`${style.rows} p-2`} style={{ backgroundColor: bgColors.bgPrimary0 }} accessKey={config.accessKeys.cockpit} tabIndex={config.tabIndexes.cockpit}>
                {
                    filter.red && filter.yellow && !filter.green && accordionItemsWithCells?.length <= 1 &&
                    <div className={`p-2`}>
                        <p className='text-success' dangerouslySetInnerHTML={{ __html: t("analyse_green-button-info") }} />
                    </div>
                }
                {Object.entries(config.accordions).map(([keyModal, valModal], index) => {
                    const showedValues = valModal.filter(val => resultData[val] !== undefined);
                    const resultShowedValues = valModal.filter(val => result[val] !== undefined);

                    if (showedValues?.length || resultShowedValues?.length) {
                        const Component = config.modalAccordionComponents[keyModal]
                        return <Component
                            showedValues={showedValues.concat(resultShowedValues)}
                            key={keyModal}
                            handleOnPopupClick={handleOnPopupClick}
                            getNextPopup={getNextPopup}
                            filter={filter}
                            index={index}
                            handleTopAccordionOpen={handleTopAccordionOpen}
                            handleModalAccordionOpen={handleModalAccordionOpen}
                        />
                    }
                    return null;
                })}


            </div>
        </div>
    );
}
