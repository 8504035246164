import React, {useState} from "react";
import {Collapse} from "@material-ui/core";
import style from "./style.module.sass"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {useTranslation} from "react-i18next";
import {convertJSONToObject, convertLanguagesToShort, stripHtml} from "../../../utils"
import {useSelector} from "react-redux";
import config from "../../../config";

export default function Fields({handleOnTextTypeChange}) {

    const {t, i18n: {language: i18nLanguage}} = useTranslation();
    const enabledBenchmarks = useSelector(state => state.analyseData.enabledBenchmarks);
    const useBenchmark = useSelector(state => state.analyseData.useBenchmark);
    const storageLanguageTextType = convertJSONToObject(
        useSelector(
            (state) => state.userData?.user?.user_options?.analyse_language_texttype
        ),
        config.defaultAnalyseLanguageTextType
    )

    if (typeof storageLanguageTextType.textType !== 'object') {
        storageLanguageTextType.textType = {}
    }

    const [openSelect, setOpenSelect] = useState(false);
    const shortLanguage = convertLanguagesToShort(i18nLanguage);
    const currentTextType = enabledBenchmarks[useBenchmark] || enabledBenchmarks[Object.keys(enabledBenchmarks)[0]]
    const description = useBenchmark > 0 ? enabledBenchmarks[useBenchmark]?.description[shortLanguage] : "";

    return (
        <div className={`${style.mainCont}`}>
            <div className={style.sliderCont}>
                {useBenchmark > 0 && <h3 title={t('accessibility_analysis_text-type-selection')}>{t("Select text type")}</h3>}
                {useBenchmark > 0 ?
                    <div className={style.actions}>
                        <div onClick={() => setOpenSelect(!openSelect)} tabIndex={config.tabIndexes.benchmarkSelection}
                             className={`${style.select}`}>
                            <div className={style.field}>
                                <p>{currentTextType?.name[shortLanguage] || currentTextType?.id}</p>
                                <ExpandMoreIcon style={{transform: openSelect ? "rotate(180deg)" : "rotate(0deg)"}}/>
                            </div>
                            <Collapse className={`position-absolute full-width`} style={{zIndex: "5"}} in={openSelect}>
                                <ul>
                                    {Object.entries(enabledBenchmarks).map(([key, val]) =>
                                        <li key={key} onClick={() => {
                                            handleOnTextTypeChange(key)
                                        }}>{val['name'][shortLanguage] || key}
                                        </li>
                                    )}
                                </ul>
                            </Collapse>
                        </div>
                        <p className={style.desc}>{description}</p>
                    </div>
                    :
                    <div className={style.warning} dangerouslySetInnerHTML={{__html: stripHtml(t("No benchmark top warning"),config.dangerousTags)}}/>
                }
            </div>
        </div>
    )
}
