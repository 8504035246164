import React from 'react'
import { Autocomplete } from '@material-ui/lab'
import { useSelector } from 'react-redux'
import { TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
//import { alpha } from '@material-ui/core/styles'
import {
  convertJSONToObject,
  convertLanguagesToShort,
  dateToSearchString
} from '../../../utils'
import config from '../../../config'
import { filterBenchmark } from '../../Analysis/analysisFunctions'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import deLocale from 'date-fns/locale/de'
import InputBase from '@material-ui/core/InputBase'
import { useActions } from '../../../utils/action-helper'
import { tableSetCompanyId, tableSetGroupId } from '../../../actions'

export default function ExtraFilter ({ localFilter, data, index, extraSettings, handleChangeValue, handleOnEnter }) {

  const {
    aTableSetCompanyId,
    aTableSetGroupId
  } = useActions({
    aTableSetCompanyId: tableSetCompanyId,
    aTableSetGroupId: tableSetGroupId
  })
  const { t, i18n: { language: i18nLanguage } } = useTranslation()
  const companies = useSelector(state => state.adminData.companies)
  const groups = useSelector(state => state.adminData.groups)
  const benchmarks = useSelector(state => state.userData.benchmarks)
  const userType = useSelector(state => state.userData.user.user_type_id)
  const selectedGroup = useSelector(state => state.tableData.groupId)
  const selectedCompany = useSelector(state => state.tableData.companyId)
  const storageLanguageTextType = convertJSONToObject(
    useSelector((state) => state.userData?.user?.user_options?.analyse_language_texttype),
    config.defaultAnalyseLanguageTextType
  )
  if (typeof storageLanguageTextType.textType !== 'object') {
    storageLanguageTextType.textType = {}
  }

  const shortInterfaceLang = convertLanguagesToShort(i18nLanguage)


  const handleChangeText = (e) =>{
    const value = e.target.value || ''
    if (value.trim().length){
      handleChangeValue(data, value, value.trim())
    } else {
      handleChangeValue(data, undefined, undefined)
    }
  }

  switch (data.type) {
    case 'selector':
      let selected = data.source.find(v=> v.id === localFilter[data.id]?.value)
      return <Autocomplete
        key={index + data.id}
        onChange={(event, value) =>
          handleChangeValue(data, value?.id ? value.id : undefined, value?.id ? value.id : undefined)}
        id={data.id}
        value={selected}
        di
        options={data.source}
        getOptionLabel={(option) => option.name}
        getOptionSelected={(option, value) => value.id === option.id}
        className={`d-flex align-items-center`}
        style={{ width: 300, height: '52px' }}
        renderInput={({ inputProps, ...rest })  =>
          <TextField {...rest}
                     inputProps={{ ...inputProps, readOnly: true }}
                     style={{ height: '52px' }}
                     label={data.label}
                     variant="filled"/>}
      />


    case 'catalog':
      if (data.source === 'benchmarks') {

        let listOfBenchmarks = benchmarks

        if (extraSettings?.language) {
          listOfBenchmarks = filterBenchmark(benchmarks, extraSettings.language, 'locale_name')
        }
        const selectVariants = []
        let selected = null

        for (const [currKey, currValue] of Object.entries(listOfBenchmarks)) {
          selectVariants.push({ name: currValue.name[shortInterfaceLang], id: currKey })
          if (localFilter[data.id]?.value === currKey) {
            selected = { name: currValue.name[shortInterfaceLang], id: currKey }
          }
        }

        return <Autocomplete
          key={index + data.id}
          onChange={(event, value) =>
            handleChangeValue(data, value?.id ? value.id : undefined, value?.id ? value.id : undefined)}
          id={data.id}
          value={selected}
          options={selectVariants}
          getOptionLabel={(option) => option.name}
          getOptionSelected={(option, value) => value.id === option.id}
          className={`d-flex align-items-center`}
          style={{ width: 300, height: '52px' }}
          renderInput={({ inputProps, ...rest })  =>
            <TextField {...rest}
                       inputProps={{ ...inputProps, readOnly: true }}
                       style={{ height: '52px' }}
                       label={data.label}
                       variant="filled"/>}
        />
      } else if(data.source === 'companies') {
        const options = Object.keys(companies).map((key) => {
          return { id: Number(key), name: companies[key].name}
        }
        )
        const idX = options.findIndex(option => option.id === selectedCompany)
        const selected = userType === 1 ? idX !== -1 ? {name: options[idX].name, id: options[idX].id} : null : options.length > 0 ? options[0] : null   
        
        return <Autocomplete
          key={index + data.id}
          onChange={(event, value) => {
            aTableSetCompanyId(value?.id ? Number(value.id) : 0)
            aTableSetGroupId(0)
          }}
          id={data.id}
          value={selected}
          options={options}
          getOptionLabel={(option) => option.name}
          getOptionSelected={(option, value) => value.id === option.id}
          className={`d-flex align-items-center`}
          style={{ width: 300, height: '52px' }}
          renderInput={({ inputProps, ...rest })  =>
            <TextField {...rest}
                       inputProps={{ ...inputProps, readOnly: true }}
                       style={{ height: '52px' }}
                       label={data.label}
                       variant="filled"/>}
        />
      } else if(data.source === 'groups') {
        let options = Object.keys(groups).map((key) => {
          return { id: Number(key), name: groups[key].name, company_id: groups[key].company_id  }
        }
        )
        const idX = options.findIndex(option => option.id === selectedGroup)

        const selected = idX !== -1 ? {name: options[idX].name, id: options[idX].id} : null

        return <Autocomplete
          key={index + data.id}
          onChange={(event, value) => aTableSetGroupId(value?.id ? value.id : 0)}
          id={data.id}
          value={selected}
          options={options}
          getOptionLabel={(option) => option.name}
          getOptionSelected={(option, value) => value.id === option.id}
          className={`d-flex align-items-center`}
          style={{ width: 300, height: '52px' }}
          renderInput={({ inputProps, ...rest })  =>
            <TextField {...rest}
                       inputProps={{ ...inputProps, readOnly: true }}
                       style={{ height: '52px' }}
                       label={data.label}
                       variant="filled"/>}
        />
       
      }
      break
    case 'date':
      return <MuiPickersUtilsProvider locale={i18nLanguage === 'de' ? deLocale : ''} utils={DateFnsUtils}>
        <KeyboardDatePicker
          key={index + data.id}
          cancelLabel={t('cancel')}
          inputProps={{ style: { textAlign: 'center', marginBottom: '15px' } }}
          margin="normal"
          id={data.id}
          placeholder={data.label}
          format="yyyy-MM-dd"
          inputVariant="filled"
          value={localFilter[data.id]?.value ? localFilter[data.id].value : null}
          style={{ width: 300, height: '52px' }}
          onChange={value => handleChangeValue(data, value, value ? dateToSearchString(new Date(value), data.source) : undefined)}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </MuiPickersUtilsProvider>
    case 'number':
      return <InputBase
        onKeyUp={handleOnEnter}
        key={index + data.id}
        value={localFilter[data.id]?.value ? localFilter[data.id].value :  ' '}
        inputProps={{ style: { textAlign: 'center' } }}
        style={{ width: 300, height: '52px', borderRadius: '10px', backgroundColor: '#e0e0e0' }}
        id={data.id}
        type="number"
        placeholder={data.label}
        onChange={(e) => {
          const num = parseFloat(e.target.value)
          if (!isNaN(num)) {
            handleChangeValue(data, num, num)
          } else {
            handleChangeValue(data, undefined, undefined)
          }
        }
        }
      />
    case 'string':
      return <InputBase
        onKeyUp={handleOnEnter}
        key={index + data.id}
        value={localFilter[data.id]?.value ? localFilter[data.id].value :  ''}
        inputProps={{ style: { textAlign: 'center' } }}
        style={{ width: 300, height: '52px', borderRadius: '10px', backgroundColor: '#e0e0e0' }}
        id={data.id}
        type="text"
        placeholder={data.label}
        onChange={handleChangeText}
      />

    default:
      return null
  }
}