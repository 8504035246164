import React, { useEffect, useState } from 'react'
import style from './style.module.sass'
import { useTheme } from '@material-ui/core/styles'
import closeIcon from '../../../../assets/close-circle.svg'
import { Rnd } from 'react-rnd'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { setAutoPosition, stripHtml, convertPipeToNewLine } from '../../../../utils'
import config from '../../../../config'


export default function InfoModal({
                                      closePopupWindow,
                                      cumulativeEditor,
                                      cumulativeElement,
                                      innerKey
                                  }) {
    const { t } = useTranslation()
    const popups = useSelector(state => state.analyseData.popover.popups)
    const currentPopup = useSelector(state => state.analyseData.popover.currentPopup)
    const popupData = popups[currentPopup].data
    const modalRef = React.createRef()
    const { theme: { bgColors, borderColors } } = useTheme()
    let left = cumulativeElement.left + 60
    let top = cumulativeElement.top + cumulativeEditor.top
    const [positionX, setPositionX] = useState(left)
    const [positionY, setPositionY] = useState(top)

    useEffect(() => {
            const [newX, newY] = setAutoPosition(
                modalRef.current?.clientHeight,
                modalRef.current?.clientWidth,
                window.innerHeight,
                window.innerWidth,
                top,
                left)
            setPositionY(newY)
            setPositionX(newX)

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [left, top, modalRef.current, window.innerHeight, window.innerWidth]
    )

    const noTranslate = popupData?.noTranslate
    const descriptionText = popupData?.text ? (noTranslate ? popupData.text : t(popupData.text)) : ''


    return (
        // eslint-disable-next-line jsx-a11y/no-access-key
        <Rnd
            onDragStop={(e, d) => {
                setPositionX(d.x)
                setPositionY(d.y)
            }}
            position={{ x: positionX, y: positionY }}
            default={{
                width: 640
            }} style={{ cursor: 'default', zIndex: 1000 }}
            accessKey={config.accessKeys.toolTip}
            tabIndex={0}  
            key={innerKey}>
            <div ref={modalRef} style={{ backgroundColor: bgColors.bgPrimary0 }} className={`${style.mainCont} pb-3`}>
                <div
                    style={{ backgroundColor: bgColors.bgPrimary300 }}
                    className={`${style.head} d-flex justify-content-between p-1 pl-3 pr-3`}>
                    <p style={{ backgroundColor: bgColors.bgPrimary300 }}
                       className={`m-0 ${style.title}`}>
                        {noTranslate ? popupData?.header : t(popupData?.header)}
                    </p>
                    <button className={`border-0 bg-transparent pr-1 pl-2`} onClick={() => {
                        closePopupWindow()
                    }} onMouseDown={e => e.stopPropagation()} data-close-modal>
                        <img src={closeIcon} alt=""/>
                    </button>
                </div>
                <div>
                    <p className={`m-1 mr-3 ml-3 mt-3 ${style.errorText}`}
                      dangerouslySetInnerHTML={{ __html: stripHtml(convertPipeToNewLine(descriptionText, true), config.dangerousTags) }}/>
                    {(popupData.bestPractice && t(popupData.bestPractice)) &&
                        <>
                            <p className={`m-1 mt-3 mr-3 ml-3 ${style.errorDescTitle}`}>{t('best-practices')}:</p>
                            <div style={{
                                backgroundColor: 'rgba(206, 224, 255, .20)',
                                borderColor: borderColors.borderPrimary200
                            }}
                                 className={`${style.desc} ml-3 mt-3 mr-3 p-1`}
                                 dangerouslySetInnerHTML={{ __html: stripHtml(convertPipeToNewLine(noTranslate ? popupData.bestPractice : t(popupData.bestPractice), true), config.dangerousTags) }}/>
                        </>
                    }
                </div>
            </div>
        </Rnd>
    )
}
