import React from "react";
import style from "./style.module.scss"
import { useTheme } from "@material-ui/core/styles";
import { useRouteMatch } from "react-router-dom";
import {
  convertNumberToStringLocally, isObjectEmpty,
  roundWithPrecision
} from "../../../utils";
import config from "../../../config";
import { useTranslation } from "react-i18next";

export default function VColoredLinearChart({ cells, heading, noClix = false }) {
  const { theme: { colors } } = useTheme();
  const { params: { type } } = useRouteMatch();
  const {
    t,
    i18n: { language: i18nLanguage },
  } = useTranslation();

  // Remove unwanted empty item:
  cells = !!cells?.length && cells.filter(el => el.name !== "")

  // incase of fullworter, remove the one from non corpotate list:
  cells = !!cells?.length && cells.filter(el => {
    if (el.name === "Füllwörter" && !!el.sort) {
      return el
    } else if (el.name !== "Füllwörter") {
      return el
    }
  }
  )

  const uniqueCells = (!!cells?.length && Array.isArray(cells)) && cells.reduce((accumulator, current) => {
    if (!accumulator.find((item) => item.name === current.name)) {
      accumulator.push(current);
    }
    accumulator = accumulator.filter(el => typeof el === "object")
    return accumulator;
  }, []);

  // Sort by index according to order from result:
  uniqueCells.sort((a, b) => a.sort - b.sort);

  const ShowCells = ({ uniqueCells, el }) => {
    const isCount = el.title === "Counts"
    return <>{uniqueCells?.length && uniqueCells.map(({ id, localTarget, targetPercent, scalePercent, localPercent, name, color, countNumber }, index) => (
      (isObjectEmpty(el) || el.subCat.some(element => t(element) === (name))) &&
      <div key={index}>
        <div className={style.VColoredLinearChart} />
        <div className={style.VColoredLinearChart} >
          <div key={id} className={style.row}>
            <div className={style.title}>
              <div>
                <span className={`mr-2`}><b>{name}</b></span>
                <span style={{ color: color === "yellow" ? "#EACD2A" : color }}>({roundWithPrecision(countNumber)})</span>
              </div>
              <div style={{ color: colors.colorPrimary500 }} className={`${style.desc} m-0`}>
                {isCount ? t("dashboard_clix-average") : t("Proportion in text")}:  {t("Current")}
                 &nbsp; {isCount ? roundWithPrecision(countNumber) : convertNumberToStringLocally(localPercent, i18nLanguage)} { !isCount && "%" } &nbsp;
              {t("Target")} &nbsp; {convertNumberToStringLocally(localTarget, i18nLanguage)} { !isCount && "%" }
              </div>
            </div>
            <div className={style.coloredCells}
              style={{ backgroundColor: "lightGray" }}>
              <div className={style.coloredCell}
                style={{ backgroundColor: color === "yellow" ? "#EACD2A" : color, borderRadius: "20px", width: `${scalePercent}%` }} />
              <div style={{
                left: `${targetPercent > 99 ? 99 : targetPercent < 1 ? 1 : targetPercent}%`,
                margin: "0 0 0 -8px", backgroundColor: type === "texts" ? "#efa90b" : "rgba(0, 0, 0, .7)",
                border: type === "texts" ? "1px solid rgba(255, 255, 255, .7)" : "1px solid rgba(0, 0, 0, .7)"
              }}
                className={style.arrow} />
            </div>
          </div>
        </div>
      </div>
    )
    )}</>
  }

  if (noClix) {
    return <ShowCells uniqueCells={uniqueCells} el={{}} />
  }

  return (
    <>
      {
        config.clixDisplay.map((el, i) => {
          return (<div key={i}>
            <div className={`mr-2`} key={i}>
              <b>{(
                heading.some((list, key) => (list.heading === el.title) && (list.item.length !== 0)))
                && <h4 style={{ marginTop: "20px", marginBottom: "20px", color: "gray" }} key={i}> {t(el.title)} </h4>}
              </b></div>
            <ShowCells uniqueCells={uniqueCells} el={el} />
          </div>
          )
        })
      }
    </>
  )
}
