import React, { useEffect, useState } from 'react'
import style from './style.module.sass'
import { useTheme } from '@material-ui/core/styles'
import { Add, Done, DoneAll } from '@material-ui/icons'

import closeIcon from '../../../../assets/close-circle.svg'
import { Rnd } from 'react-rnd'
import ButtonWithPop from '../../Overview/ButtonWithPop'
import { useTranslation } from 'react-i18next'
import {
    convertArrayToText,
    convertJSONToObject,
    setAutoPosition,
    stripHtml,
    prepareReplacement,
    convertLanguagesToShort, applyReplacements
} from '../../../../utils'
import config from '../../../../config'
import { allowlistText } from '../../../../actions'
import { useDispatch, useSelector } from 'react-redux'


export default function LinguisticClimateModal({
    handleChangeInText,
    handleAddToIgnoreList,
    popupText,
    closePopupWindow,
    cumulativeEditor,
    cumulativeElement,
    innerKey
}) {
    const { t, i18n: { language: i18nLanguage } } = useTranslation()
    const interfaceLanguage = convertLanguagesToShort(i18nLanguage)
    
    const dispatch = useDispatch()

    // get popover
    const popups = useSelector(state => state.analyseData.popover.popups)
    const currentPopup = useSelector(state => state.analyseData.popover.currentPopup)
    const enabledSections = useSelector(state => state.userData.user?.enabled_frontend_sections || [])
    const popupData = popups[currentPopup].data
    const suggestions = popupData.replacement
    const realPosition = popupData.currentPosition
    const innerReplacements = (popupData?.inner_replacements && popupData.inner_replacements[realPosition]) ? popupData.inner_replacements[realPosition] : []
    const arrText = popupData.arrText || [];
    const preparedReplacement = prepareReplacement(suggestions)
    const modalRef = React.createRef()
    const description = popupData?.term?.description ?? popupData?.category?.description[interfaceLanguage] ?? ''
    const textLanguage = useSelector(state => state.analyseData.textLanguage) || "de_DE";


    const { theme: { bgColors, borderColors, colors } } = useTheme()
    const [valueChanged, setValueChanged] = useState(false)
    const [newText, setNewText] = useState('')


    const wordColorToBgColor = {
        colorBlue: bgColors.bgProgress120,
        colorRed: bgColors.bgProgress20,
        colorGreen: bgColors.bgProgress100,
        colorYellow: bgColors.bgProgress60,
    }


    let left = cumulativeElement.left + 60
    let top = cumulativeElement.top + cumulativeEditor.top

    const [positionX, setPositionX] = useState(left)
    const [positionY, setPositionY] = useState(top)

    useEffect(() => {
        const [newX, newY] = setAutoPosition(
            modalRef.current?.clientHeight,
            modalRef.current?.clientWidth,
            window.innerHeight,
            window.innerWidth,
            top,
            left)
        setPositionY(newY)
        setPositionX(newX)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [left, top, modalRef.current, window.innerHeight, window.innerWidth]
    )

    useEffect(() => {
        setNewText(popupText)
        setValueChanged(false)
    }, [popupText]
    )

    useEffect(() => {
        if (newText === popupText)
            setValueChanged(false)
    }, [newText, popupText]
    )

    const ignoreButtonsDisabled = popupData?.category?.settings?.ignoreDisabled || false
    
    const allowedButtonsDisabled = popupData?.category?.settings?.allowListDisabled || false

    return (
        // eslint-disable-next-line jsx-a11y/no-access-key
        <Rnd
            onDragStop={(e, d) => {
                setPositionX(d.x)
                setPositionY(d.y)
            }}
            position={{ x: positionX, y: positionY }}
            default={{
                width: 640
            }} style={{ cursor: 'default', zIndex: 1000 }}
            accessKey={config.accessKeys.toolTip}
            tabIndex={0}  
            key={innerKey}>
            <div ref={modalRef} style={{ backgroundColor: bgColors.bgPrimary0 }} className={`${style.mainCont} pb-3`}>
                <div
                    style={{ backgroundColor: bgColors.bgPrimary300 }}
                    className={`${style.head} d-flex justify-content-between p-1 pl-3 pr-3`}>
                    <p style={{ backgroundColor: bgColors.bgPrimary300 }}
                        className={`m-0 ${style.title}`}>{t('Linguistic climate')}</p>
                    <button className={`border-0 bg-transparent pr-1 pl-2`} onClick={() => {
                        closePopupWindow()
                    }} onMouseDown={e => e.stopPropagation()} data-close-modal>
                        <img src={closeIcon} alt="" />
                    </button>
                </div>
                <div
                    className={`${style.fieldPart} d-flex align-items-start justify-content-between pl-3 pr-3 p-1 mt-2 mb-2`}>
                    <div className={style.fieldCont} style={{
                        backgroundColor: popupData?.category?.category_type
                            ? wordColorToBgColor[popupData?.category?.category_type]
                            : bgColors.bgProgress40

                    }}>
                        <input type="text"
                            value={newText}

                            onMouseDown={e => e.stopPropagation()}
                            onChange={e => {
                                setValueChanged(true)
                                setNewText(e.target.value)
                            }} />
                    </div>
                    <div className={`d-flex`} style={{ gridGap: '10px' }}>
                        {!ignoreButtonsDisabled && <ButtonWithPop
                            text={valueChanged ? t('replace here') : t('ignore here')}
                            typeOfCount={''}
                            valueChanged={valueChanged}
                            onClick={() => {
                                if (!valueChanged) {
                                    handleAddToIgnoreList(currentPopup, newText, popupText, 'linguisticClimate', convertJSONToObject(popupData.words), realPosition)

                                } else {
                                    handleChangeInText(currentPopup, newText, '', 'linguisticClimate')
                                }
                                closePopupWindow()
                            }}
                        />}

                       {!ignoreButtonsDisabled && <ButtonWithPop
                            text={valueChanged ? t('replace everywhere in the document') : t('ignore everywhere in the document')}
                            typeOfCount={'all'}
                            valueChanged={valueChanged}
                            onClick={() => {
                                if (!valueChanged) {
                                    handleAddToIgnoreList(currentPopup, newText, popupText, 'linguisticClimate', convertJSONToObject(popupData.words), -1)
                                } else {
                                    handleChangeInText(currentPopup, newText, popupText, 'linguisticClimate')
                                }
                                closePopupWindow()
                            }}
                        />}
                        {enabledSections.indexOf('allowedlist') > -1 && !allowedButtonsDisabled ?
                            <ButtonWithPop text={t('add entry to my allowed list')}>
                                <Add style={{ height: '20px' }} />
                                <span style={{ padding: '0 5px 0 0' }}
                                    onClick={() => {
                                        dispatch(allowlistText(newText))
                                        closePopupWindow()
                                    }
                                    }
                                >{t('allowed')}</span>
                            </ButtonWithPop> : null}
                    </div>
                </div>
                <div className={`${style.rows} ml-3 mr-3 m-1`} style={{ borderColor: borderColors.borderPrimary100 }}>
                    {Object.values(preparedReplacement).map((meaningVal, index) => {
                        if (meaningVal.replacements.length > 0) {
                            return (
                                <div key={index}>
                                    {meaningVal.meaning &&
                                        <div className={`d-flex align-items-start`}>
                                            <p className={`m-0`}>{t('Meaning')}: {meaningVal.meaning[0]}</p>
                                        </div>
                                    }

                                    {
                                        meaningVal.replacements.map(valRep => {
                                            let text = convertArrayToText(applyReplacements(innerReplacements, valRep['words'], arrText), textLanguage)

                                            return (
                                                <div key={valRep.index} className={`${style.row} p-1`}
                                                    style={{ borderColor: borderColors.borderPrimary100 }}>
                                                    <div className={`d-flex align-items-center`}>
                                                        <div className={style.statusCircle}
                                                            style={{
                                                                backgroundColor: valRep?.settings?.color
                                                                    ? wordColorToBgColor[valRep.settings.color]
                                                                    : bgColors.bgProgress40

                                                            }} />
                                                        <p className={`m-0 ml-2`}>{text}</p>
                                                    </div>
                                                    <div className={`d-flex align-items-center`}
                                                        style={{ gridGap: '5px' }}>
                                                        <ButtonWithPop
                                                            text={t('replace here')}
                                                            typeOfCount={''}
                                                            valueChanged={valueChanged}
                                                            onClick={() => {
                                                                handleChangeInText(currentPopup, text, '', 'linguisticClimate')
                                                                closePopupWindow()
                                                            }}

                                                        >
                                                            <Done
                                                                style={{ fill: colors.colorProgress100, height: 24 }} />
                                                        </ButtonWithPop>
                                                        <ButtonWithPop
                                                            text={t('replace everywhere in the document')}
                                                            typeOfCount={''}
                                                            valueChanged={valueChanged}
                                                            onClick={() => {
                                                                handleChangeInText(currentPopup, text, popupText, 'linguisticClimate')
                                                                closePopupWindow()
                                                            }}
                                                        >
                                                            <DoneAll
                                                                style={{ fill: colors.colorProgress100, height: 24 }} />
                                                        </ButtonWithPop>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                            )
                        }
                        return null
                    })
                    }
                </div>
                {description &&
                    <div style={{
                        backgroundColor: 'rgba(206, 224, 255, .20)',
                        borderColor: borderColors.borderPrimary200
                    }}
                        className={`${style.desc} ml-3 mt-3 mr-3 p-1`}
                        dangerouslySetInnerHTML={{ __html: stripHtml(description, config.dangerousTags) }} />
                }

            </div>

        </Rnd>
    )
}
