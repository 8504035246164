import {useTranslation} from "react-i18next";
import {useTheme} from "@material-ui/core/styles";
import AccordionItem from "../AccordionItem";
import {chooseColor, filterByHIXCLIXFlesch, getScale, getValue, roundWithPrecision} from "../../../../../utils";
import AccordionModalDefault from "../AccordionModal/AccordionModalDefault";
import React from "react";
import {useSelector} from "react-redux";
import config from "../../../../../config";
import { analyseSetAccordionItemWithCells } from "../../../../../actions"
import { useActions } from "../../../../../utils/action-helper"

// GrammarAndStyle
export default function GrammarAndStyle({
                                            showedValues,
                                            handleModalAccordionOpen,
                                            handleTopAccordionOpen,
                                            handleOnPopupClick,
                                            getNextPopup,
                                            index,
                                            filter
                                        }) {
    const {t} = useTranslation();
    const {theme: {colors}} = useTheme();

    const themeColors = {
        red: colors.colorProgress20,
        yellow: colors.colorProgress60,
        green: colors.colorProgress100,
        blue: colors.colorProgress120
    };

    const resultData = useSelector(state => state.analyseData.result.resultData);
    const currentBenchmarkSettings = useSelector(state => state.analyseData.result.benchmark);
    const { actionAnalyseSetAccordionItemWithCells} = useActions({
        actionAnalyseSetAccordionItemWithCells: analyseSetAccordionItemWithCells
    })

    let topColor = 'green';
    let num = 0;


    const cells = showedValues.reduce((acc, valueName) => {
        const countNumber = resultData[valueName].length;

        const localSettings = currentBenchmarkSettings?.settings[valueName]?.values;
        const localTarget = getValue(localSettings, "target", 50);
        const localThreshold = getValue(localSettings, "threshold", 50);
        const localTargetYellowGreen = getValue(localSettings, "yellowGreen", 50);
        const localTargetYellowRed = getValue(localSettings, "yellowRed", 50);
        const localScaleFrom = getValue(localSettings, "scaleFrom", 0);
        const localScaleTo = getValue(localSettings, "scaleTo", 100);
        const fullValueCount = config.fullCountBase[valueName] !== undefined ? config.fullCountBase[valueName] : "countWords";

        const localPercent = countNumber / (resultData[fullValueCount] / 100);
        const localColor = chooseColor(localPercent, localTargetYellowGreen, localTargetYellowRed);

        // convert real % into  scale size percent
        const [scalePercent, targetPercent] = getScale (localPercent, localTarget, localScaleFrom, localScaleTo);

        // if filter = ignore
        if (!filter[localColor])
            return [...acc];

        // cilx + hix filter
        if (filterByHIXCLIXFlesch(filter, currentBenchmarkSettings?.settings[valueName]?.values?.clix, valueName))
            return [...acc];


        num += countNumber;
        topColor = localColor === 'red' ? 'red' : (localColor === 'yellow' && topColor !== 'red') ? 'yellow' : topColor;


        return [
            ...acc,
            {
                toolTip: t('grammarAndStyle_' + valueName + '_tooltip'),
                colorBar: themeColors[localColor],
                colorTop: themeColors[localColor],
                id: 'GrammarAndStyle_' + valueName,
                num: roundWithPrecision(countNumber, 0),
                target: localTarget,
                targetTriangle: targetPercent,
                actual: roundWithPrecision(localPercent, 1),
                scaleWidth: roundWithPrecision(scalePercent, 1),
                title: t(valueName, {threshold: localThreshold})
            }
        ]
    }, []);

    if (cells.length === 0){
        return null
    } else {
        if (config.accordionsForGreenFilterInfo.includes('GrammarAndStyle') && topColor !== 'green') {
            actionAnalyseSetAccordionItemWithCells("GrammarAndStyle")
        }
    }

    return (
        <AccordionItem
            color={themeColors[topColor]}
            number={num}
            title={t("GrammarAndStyle")}
            index={index}
            handleTopAccordionOpen={handleTopAccordionOpen}
            windowsKey={'GrammarAndStyle'}
        >
            <AccordionModalDefault
                handleOnPopupClick={handleOnPopupClick}
                getNextPopup={getNextPopup}
                handleModalAccordionOpen={handleModalAccordionOpen}
                cells={cells}
                windowsKey={'GrammarAndStyle'}
            />
        </AccordionItem>
    );
}