import React from "react";
import style from "./style.module.sass";
import { useTheme } from '@material-ui/core/styles';
import disabledArrowLeft from "../../../../../assets/disabledArrowLeft.svg";
import disabledArrowRight from "../../../../../assets/disabledArrowRight.svg";
import activeArrowLeft from "../../../../../assets/activeArrowLeft.svg";
import activeArrowRight from "../../../../../assets/activeArrowRight.svg";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ComponentWithPop from "../../../../common/ComponentWithPop";
import infoIcon from "../../../../../assets/information-circle.svg";
import { convertNumberToStringLocally } from "../../../../../utils";

const Triangle = ({ value, color }) =>
    <svg className={`position-absolute`} style={{ left: `calc(${value} - 11.5px)`, top: "1px" }}
        xmlns="http://www.w3.org/2000/svg" width="23.172" height="14.138" viewBox="0 0 23.172 14.138">
        <g id="Polygon_8" data-name="Polygon 8" fill="#171717">
            <path
                d="M 21.05945014953613 13.13750076293945 L 2.112425565719604 13.13750076293945 L 11.5859375 1.577646970748901 L 21.05945014953613 13.13750076293945 Z"
                stroke="none" fill={color} />
        </g>
    </svg>

export default function AccordionModalRectangleShaped({
    getNextPopup,
    handleOnPopupClick,
    handleModalAccordionOpen,
    cells,
    enabledTypes,
    windowsKey
}) {
    const { theme: { bgColors, colors } } = useTheme();
    const {
        t,
        i18n: { language: i18nLanguage },
    } = useTranslation();
    const currentPopup = useSelector(state => state.analyseData.popover.currentPopup);
    const modalAccordionOpen = useSelector(state => state.analyseData.modalAccordionOpen);
    const topAccordionOpen = useSelector(state => state.analyseData.topAccordionOpen)
    const tabIndex = windowsKey === topAccordionOpen ? '0' : '-1'     
    const prevPopUp = getNextPopup(-1, currentPopup, enabledTypes);
    const nextPopUp = getNextPopup(1, currentPopup, enabledTypes);

    return (
        <ul className={`${style.mainCont}`}>
            {cells.map(c => (
                <li key={c.id} style={{ backgroundColor: modalAccordionOpen === c.id ? bgColors.bgPrimary0 : "" }}
                    className={`${style.recShapedRangerRow} ${modalAccordionOpen === c.id && style.open}  p-2 mb-2`}>
                    <div className={`d-flex align-items-start`}>
                        <p className={`mr-2 mb-0 font-weight-bold`}
                            style={{ color: c.colorTop }}>{c.topNumber}</p>
                        <p onClick={() => handleModalAccordionOpen(modalAccordionOpen === c.id ? "" : c.id)}
                            style={{
                                color: modalAccordionOpen !== c.id ? colors.colorPrimary300 : colors.colorPrimary1000,
                                width: "200px"
                            }}
                            tabIndex={tabIndex}
                            data-accordion-item={topAccordionOpen === windowsKey ? "accordion" : ""}
                            data-compare-item={c.id}
                            className={`${style.title} m-0`}>
                            {c.title}
                        </p>
                        <div style={{ margin: "0 0 0 auto" }} className={`d-flex justify-content-end`}>
                            <button
                                onClick={() => {
                                    if (modalAccordionOpen === c.id && prevPopUp > -1) {
                                        handleOnPopupClick(prevPopUp);
                                    }
                                }}
                                style={{ backgroundColor: "none", border: "none" }} className={`p-0 m-0`}><img
                                    className={`mr-1`}
                                    src={(modalAccordionOpen === c.id && prevPopUp > -1) ? activeArrowLeft : disabledArrowLeft}
                                    alt="" /></button>
                            <button
                                onClick={() => {
                                    if (modalAccordionOpen === c.id && nextPopUp > -1) {
                                        handleOnPopupClick(nextPopUp);
                                    }
                                }}
                                style={{ backgroundColor: "none", border: "none" }} className={`p-0 m-0`}><img
                                    src={(modalAccordionOpen === c.id && nextPopUp > -1) ? activeArrowRight : disabledArrowRight}
                                    alt="" /></button>
                        </div>
                    </div>
                    <div className={style.modalWin}>
                        <div className={`d-flex justify-content-between`}>
                            <p className={`m-0`} style={{ color: c.leftTextColor }}>{t(c.leftText)}</p>
                            <p className={`m-0`} style={{ color: c.rightTextColor }}>{t(c.rightText)}</p>
                        </div>
                        <div className={`${style.rangerRecShaped} d-flex`}>
                            {c.ranger.map(r => <div key={r}
                                style={{ backgroundColor: c.colorRange ? c.colorRange : r === c.rangePosition ? c.colorRangeBar : bgColors.bgPrimary200 }} />)}
                            <div className={`${style.center}`} />
                            <Triangle value={c.trianglePosition} color={c.triangleColor} />
                        </div>
                        <div className={`d-flex align-items-center justify-content-between mt-2`}>
                            <p style={{ color: colors.colorPrimary500 }} className={`${style.desc} m-0`}>
                                {t("Proportion in text")}: {t("Current")}
                                {
                                    convertNumberToStringLocally(c.bottomNumber, i18nLanguage)
                                } | {t("Target")}
                                {c.target}%</p>
                            <ComponentWithPop html={true} text={!!c.toolTip ? c.toolTip : ""}>
                                <img style={{ cursor: "pointer" }} src={infoIcon} alt={!!c.toolTip ? c.toolTip : ""} />
                            </ComponentWithPop>
                        </div>
                    </div>
                </li>
            )
            )}
        </ul>
    )
}
