import style from "./style.module.sass";
import Button from "@material-ui/core/Button";
import React  from "react";
import {useTranslation} from "react-i18next";
import config from "../../../../config"
export default function AnalyseButton({
                                          handleOnAnalyseClick,
                                          analyseButtonEnable,
                                          setAnalyseButtonEnable,
                                          tabIndex
                                      }) {
    const {t} = useTranslation();
    return (
        <div className={`${style.selectCont}`} title={t('accessibility_analysis_start-analysis-button')}>
            {/* eslint-disable-next-line jsx-a11y/no-access-key */}
            <Button onClick={() => {
                if (analyseButtonEnable) {
                    setAnalyseButtonEnable(false);
                    handleOnAnalyseClick()
                }
            }}
                    accessKey={config.accessKeys.runAnalyse}
                    tabIndex={tabIndex}
                    disabled={!analyseButtonEnable}
                    className={`${style.loader} ${analyseButtonEnable && style.active}`}>
                <div className={style.figure}>
                    <div className={`${style.circle} ${analyseButtonEnable && style.active}`}/>
                    <div className={style.top}/>
                    <div className={style.center}>
                        <div className={`${style.center_circle} ${analyseButtonEnable && style.active}`}/>
                    </div>
                </div>
                <h4 className={`${analyseButtonEnable && style.activeText}`}>{t("Start analysis")}</h4>
            </Button>
        </div>
    )
}