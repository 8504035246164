import React from 'react'
import style from './style.module.sass'
import { NavLink } from 'react-router-dom'
import closeModalWhite from './assets/ionic-ios-close-circle.svg'
import arrowTop from './assets/arrorTop.svg'
import errorIcon from './assets/errorIcon.svg'
import infoCircle from './assets/info-circle.svg'
import rightArrow from './assets/rightArrow.svg'
import { useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import CheckIcon from '@material-ui/icons/Check'
import { Tooltip } from '@material-ui/core'
import { stripHtml } from '../../../utils'
import config from '../../../config'

function Notification ({ open, setOpen, handleMarkRead }) {
  const { theme: { bgColors, borderColors, colors } } = useTheme()
  const { t } = useTranslation()

  // get reducers
  const messages = useSelector(state => state.userData.messages.getWarningsAndInfo())

  return (
    <div
      style={{ backgroundColor: bgColors.bgPrimary0, transform: open ? 'translateY(10px)' : 'translateY(-1200px)' }}
      className={style.select}>
      <img className={style.triangle} src={arrowTop} alt=""/>

      <div onClick={() => setOpen(false)}
           className={`d-flex align-items-center pb-1 text-left btn ${style.options}`}>
        <img className={`mr-2 ml-0 ${style.close}`} src={closeModalWhite} alt=""/>
        <p className={`m-0`}>{t('Notifications')}</p>
      </div>
      {(messages && messages[0]?.length) ? messages[0].reverse().map((o, i) => (

<div
          style={{ borderColor: borderColors.borderPrimary300, gridGap: '5px' }}
          key={o.text}
          className={`d-flex align-items-center justify-content-between ${style.options}`}>
          <div className={`d-flex align-items-center`}>
          <img src={o.level === 'info' ? infoCircle : errorIcon} alt="" style={{marginRight: '10px'}}/>
          <NavLink
            onClick={() => setOpen(false)}
            to={`/profile/messages/${o.id}#${o.id}`}
            tabIndex={-1}
            exact={true}>
            <p style={{ color: colors.colorPrimary1000, minWidth: '266px'}} className={`m-0`}>
              {stripHtml(o.header).substring(0, 40)} {stripHtml(o.header).length > 40 ? '...' : ''}
            </p>
          </NavLink>
          </div>
          <div className='d-flex align-items-center' style={{gridGap: '5px'}}>
          <NavLink
            onClick={() => setOpen(false)}
            to={`/profile/messages/${o.id}#${o.id}`}
            tabIndex={open? config.tabIndexes.notificationButtonMessage + i : -1}
            exact={true}>
            <Tooltip
              title={t('notifications_open')}>
              <img className={style.arrowRight} src={rightArrow} alt=""/>
            </Tooltip>
          </NavLink>

          <Tooltip style={{ cursor: 'pointer' }} title={t('notifications_make_read')}>
            <span tabIndex={open? config.tabIndexes.notificationButtonMessageTick + i : -1} onClick={() => handleMarkRead(o.id)} alt={t('notifications_make_read')}>
            <CheckIcon />
            </span>
          </Tooltip>
          </div>
        </div>

      )) : <div className={style.no_messages}>{t('notifications_no_unread_messages')}</div>}
      <div className={style.link}>
        <NavLink tabIndex={open? config.tabIndexes.notificationButtonMessageOverview : -1} alt="" to="/profile/messages">{t('notifications_message_link')}</NavLink>
      </div>


    </div>
  )
}

export default Notification
