import AccordionItem from "../AccordionItem";
import {
    chooseColor,
    filterByHIXCLIXFlesch,
    getScale,
    isObjectEmpty,
    isObjectsEqual,
    roundWithPrecision
} from "../../../../../utils";
import AccordionModalDefault from "../AccordionModal/AccordionModalDefault";
import React from "react";
import {useTranslation} from "react-i18next";
import {useTheme} from "@material-ui/core/styles";
import {useSelector} from "react-redux";


export default function Spell({
                                  handleModalAccordionOpen,
                                  handleTopAccordionOpen,
                                  handleOnPopupClick,
                                  getNextPopup,
                                  index,
                                  filter
                              }) {

    const {t} = useTranslation();
    const {theme: {colors}} = useTheme();

    const themeColors = {
        red: colors.colorProgress20,
        yellow: colors.colorProgress60,
        green: colors.colorProgress100,
        blue: colors.colorProgress120
    };


    const resultData = useSelector(state => state.analyseData.result.resultData);
    const currentBenchmarkSettings = useSelector(state => state.analyseData.result.benchmark);
    let languageToolResult = useSelector(state => state.analyseData.result.resultData.languageTool);
    const ignoreList = useSelector(state => state.analyseData.ignoreList);
    const dataHunSpell = resultData['dataHunSpell'];

    let cells = [];
    let topColor = 'green';
    let num = 0

    const enableLanguageTool = currentBenchmarkSettings?.settings?.languageTool?.values?.enabled || false;

    let show = false;

    if (dataHunSpell !== undefined && !filterByHIXCLIXFlesch(filter, currentBenchmarkSettings?.settings?.dataHunSpell?.values?.clix, 'dataHunSpell')) {

        // count with ignore list
        const numHunSpell = dataHunSpell.reduce((acc, val) => {
            const from = val['place'];
            const words = [resultData.dataTokenizer[from]?.word];
            if (ignoreList['spell']?.everyWhereList.findIndex(value => isObjectsEqual(words, value)) > -1
                || isObjectsEqual(ignoreList['spell']?.byPositionList[from], words)) {
                return acc;
            }
            return acc + 1;
        }, 0);


        // dataHunSpell
        //const numHunSpell = dataHunSpell?.length || 0;
        const targetHunSpell = currentBenchmarkSettings?.settings?.dataHunSpell?.values.target;
        const targetYellowGreenHunSpell = currentBenchmarkSettings?.settings?.dataHunSpell?.values.yellowGreen;
        const targetYellowRedHunSpell = currentBenchmarkSettings?.settings?.dataHunSpell?.values.yellowRed;
        const percentHunSpell = numHunSpell / (resultData.countWords / 100);
        const colorHunSpell = chooseColor(percentHunSpell, targetYellowGreenHunSpell, targetYellowRedHunSpell);

        // convert real % into  scale size percent
        const localScaleFrom = currentBenchmarkSettings?.settings["dataHunSpell"]?.values.scaleFrom;
        const localScaleTo = currentBenchmarkSettings?.settings["dataHunSpell"]?.values.scaleTo;
        const [scalePercent, targetPercent] = getScale (percentHunSpell, targetHunSpell, localScaleFrom, localScaleTo);

        if (filter[colorHunSpell]) {
            show = true;
            cells.push({
                toolTip: t('spell_datahunspell_tooltip'),
                colorBar: themeColors[colorHunSpell],
                colorTop: themeColors[colorHunSpell],
                id: 'Spell_dataHunSpell',
                num: numHunSpell,
                target: targetHunSpell,
                targetTriangle: targetPercent,
                actual: roundWithPrecision(percentHunSpell, 1),
                scaleWidth: roundWithPrecision(scalePercent, 1),
                title: t("Spelling issues")
            })
            topColor = colorHunSpell === 'red' ? 'red' : (colorHunSpell === 'yellow' && topColor !== 'red') ? 'yellow' : topColor;
            num += numHunSpell;
        }
    }


    if (!isObjectEmpty(languageToolResult) && enableLanguageTool && !filterByHIXCLIXFlesch(filter, currentBenchmarkSettings?.settings?.languageTool?.values?.clix, 'languageTool')) {

        // LanguageToolResult
        //const numLanguageTool = languageToolResult?.length || 0;
        // count with ignore list
        const dataBodyRecognition = resultData?.dataBodyRecognition  || [-1,-1];

        // filter result length
        if (dataBodyRecognition[0] > -1 || dataBodyRecognition[1] > -1) {
           languageToolResult = languageToolResult.filter( t =>
               (dataBodyRecognition[0] < 0 || t.offset > dataBodyRecognition[0]) &&
               (dataBodyRecognition[1] < 0 || t.offset+ t.length < dataBodyRecognition[1]));
        }


        //const borderOffset = resultFull?.resultData?.dataBodyRecognition && resultFull.resultData.dataBodyRecognition[0] > -1 ? 40 : 0;
        //const startBody = resultFull?.resultData?.dataBodyRecognition && resultFull.resultData.dataBodyRecognition[0] > -1 ?
        //    resultFull.resultData.dataBodyRecognition[0] : 0;
        //const endBody = resultFull?.resultData?.dataBodyRecognition && resultFull.resultData.dataBodyRecognition[1] > -1 ?
        //    resultFull.resultData.dataBodyRecognition[1] : tmpContent.length - 1;


        let numLanguageTool = languageToolResult?.length || 0;

        if (!isObjectEmpty(ignoreList['languageTool']?.everyWhereList) || !isObjectEmpty(ignoreList['languageTool']?.byPositionList)) {
             numLanguageTool = languageToolResult.reduce((acc, val) => {
                const from = val['offset'];
                const position = resultData.dataTokenizer.findIndex(val => val.begin === from)
                const words = position > -1 ? [resultData.dataTokenizer[position]?.word] : [];
                if (ignoreList['languageTool']?.everyWhereList.findIndex(value => isObjectsEqual(words, value)) > -1
                    || isObjectsEqual(ignoreList['languageTool']?.byPositionList[from], words)) {
                    return acc;
                }
                return acc + 1;
            }, 0);

        }

        const targetLanguageTool = currentBenchmarkSettings?.settings?.languageTool?.values.target
        const targetYellowGreenLanguageTool = currentBenchmarkSettings?.settings?.languageTool?.values.yellowGreen;
        const targetYellowRedLanguageTool = currentBenchmarkSettings?.settings?.languageTool?.values.yellowRed;
        const percentLanguageTool = numLanguageTool / (resultData.countWords / 100);

        const colorLanguageTool = chooseColor(percentLanguageTool, targetYellowGreenLanguageTool, targetYellowRedLanguageTool);

        // convert real % into  scale size percent
        const localScaleFrom = currentBenchmarkSettings?.settings["languageTool"]?.values.scaleFrom;
        const localScaleTo = currentBenchmarkSettings?.settings["languageTool"]?.values.scaleTo;
        const [scalePercent, targetPercent] = getScale (percentLanguageTool, targetLanguageTool, localScaleFrom, localScaleTo);

        if (filter[colorLanguageTool]) {
            show = true;
            cells.push({
                toolTip: t('spell_language_tool_tooltip'),
                colorBar: themeColors[colorLanguageTool],
                colorTop: themeColors[colorLanguageTool],
                id: 'Spell_languageTool',
                num: numLanguageTool,
                target: targetLanguageTool,
                targetTriangle: targetPercent,
                actual: roundWithPrecision(percentLanguageTool, 1),
                scaleWidth: roundWithPrecision(scalePercent, 1),
                title: t("LanguageTool issues")
            })
            topColor = colorLanguageTool === 'red' ? 'red' : (colorLanguageTool === 'yellow' && topColor !== 'red') ? 'yellow' : topColor;
            num += numLanguageTool;
        }
    }
    if (!show)
        return null;

    return (
        <AccordionItem
            color={themeColors[topColor]}
            filter={filter}
            number={num}
            title={t("Spelling")}
            index={index}
            handleTopAccordionOpen={handleTopAccordionOpen}
            windowsKey={'Spell'}
        >
            <AccordionModalDefault
                handleOnPopupClick={handleOnPopupClick}
                getNextPopup={getNextPopup}
                handleModalAccordionOpen={handleModalAccordionOpen}
                cells={cells}
                enabledTypes={['LanguageTool','Spell']}
                windowsKey={'Spell'}
            />
        </AccordionItem>
    );
}