
import { useTranslation } from "react-i18next"
import { useTheme } from "@material-ui/core/styles"
import config from "../../../../config"

export default function AiButton({
    onClick,
    onHover = () => { },
    enabled = true,
    backgroundColor,
    cursor,
    imageSrc,
    tabIndex = -1,
}) {
    const { t } = useTranslation()
    const {
        theme: { colors },
    } = useTheme()
    return (
        // eslint-disable-next-line jsx-a11y/no-access-key
        <div
            style={{
                backgroundColor,
                borderRadius: 10,
                padding: '15px 10px 0px 15px',
                cursor,
                overflow: 'hidden',
            }}
            onClick={enabled ? onClick : undefined}
            tabIndex={tabIndex}
            onMouseEnter={() => enabled && onHover(true)}
            onMouseLeave={() => enabled && onHover(false)}
            accessKey={config.accessKeys.ainstein}
            title={t('accessibility_analysis_ai-button')}
        >
            <img
                src={imageSrc}
                alt="ComLab AI Bot"
                width="120px"
                height="80px"
                style={{ float: 'right' }}
            />
            <p
                dangerouslySetInnerHTML={{ __html: t('hello-ai-text') }}
                style={{
                    fontFamily: 'sans-serif',
                    color: !enabled && colors.colorPrimary400,
                }}
            />
        </div>
    )
}