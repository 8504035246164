import React from "react";
import style from "./style.module.sass";
import selectTriangle from "./assets/selectTriangle.svg";
import closeModalWhite from "./assets/Icon-ionic-ios-close-circle.svg";
import {NavLink} from "react-router-dom";
import person from "./assets/Icon-material-person.svg";
//import modalNotifyIcon from "./assets/Component-4–1.svg";
import logout from "./assets/Icon-open-account-logout.svg";
import {useTheme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import config from "../../../config"


function DropdownUser({openPersonalModalWin, setOpenPersonalModalWin}) {
    const {theme: {bgColors, borderColors, colors}} = useTheme();
    const {t} = useTranslation();
    // get reducers
    const user = useSelector(state => state.userData.user);

    return (

        <div style={{
            backgroundColor: bgColors.bgPrimary500, transform: openPersonalModalWin ? "translateY(10px)" :
                "translateY(-1000px)", zIndex: 9
        }}
             className={style.select}>
            <img className={style.triangle} src={selectTriangle} alt=""/>
            <div style={{color: colors.colorPrimary200, borderColor: borderColors.borderPrimary700}} onClick={() =>
                setOpenPersonalModalWin(false)}
                 className={`d-flex align-items-center pb-4 ${style.options}`}>
                <img className={`mr-2 ml-0`} src={closeModalWhite} alt=""/>
                <p className={`m-0`}>{user['user_name']}</p>
            </div>
            <NavLink style={{color: colors.colorPrimary200, borderColor: borderColors.borderPrimary700}} to="/profile"
                     exact tabIndex={openPersonalModalWin ? config.tabIndexes.preferences : -1} title={t("accessibility_header_preferences")}
                     className={`d-flex
        align-items-center
        ${style.options}`}>
                <img src={person} alt=""/>
                <p className={`m-0`}>{t("Preferences")}</p>
            </NavLink>
            <NavLink style={{color: colors.colorPrimary200, borderColor: borderColors.borderPrimary700}}
                     to="/logout"
                     exact
                     tabIndex={openPersonalModalWin ? config.tabIndexes.logout : -1}
                     title={t("accessibility_header_logout")}
                     className={`d-flex
        align-items-center ${style.options}`}>
                <img src={logout} alt=""/>
                <p className={`m-0`}>{t("Logout")}</p>
            </NavLink>
        </div>
    );
}

export default DropdownUser;