import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import * as Papa from 'papaparse'
import {
  checkTextSize,
  convertJSONToObject,
  convertLanguagesToFull,
  convertLanguagesToShort,
  cumulativeOffset,
  deepCopy,
  isObjectEmpty,
  isObjectsEqual,
  md5Generator,
  readUploadedFileAsBinary,
  removeBodyBorders,
  removeTagMark,
  roundWithPrecision,
  setLanguage,
  stripHtml,
  textAddBodyBorders,
  uploadAsFile,
} from '../../utils'
import style from './style.module.sass'
import TLServiceComponent from '../../contexts/TLServiceComponent'
import Tabs from './Tabs'
import MainPageWrapper from '../Main-page-wrapper'
import ErrorModal from './ModalWindows/ErrorModal'
import Fields from './Fields'
import HeadPart from './HeadPart'
import TinyEditor from '../TinyEditor'
import Footer from '../Footer/Footer'
import AnalyseFooter from './AnalyseFooter'
import { useActions } from '../../utils/action-helper'
import {
  analyseClearIgnoreList,
  analyseClearPopovers,
  analyseClearResult,
  analyseSetAutoTextLanguage,
  analyseSetBodyBorderShow,
  analyseSetCurrentPopup,
  analyseSetEnabledBenchmark,
  analyseSetIgnoreList,
  analyseSetLanguageToolResult,
  analyseSetLtOffset,
  analyseSetModalAccordion,
  analyseSetPopups,
  analyseSetResult,
  analyseSetText,
  analyseSetTextLanguage,
  analyseSetTopAccordion,
  analyseSetUseBenchmark,
  batchProcessingClear,
  userGetAllBenchmarks,
  userGetAllowLists,
  userGetTextTone,
  userSaveOption,
  userSetOption,
  analyseSetFileName, userGetNNPaths, analyseSetOldText, analyseClearSaveArchive,
  analyseClearAccordionItemsWithCells,
} from '../../actions'
import {
  filterBenchmark,
  highlightEditor,
  ignoreInText,
  readDNDFiles,
  replaceInText,
  startAnalyse,
} from './analysisFunctions'
import config from '../../config'
import Spinner from '../Spinner'
import TopIndicatorsBar from './TopIndicatorsBar'
import ModalDialog from '../ModalWindows/ModalDialog'
//import { Store } from "@material-ui/icons";
import BatchModal from './BatchProcessing/BatchModal'
import { Button, Collapse, Modal } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import closeIcon from '../../assets/close-circle.svg'
import AiAnalyse from './ai'

export default function AnalysisComponent () {
  const { theme: { bgColors } } = useTheme()
  const { t, i18n: { language: i18nLanguage } } = useTranslation()
  const runRV = useRef(false)
  const alertMsg = useRef([])
  const [errorModal, setErrorModal] = useState(false)
  const rnvFile = useRef()

  /**REDUX**/
    // get user reducers
  const enabledSections = useSelector(state => state.userData.user?.enabled_frontend_sections || [])
  const returnValues = useSelector(state => state.openData.return_values || {})
  const token = useSelector(state => state.userData.token)
  const userDataUser = useSelector(state => state.userData.user)
  const userId = useSelector(state => state.userData.user.id)
  const benchmarks = useSelector(state => state.userData.benchmarks)
  const bodyBorderShow = useSelector(state => state.analyseData.bodyBorderShow)
  const ltOffset = useSelector(state => state.analyseData.ltOffset)
  const benchmarkIs = useSelector(state => state.analyseData.result.benchmark)
  const shortLanguage = convertLanguagesToShort(i18nLanguage)
  const maxRequestSize = useSelector(state => state.openData?.ping?.maxRequestSize || 1000000)
  const customOptions = useSelector(state => state.userData.user?.custom_options || {})
  const aiMode = useSelector(state => state.analyseData.aiMode)

  // get analyse reducers
  const resultTemplate = useSelector(state => state.analyseData.result.resultTemplate)
  const resultData = useSelector(state => state.analyseData.result.resultData)
  const resultFull = useSelector(state => state.analyseData.result)
  const dispatch = useDispatch()

  const text = useSelector(state => state.analyseData.text)
  const modalAccordionOpen = useSelector(state => state.analyseData.modalAccordionOpen)
  //const topAccordionOpen = useSelector(state => state.analyseData.topAccordionOpen);
  const backendLanguages = useSelector(state => state.userData.user.backend_languages)
  const textLanguage = useSelector(state => state.analyseData.textLanguage)
  const autoTextLanguage = useSelector(state => state.analyseData.autoTextLanguage)
  const ignoreList = useSelector(state => state.analyseData.ignoreList)
  const enabledBenchmarks = useSelector(state => state.analyseData.enabledBenchmarks)
  const useBenchmark = useSelector(state => state.analyseData.useBenchmark)

  // get popover reducers
  const popups = useSelector(state => state.analyseData.popover.popups)
  const currentPopup = useSelector(state => state.analyseData.popover.currentPopup)

  // get storage data reducers

  const storageAnalyseTonality = convertJSONToObject(useSelector(state => state.userData?.user?.user_options?.analyse_tonality), config.defaultAnalyseTonality)
  const storageLanguageTextType = convertJSONToObject(
    useSelector(
      state => state.userData?.user?.user_options?.analyse_language_texttype),
    config.defaultAnalyseLanguageTextType)

  if (typeof storageLanguageTextType.textType !== 'object') {
    storageLanguageTextType.textType = {}
  }

  /**BIND ACTIONS**/
  const tlService = React.useContext(TLServiceComponent)
  //const ltService = React.useContext(LTServiceComponent);

  const {
    actionBatchProcessingClear,
    actionUserSaveOption,
    actionUserGetAllBenchmarks,
    actionUserSetOption,
    actionAnalyseSetResult,
    actionAnalyseClearResult,
    actionAnalyseClearSaveArchive,
    actionAnalyseSetPopups,
    actionAnalyseSetCurrentPopup,
    actionAnalyseClearPopovers,
    actionAnalyseSetText,
    actionAnalyseSetOldText,
    actionAnalyseSetTopAccordion,
    actionAnalyseSetModalAccordion,
    actionAnalyseClearIgnoreList,
    actionAnalyseSetUseBenchmark,
    actionAnalyseSetEnabledBenchmark,
    actionAnalyseSetIgnoreList,
    actionAnalyseSetTextLanguage,
    actionAnalyseSetAutoTextLanguage,
    actionAnalyseSetLanguageToolResult,
    actionAnalyseSetBodyBorderShow,
    actionAnalyseSetLtOffset,
    actionClearAccordionItemsWithCells,
    actionUserGetAllowLists,
    actionUserGetTextTone,
    actionUserGetNNPaths,
  } = useActions({
    actionBatchProcessingClear: batchProcessingClear,
    actionUserSetOption: userSetOption,
    actionAnalyseSetTextLanguage: analyseSetTextLanguage,
    actionAnalyseSetAutoTextLanguage: analyseSetAutoTextLanguage,
    actionAnalyseSetIgnoreList: analyseSetIgnoreList,
    actionAnalyseClearIgnoreList: analyseClearIgnoreList,
    actionAnalyseSetResult: analyseSetResult,
    actionAnalyseSetUseBenchmark: analyseSetUseBenchmark,
    actionAnalyseSetEnabledBenchmark: analyseSetEnabledBenchmark,
    actionAnalyseClearResult: analyseClearResult,
    actionAnalyseClearSaveArchive: analyseClearSaveArchive,
    actionAnalyseSetPopups: analyseSetPopups,
    actionAnalyseSetCurrentPopup: analyseSetCurrentPopup,
    actionAnalyseClearPopovers: analyseClearPopovers,
    actionAnalyseSetText: analyseSetText,
    actionAnalyseSetOldText: analyseSetOldText,
    actionAnalyseSetTopAccordion: analyseSetTopAccordion,
    actionAnalyseSetModalAccordion: analyseSetModalAccordion,
    actionAnalyseSetBodyBorderShow: analyseSetBodyBorderShow,
    actionAnalyseSetLtOffset: analyseSetLtOffset,
    actionClearAccordionItemsWithCells: analyseClearAccordionItemsWithCells,
    actionUserSaveOption: userSaveOption(tlService),
    actionUserGetAllBenchmarks: userGetAllBenchmarks(tlService),
    actionAnalyseSetLanguageToolResult: analyseSetLanguageToolResult(tlService),
    actionUserGetAllowLists: userGetAllowLists(tlService),
    actionUserGetTextTone: userGetTextTone(tlService),
    actionUserGetNNPaths: userGetNNPaths(tlService),

  })

  /**STATES**/
    // main states

  const [error, setError] = useState(null)
  const [submitted, setSubmitted] = useState(false)

  const keepTopAccordion = useRef('')
  const keepModalAccordion = useRef('')

  // buttons
  const [analyseButtonEnable, setAnalyseButtonEnable] = useState(false)
  const [mustReload, setMustReload] = useState(false)

  // open popups states
  const [fixPopup, setFixPopup] = useState(false)
  const [popupHeader, setPopupHeader] = useState('')
  const [popupText, setPopupText] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [cumulativeEditor, setCumulativeEditor] = useState({ top: 0, left: 0 })
  const [cumulativeElement, setCumulativeElement] = useState({ top: 0, left: 0 })
  const [elementWidth, setElementWidth] = useState(0)
  const [popupComponent, setPopupComponent] = useState(null)

  // link to editor state
  const [editor, setEditor] = useState(null)
  const [selectedText, setSelectedText] = useState(undefined)
  const [modalTitle, setModalTitle] = useState()

  // modal dialog states
  const [dialogData, setDialogData] = useState(null)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogTitle, setDialogTitle] = useState('')
  const [dialogText, setDialogText] = useState('')
  const [dialogYesText, setDialogYesText] = useState('')
  const [dialogNoText, setDialogNoText] = useState('')

  // batch modal states
  const [batchModalOpen, setBatchModalOpen] = useState(false)
  const [batchModalFiles, setBatchModalFiles] = useState([])

  const [showUploadBar, setShowUploadBar] = useState(false)
  const [showLoadText, setShowLoadText] = useState(false)
  const [loadBar, setLoadBar] = useState(0)
  const [uploadBarNow, setUploadBarNow] = useState(0)

  const batchProcessingState = useSelector(state => state.batchData)

  // config for batch processing uploads
  const batchProcessingMaxFileCount = 2000
  //const batchProcessingMaxFileSizeInBytes = 8000000; // 2,5 MB
  const docUploadAllowedMimeTypes = [
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/pdf',
    'text/plain',
  ]

  /**HOOKS**/

  // on mount
  useEffect(()=> {
    actionUserGetNNPaths(token)
    if (isObjectEmpty(benchmarks)) {
      actionUserGetAllBenchmarks(token)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    if (!!textLanguage) {
      // get allow list
      actionUserGetAllowLists(token, textLanguage)

      // get text tone
      actionUserGetTextTone(token, textLanguage)
    }
    if (!textLanguage) {
      actionAnalyseSetTextLanguage(
        convertLanguagesToFull(i18nLanguage))
    }
    if (!autoTextLanguage) {
      actionAnalyseSetAutoTextLanguage(
        convertLanguagesToFull(i18nLanguage))
    }

    // clean tags if text
    text && actionAnalyseSetText(removeTagMark(text))

    // eslint-disable-next-line
  }, [textLanguage])

  useEffect(() => {
    //set current use benchmark when landing on analysis page:
    actionAnalyseSetUseBenchmark(storageLanguageTextType.textType[textLanguage] || (!isObjectEmpty(enabledBenchmarks) ? parseInt(Object.keys(enabledBenchmarks)[0]) : 0))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textLanguage])

  // on  Modal close clear all .selected
  useEffect(() => {
    if (!openModal && editor) {
      editor.dom.removeClass(editor.dom.select('mark'), 'selected')
    }
    // eslint-disable-next-line
  }, [openModal])

  // control open accordion modal
  useEffect(() => {
    actionAnalyseSetCurrentPopup(-1)

    if (modalAccordionOpen !== '') {
      const [element, key] = modalAccordionOpen.split('_')
      reloadHighlightEditor(element, key, resultFull, bodyBorderShow, text, ignoreList,
        i18nLanguage)
    } else {
      // close modal if opened
      setOpenModal(false)
      setFixPopup(false)
      reloadHighlightEditor('', '', resultFull, bodyBorderShow, text, ignoreList,
        i18nLanguage)
    }

    // eslint-disable-next-line
  }, [modalAccordionOpen])

  // control language settings
  // set-unset auto detection
  useEffect(() => {
    if (storageLanguageTextType.language !== 'auto') {
      actionAnalyseSetTextLanguage(resultFull?.benchmark?.locale_name || storageLanguageTextType.language)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storageLanguageTextType.language])

  // control textype settings
  useEffect(() => {
    if (storageLanguageTextType.textType[textLanguage] &&
      benchmarks[storageLanguageTextType.textType[textLanguage]]) {
      if ((resultTemplate > 0 && enabledBenchmarks[resultTemplate] !== undefined)) {
        actionAnalyseSetUseBenchmark(resultTemplate)
      } else {
        actionAnalyseSetUseBenchmark(storageLanguageTextType.textType[textLanguage] || (!isObjectEmpty(enabledBenchmarks) ? parseInt(Object.keys(enabledBenchmarks)[0]) : 0))
      }
    }

    if (!storageLanguageTextType.textType[textLanguage] &&
      storageLanguageTextType.language === 'auto') {
      actionAnalyseSetUseBenchmark((!isObjectEmpty(enabledBenchmarks) ? parseInt(Object.keys(enabledBenchmarks)[0]) : 0))
    }
    // eslint-disable-next-line
  }, [benchmarks, JSON.stringify(storageLanguageTextType.textType), enabledBenchmarks])

  // on lang or benchmarks change
  useEffect(() => {
    if (textLanguage && !isObjectEmpty(benchmarks)) {
      const filteredBenchmark = (filterBenchmark(benchmarks, textLanguage,
        'locale_name'))
      if (!isObjectsEqual(filteredBenchmark, enabledBenchmarks)) {
        actionAnalyseSetEnabledBenchmark(filteredBenchmark)
        if (isObjectEmpty(filteredBenchmark) &&
          isObjectEmpty(storageLanguageTextType.textType))
          actionAnalyseSetUseBenchmark(0)
      }
    }
    // eslint-disable-next-line
  }, [benchmarks, textLanguage])

  // set the first benchmark in list if  language is changed
  useEffect(() => {
    if (!isObjectEmpty(enabledBenchmarks) && enabledBenchmarks[useBenchmark] ===
      undefined) {
      actionAnalyseSetUseBenchmark(parseInt(Object.keys(enabledBenchmarks)[0]))
    }
    // eslint-disable-next-line
  }, [enabledBenchmarks])

  // reload if ignore list changed
  // disabled!!
  /*
  useEffect(() => {
      if (modalAccordionOpen !== '') {
          const [element, key] = modalAccordionOpen.split("_")
          reloadHighlightEditor(element, key, resultFull, text, ignoreList);
      }

      // eslint-disable-next-line
  }, [ignoreList])
  */

  // enable analyse button if benchmark is changed
  useEffect(() => {
    if (resultTemplate > 0) {
      if (useBenchmark === 0 || text.length < config.minSize || text.length >
        maxRequestSize) {
        setMustReload(false)
      }
    } else {
      setMustReload(false)
      if (useBenchmark > 0 && text.length > config.minSize && text.length <
        maxRequestSize) {
        setAnalyseButtonEnable(true)
      } else {
        setAnalyseButtonEnable(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    useBenchmark,
    text,
    resultTemplate,
    textLanguage,
    autoTextLanguage,
    enabledBenchmarks])

  //  Display benchmark which is in the result instead of default benchmark value when land on page :
  useEffect(() => {
    Object.entries(enabledBenchmarks).map(([key, val]) => {
        if (!!benchmarkIs) {
          if (benchmarkIs['name'] !== undefined && val['name'] !== undefined) {
            if (benchmarkIs['name'][shortLanguage] ===
              val['name'][shortLanguage]) {
              actionAnalyseSetUseBenchmark(key)
            }
          }
        }
      },
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**SERVICE FUNCTION**/

  function closePopupWindow () {
    setOpenModal(false)
    setFixPopup(false)
  }

  function reloadHighlightEditor (
    element, key, resultFull, bodyBorderShow, text, ignoreList, language) {
    const currentBenchmarkSettings = enabledBenchmarks[useBenchmark] !==
    undefined ? enabledBenchmarks[useBenchmark] : {}
    if (element !== '') {
      const {
        text: newText,
        popups: newPopups,
        ltOffset: newLtOffset,
      } = highlightEditor(element, key, resultFull, bodyBorderShow, text, ignoreList, language,
        currentBenchmarkSettings, t, userDataUser)
      actionAnalyseSetText(newText)
      actionAnalyseSetPopups(newPopups)
      actionAnalyseSetLtOffset(newLtOffset)
    } else {
      actionAnalyseSetLtOffset(0)
      const newText = removeTagMark(text, bodyBorderShow)
      text !== newText && actionAnalyseSetText(newText)
      actionAnalyseClearPopovers()
    }
  }

  function clearResult () {
    actionAnalyseClearResult()
    actionAnalyseClearPopovers()
    closePopupWindow()
    actionAnalyseSetModalAccordion('')
    actionAnalyseSetTopAccordion('')
  }

  // ************************

  function openSneakPreviewDialog () {
    setDialogTitle(t('sneak preview modal title'))
    setDialogText(t('sneak preview modal text'))
    setDialogYesText('Ok')
    setDialogNoText('')
    setDialogOpen(true)
  }

  function openCountWordDialog () {
    setDialogData({ type: 'handleWordDialog' })
    setDialogTitle(t('count word modal title'))
    setDialogText(t('count word modal text'))
    setDialogYesText('Ok')
    setDialogNoText('')
    setDialogOpen(true)
  }

  function handleDefaultClick () {
    setDialogData({ type: 'handleDefaultClick' })
    openSneakPreviewDialog()
  }

  function openMustReloadDialog () {
    setDialogTitle(t('Need Reload Dialog Title'))
    setDialogText(t('Need Reload Dialog Text'))
    setDialogYesText(t('Need Reload Dialog Yes'))
    setDialogNoText(t('Need Reload Dialog No'))
    setDialogOpen(true)
  }

  function openStartNewDialog () {
    setDialogTitle(t('Start New Dialog Title'))
    setDialogText(t('Start New  Dialog Text'))
    setDialogYesText(t('Start New  Dialog Yes'))
    setDialogNoText(t('Start New  Dialog No'))
    setDialogOpen(true)
  }

  /**HANDLE FUNCTIONS**/

  async function handleDownloadAsFile (type) {
    // document.getElementById('text-handwrite_ifr').contentWindow.document.getElementById('tinymce')
    uploadAsFile('download', text, type)
  }

  function handleShowBorders (bodyBorderShow) {
    if (bodyBorderShow) {
      if (mustReload) {
        //actionAnalyseSetBodyBorderShow(false);
        openMustReloadDialog()
        return false
      } else
        actionAnalyseSetText(textAddBodyBorders(text, resultData, ltOffset))
    } else {
      actionAnalyseSetText(removeBodyBorders(text))
    }
    actionAnalyseSetBodyBorderShow(bodyBorderShow)
    return true
  }

  function handleOnEditorInit (event, newEditor) {
    setEditor(newEditor)
  }

  function setAutoLanguage (text) {
    const lang = setLanguage(text)
    actionAnalyseSetAutoTextLanguage(lang)
    if (lang !== textLanguage) {
      handleOnLanguageChange(lang)
    }
  }

  function handleOnEditorChange (newText, newEditor) {

    // fix bug 839
    newText = newText.replace(/[\xAD]/g, '')
    actionAnalyseSetText(newText)
    actionAnalyseSetOldText(newText)
    const cuttedOldText = removeTagMark(text)
    const cuttedText = removeTagMark(newText)
    if (cuttedOldText !== cuttedText) {
      if (resultTemplate > 0) {
        //console.log("findFirstDiff",findFirstDiff(cuttedOldText, cuttedText, 20))
        setMustReload(true)
      }
    }
    const lang = setLanguage(newText)
    actionAnalyseSetAutoTextLanguage(lang ? lang : convertLanguagesToFull(i18nLanguage))
    // set lang if auto
    if (storageLanguageTextType?.language === 'auto') {
      if (!resultTemplate) {
        !lang && actionAnalyseSetTextLanguage(convertLanguagesToFull(i18nLanguage))
        if (lang !== textLanguage && (!isObjectEmpty(backendLanguages) && Object.keys(backendLanguages).includes(lang))) {
          handleOnLanguageChange(lang)
        }
      } else {
        actionAnalyseSetTextLanguage(resultFull?.benchmark?.locale_name)
      }
    }
    newEditor && setEditor(newEditor)
  }

  function handleOnEditorKeyUp (key, editor) {
  }

  function handleOnSelectorChange (key, editor) {
    // Set the Text select by the User:
    const content = editor.selection.getContent()
    if (content?.length) {
      setSelectedText(editor.selection)
    } else {
      setSelectedText(undefined)
    }
    setEditor(editor)
  }

  function openModalWindow (element) {
    if (element.target.nodeName === 'MARK' && element.target.id) {

      // scroll to element
      element.target.scrollIntoView({ block: 'nearest', behavior: 'auto' })

      //set selected class
      editor.dom.removeClass(editor.dom.select('mark'), 'selected')
      editor.dom.addClass(`${element.target.id}`, 'selected')

      // { x: 0, y: 1636, w: 1034, h: 571 }
      const editorPort = editor.dom.getViewPort(editor.getWin())

      let newCumulativeEditor = cumulativeOffset(editor.container)
      newCumulativeEditor['editorPort'] = editorPort
      let newCumulativeElement = cumulativeOffset(element.target)
      newCumulativeElement.top -= editorPort.y
      newCumulativeElement.left -= editorPort.x
      const elementWidth = element.target.offsetWidth
      const popId = parseInt(element.target.id.slice(2))
      if (popups[popId]) {
        if (currentPopup !== popId)
          actionAnalyseSetCurrentPopup(popId)
        setCumulativeElement(newCumulativeElement)
        setCumulativeEditor(newCumulativeEditor)
        setElementWidth(elementWidth)
        setPopupComponent(popups[popId].component)
        setPopupText(element.target.innerText)
        setOpenModal(true)

      } else {
        closePopupWindow()
      }

    } else {
      closePopupWindow()
    }
    return true
  }

  function handleOnMouseOver (element, newEditor) {
    // element, newEditor
    newEditor && setEditor(newEditor)
    console.log('handleOnMouseOver fixPopup openModal', fixPopup, openModal)
    if (!fixPopup) {
      openModalWindow(element)
    }
    return true
  }

  function handleOnMouseClick (element, newEditor) {
    setFixPopup(true)
    newEditor && setEditor(newEditor)
    openModalWindow(element)
    return true
  }

  function handleOnPopupClick (index, title) {
    // Title for the modal popup:
    setModalTitle(title)
    if (index < 0 || index >= popups.length)
      return false

    const element = editor.dom.get(`id${popups[index].index.toString()}`)
    console.log('handleOnPopupClick', element)
    if (element)
      handleOnMouseClick({ target: element }, null)
    actionAnalyseSetCurrentPopup(index)
    return true
  }

  function getNextPopup (direction, currentPopup, enabledTypes = []) {

    let newCurrentPopup = currentPopup + direction
    while (newCurrentPopup >= 0 && newCurrentPopup < popups.length) {
      if (enabledTypes.length === 0 ||
        enabledTypes.indexOf(popups[newCurrentPopup].component) > -1)
        return newCurrentPopup
      newCurrentPopup += direction
    }
    return -1
  }

  function handleRestartAll () {
    setDialogData({ type: 'handleRestartAll' })
    openStartNewDialog()
  }

  function handleOnLanguageChange (value) {
    actionAnalyseSetTextLanguage(value)
    if (resultTemplate > 0) {
      setMustReload(true)
    }
  }

  function handleOnTextTypeChange (value) {
    if (!isObjectEmpty(enabledBenchmarks) && value !== useBenchmark &&
      enabledBenchmarks[value] !== undefined) {
      actionAnalyseSetUseBenchmark(value)
      if (resultTemplate > 0) {
        setMustReload(true)
      }
    }
  }

  function handleOnAnalyseClick () {
    startAnalysisForText(text)
  }

  function handleAnalyseFromAi (text) {
    startAnalysisForText(text)
  }

  async function startAnalysisForText (text) {
    console.log('start analyse')
    actionBatchProcessingClear()
    actionClearAccordionItemsWithCells()
    const enableLanguageTool = enabledBenchmarks[useBenchmark]?.settings?.languageTool?.values?.enabled || false
    const clearText = removeTagMark(text, false)
    setMustReload(false)
    closePopupWindow()
    setDialogOpen(false)
    if (useBenchmark === 0) {
      setAnalyseButtonEnable(false)
    } else {
      setSubmitted(true)
      // check for size
      const checkedSizeError = checkTextSize(config, clearText.length,
        stripHtml(clearText).length)
      if (checkedSizeError) {
        console.log('checkedSizeError error ')
        setError({ text: t(checkedSizeError), header: t(checkedSizeError) })
        setSubmitted(false)
      } else {
        clearResult()


        let result
        try {
          result = await startAnalyse(useBenchmark, textLanguage, clearText,
            tlService, token, t)
        } catch (e) {

        }

        result.error && console.log('result.error ', result.error)
        console.log('resultData', result.resultData)
        setError(result.error)
        actionAnalyseSetResult(result.resultData, result.resultTemplate,
          benchmarks[result.resultTemplate])
        actionAnalyseSetLtOffset(0)

        // if textTextCleaning is set - update editor!
        if (!!result?.resultData?.textTextCleaning ||
          !!result?.resultData?.convertedText) {

          const newText = !!result?.resultData?.textTextCleaning
            ? result.resultData.textTextCleaning
            : result.resultData.convertedText

          // start languagetool with clear text
          //if (enableLanguageTool && result.resultData.languageTool === undefined) {
          //    actionAnalyseSetLanguageToolResult(token, textLanguage, newText, result.resultData, enabledBenchmarks[useBenchmark]);
          //}

          if (bodyBorderShow)
            actionAnalyseSetText(
              textAddBodyBorders(newText, result.resultData, 0))
          else
            actionAnalyseSetText(newText)
        } else {

          // start langtool with text
          if (enableLanguageTool && result.resultData.languageTool ===
            undefined) {
            actionAnalyseSetLanguageToolResult(token, textLanguage, clearText,
              result.resultData, enabledBenchmarks[useBenchmark])
          }
          if (bodyBorderShow)
            actionAnalyseSetText(
              textAddBodyBorders(clearText, result.resultData, 0))
          else
            actionAnalyseSetText(clearText)
        }
        setSubmitted(false)

        // If count word is 0 then show warning msg:
        if (result.resultData.countWords === 0) {
          openCountWordDialog()
        }

        // keep
        actionAnalyseSetTopAccordion(keepTopAccordion.current)
        actionAnalyseSetModalAccordion(keepModalAccordion.current)
      }
    }
  }

  function handleSaveAnalyseTonality (name, value) {
    const newTonality = { ...storageAnalyseTonality, [name]: value }
    actionUserSetOption('analyse_tonality', JSON.stringify(newTonality))
    actionUserSaveOption(token, userId, 'analyse_tonality',
      JSON.stringify(newTonality))
  }

  function handleChangeAnalyseTonality (name, value) {
    const newTonality = { ...storageAnalyseTonality, [name]: value }
    if (!isObjectsEqual(newTonality, storageAnalyseTonality)) {
      actionUserSetOption('analyse_tonality', JSON.stringify(newTonality))
      actionUserSaveOption(token, userId, 'analyse_tonality',
        JSON.stringify(newTonality))
    }
  }

  function handleChangeAnalyseTextType (name, value) {
    const newTextType = { ...storageLanguageTextType, [name]: value }
    actionUserSetOption('analyse_language_texttype',
      JSON.stringify(newTextType))
    actionUserSaveOption(token, userId, 'analyse_language_texttype',
      JSON.stringify(newTextType))
    if (name === 'language' && value === 'auto') {
      setAutoLanguage(text)
    }
  }

  function handleUploadFile (acceptedFiles) {
    setSubmitted(true)
    readDNDFiles(token, tlService, acceptedFiles, t).then(async result => {
      setError(result.error)
      clearResult()

      const text = result.text.replace(/<a id[^>]+><\/a>/g, '')

      if (enabledSections.indexOf('images_to_text') > -1) {
        // match
        const regexp = /<img[^>]+src="[^>]+base64,([^>]+)"[^>]*>/sg
        let match
        let newText = ''
        let lastPosition = 0
        while ((match = regexp.exec(text)) !== null) {
          try {
            const res = await tlService.runRedirect(token, { "body": { "image": match[1] } }, 'ImageToText')
            if (res?.data?.text) {
              newText += text.substring(lastPosition, match.index) + `<img alt="${res.data.text}" title="${res.data.text}" `
                + text.substring(match.index + 4, regexp.lastIndex)
            } else {
              newText += text.substring(lastPosition, regexp.lastIndex)
            }
          } catch (e) {
            console.log('e', e)
            newText += text.substring(lastPosition, regexp.lastIndex)
          }
          lastPosition = regexp.lastIndex
        }
        newText += text.substring(lastPosition)
        handleOnEditorChange(newText, false)
      } else {
        handleOnEditorChange(text, false)
      }
      setSubmitted(false)
    })
  }

  function evalFormula (evCond, res, arr = {}, hiddenValues = {}) {
    const re = /{([^}]+)}/g
    const fReplace = [...evCond.matchAll(re)]
    if (fReplace.length > 0) {
      for (let i of fReplace) {
        if (res[i[1]] !== undefined) {
          evCond = evCond.replaceAll(i[0], res[i[1]])
        } else if (arr[i[1]] !== undefined) {
          evCond = evCond.replaceAll(i[0],
            parseFloat(arr[i[1]].toString().replace(',', '.')).toString())
        } else if (hiddenValues[i[1]] !== undefined) {
          evCond = evCond.replaceAll(i[0], hiddenValues[i[1]])
        }
      }
    }
    return evCond
  }

  async function loadBenchResult (
    arr, res, text, listOfValues, addText, normalize) {
    const hiddenValues = {}

    if (addText) {
      if (!!res?.textTextCleaning || !!res?.convertedText) {
        arr['Text'] = !!res?.textTextCleaning
          ? res.textTextCleaning
          : res.convertedText
      }
    }
    if (!res) {
      return arr
    }
    for (const val of listOfValues) {
      /* eslint no-eval: 0 */
      if (val?.condition) {
        // eval
        const evCond = evalFormula(val.condition, res)
        if (!eval(evCond)) {
          arr[val.name] = val?.notValid || 'nicht auswertbar'
          continue
        }
      }
      let normalizer
      if (val.type === 'arrayofarrays' || val.type === 'category') {
        const valName = val.valueName.split('.')
        normalizer = returnValues[valName[0]]?.settings?.normalizer || ''
      } else {
        normalizer = returnValues[val.valueName]?.settings?.normalizer || ''
      }
      if (val.type === 'number') {
        arr[val.name] = roundWithPrecision(parseFloat(res[val.valueName]), 2,
          true).replace('.', ',')
      } else if (val.type === 'integer') {
        arr[val.name] = parseInt(res[val.valueName])
          .toString()
          .replace('.', ',')
      } else if (val.type === 'array') {
        if (res[val.valueName])
          arr[val.name] = res[val.valueName].length.toString()
            .replace('.', ',')
        else
          arr[val.name] = 0
      } else if (val.type === 'arrayofarrays') {
        const valName = val.valueName.split('.')
        if (res[valName[0]] && res[valName[0]][valName[1]]) {
          arr[val.name] = res[valName[0]][valName[1]].length.toString()
            .replace('.', ',')
        } else {
          arr[val.name] = 0
        }
      } else if (val.type === 'category') {
        const valName = val.valueName.split('.')
        if (res[valName[0]] && res[valName[0]][valName[1]]) {
          const cat = res[valName[0]][valName[1]].result
          let count = 0
          for (let catRes of cat) {
            count += catRes.position.length
          }
          arr[val.name] = count.toString().replace('.', ',')
        } else {
          arr[val.name] = 0
        }
      }
      if (val.type === 'formula' && val?.formula?.length) {
        // formula
        const formulaCond = evalFormula(val.formula, res, arr, hiddenValues)
        let result = undefined
        try {
          result = eval(formulaCond)
        } catch (e) {
          console.log('error formula', formulaCond, arr)
        }
        if (result !== undefined) {
          arr[val.name] = roundWithPrecision(parseFloat(result), 2, true)
            .replace('.', ',')
        }
      } else if (normalizer.length > 0 && parseFloat(res[normalizer]) > 0) {
        // normalizer calculate
        // ignore it for the formula
        if (normalize) {
          arr[val.name] = roundWithPrecision(
            parseFloat(arr[val.name].toString().replace(',', '.')) /
            (parseFloat(res[normalizer]) / 100), 2, true).replace('.', ',')
        }
      }
      // if hidden == true -> remove it from the result and add to the hidden values to use in "formula"
      if (val?.hidden) {
        hiddenValues[val.name] = parseFloat(
          arr[val.name].toString().replace(',', '.'))
        delete arr[val.name]
      }
    }
    return arr
  }

  function handleStopRVAnalyse () {
    runRV.current = false
    console.log('handleStopRVAnalyse', runRV.current)
  }

  const incorrectBaustein_keys = []

  async function checkKeys (acceptedFiles) {
    if (acceptedFiles.length !== 1) {
      setError(
        { text: t('File upload error'), header: t('File upload error') })
      return
    }
    if (acceptedFiles[0]['type'] !== 'text/csv' && acceptedFiles[0]['type'] !==
      'application/vnd.ms-excel') {
      setError({
        text: t('Unsupported file type'),
        header: t('Unsupported file type'),
      })
      return
    }
    if (customOptions['ruv_button'] === undefined) {
      console.log('ruvConfig doesn\'t exist')
      return
    }
    const fileContents = await readUploadedFileAsBinary(acceptedFiles[0], true)
    const res = Papa.parse(fileContents, {
      header: true, delimiter: '',
    })
    if (!res || !res.data.length) {
      setError({
        text: t('Unsupported file type'),
        header: t('Unsupported file type'),
      })
      return
    }
    const requiredKeys = [
      'sachgebiet',
      'herkunft',
      'standardbrief',
      'standardbrief_beschreibung',
      'briefrahmen',
      'baustein_keys',
      'anzahl',
      'baustein_nr',
      'baustein_key',
      'dokument_anfang',
      'name',
      'Geaendert',
      'Text']
    const keys = Object.keys(res.data[0])
    const miss = []
    for (let key of requiredKeys) {
      if (keys.indexOf(key) === -1) {
        miss.push(key)
      }
    }
    if (miss?.length) {
      setError({
        text: t('missing at least one column') + miss.join(', '),
        header: t('missing at least one column_title'),
      })
      return
    }
    const data = res.data
    rnvFile.current = data
    let counterIs = 1
    for (let j = 1; j < data.length; j++) {
      setLoadBar((Math.round(j / (data.length / 100))))
      // Add the first unque keys:
      if (!incorrectBaustein_keys.includes(data[j]['baustein_keys'])) {
        incorrectBaustein_keys.push(data[j]['baustein_keys'])
      } else if ((data[j - 1]['baustein_keys'] !== data[j]['baustein_keys']) &&
        (incorrectBaustein_keys.includes(data[j]['baustein_keys']))) {
        // Add the repeated unorder keys:
        alertMsg.current.push(t('Warning-Same-Textbaustein_keys-{{i}}',
          { i: j + 2, num: counterIs }))
        counterIs = counterIs + 1
      }
    }
    // Open modal after all error are collected:
    if (alertMsg.current?.length) {
      setShowLoadText(false)
      setLoadBar(0)
      setErrorModal(true)
    }
    // If all ordered correctly then proceed by default:
    if (alertMsg.current.length === 0) {
      setShowLoadText(false)
      setLoadBar(0)
      runRV.current = true
      setShowUploadBar(true)
      setUploadBarNow(0)
      handleRVUpload()
    }
  }

  async function handleRVUpload () {
    const data = rnvFile.current
    const normalize = false
    const hashObject = {}
    if (customOptions['ruv_button'] === undefined) {
      console.log('ruvConfig doesn\'t exist')
      return
    }
    const ruvConfig = customOptions['ruv_button']
    let counter = 0
    let buildingKeys = ''
    let prevBuildingKeys = ''
    let fullText = []
    const columns = []
    if (data?.length) {
      // sdd list of column
      for (let name of Object.keys(data[0])) {
        columns.push(name)
      }
      for (let name of ruvConfig.rvExportData) {
        if (!name?.hidden)
          columns.push(name.name)
      }
      let i = data.length - 1
      do {
        // percent counter
        counter++
        setUploadBarNow(Math.round(counter / (data.length / 100)))
        // break if the button "stop" is pushed
        if (!runRV.current) {
          setShowUploadBar(false)
          setUploadBarNow(0)
          return
        }
        if (data[i]?.Text?.length) {
          if (buildingKeys === '') {
            data[i]['name'] = data[i]['sachgebiet'].toString().split('/')[0] + '_' + data[i]['name']
            buildingKeys = data[i]['sachgebiet'].concat('-', data[i]['standardbrief']).concat('-', data[i]['baustein_keys'])
            prevBuildingKeys = buildingKeys
            fullText = []
          } else {
            data[i]['name'] = data[i]['sachgebiet'].toString().split('/')[0] + '_' + data[i]['name']
            buildingKeys = data[i]['sachgebiet'].concat('-', data[i]['standardbrief']).concat('-', data[i]['baustein_keys'])
          }
          try {
            const hash = md5Generator(data[i].Text)
            if (hashObject[hash] === undefined) {
              hashObject[hash] = await tlService.runBenchmark(token,
                ruvConfig.rvExportValues.partBenchmark,
                ruvConfig.rvExportValues.benchmarkLang, data[i].Text)
            }
            data[i] = await loadBenchResult(data[i], hashObject[hash],
              data[i].Text, ruvConfig.rvExportData, true, normalize)
          } catch (e) {
            console.log('error', e)
          }
          // add full
          if (buildingKeys !== prevBuildingKeys) {
            let arrRes = {}
            arrRes['Text'] = fullText.reverse().join(ruvConfig.rvExportValues.concatText)
            arrRes['dokument_anfang'] = 'Brief'
            try {
              const hash = md5Generator(arrRes['Text'])
              if (hashObject[hash] === undefined) {
                hashObject[hash] = await tlService.runBenchmark(token,
                  ruvConfig.rvExportValues.summaryBenchmark,
                  ruvConfig.rvExportValues.benchmarkLang, arrRes['Text'])
              }
              arrRes = await loadBenchResult(arrRes, hashObject[hash],
                arrRes['Text'], ruvConfig.rvSumExportData, true, normalize)
            } catch (e) {
              console.log('error', e)
            }
            if (data[i + 1]) {
              for (let copyVal of ruvConfig.ruvCopyValues) {
                arrRes[copyVal] = data[i + 1][copyVal]
              }
            }
            data.splice(i + 1, 0, arrRes)
            fullText = []
          }
          prevBuildingKeys = buildingKeys
        }
        fullText.push(data[i].Text)
        i--
      } while (i >= 0)
      // last one at the end
      if (fullText.length > 0) {
        let arrRes = {}
        arrRes['Text'] = fullText.reverse().join(ruvConfig.rvExportValues.concatText)
        arrRes['dokument_anfang'] = 'Brief'
        try {
          const hash = md5Generator(arrRes['Text'])
          if (hashObject[hash] === undefined) {
            hashObject[hash] = await tlService.runBenchmark(token,
              ruvConfig.rvExportValues.summaryBenchmark,
              ruvConfig.rvExportValues.benchmarkLang, arrRes['Text'])
          }

          arrRes = await loadBenchResult(arrRes, hashObject[hash],
            arrRes['Text'], ruvConfig.rvSumExportData, true, normalize)
        } catch (e) {
          console.log('error', e)
        }

        if (data[i + 1]) {
          for (let copyVal of ruvConfig.ruvCopyValues) {
            arrRes[copyVal] = data[i + 1][copyVal]
          }
        }
        data.splice(0, 0, arrRes)
      }
    }
    setShowUploadBar(false)
    setUploadBarNow(0)
    let stringResult = Papa.unparse(data,
      { delimiter: ';', columns: columns, quotes: true })
    const blob = new Blob(['\ufeff' + stringResult], { type: 'text/csv' })
    const element = document.createElement('a')
    element.href = URL.createObjectURL(blob)
    element.download = `${data[0]['sachgebiet'].toString().split('/')[0]}_result.csv`
    document.body.appendChild(element) // Required for this to work in FireFox
    element.click()
  }

  function handleBatchProcessingUpload (acceptedFiles) {

    ///console.log('handleBatchProcessingUpload', acceptedFiles)
    actionBatchProcessingClear()

    if (acceptedFiles.length === 0) {
      return
    }

    setSubmitted(true)

    // if only one file OR archive is disabled!!
    if (acceptedFiles.length === 1 || enabledSections.indexOf('archive') === -1) {
      dispatch(analyseSetFileName(acceptedFiles[0]?.name))
      handleUploadFile(acceptedFiles)
      return
    }

    if (batchProcessingState.running) {
      setError({
        text: t('batchProcessingErrorAlreadyRunning'),
        header: t('batchProcessingErrorHeader'),
      })
      setSubmitted(false)
      return
    }

    if (checkBatchprocessingFiles(acceptedFiles)) {
      clearResult()
      setSubmitted(false)
      setBatchModalFiles(acceptedFiles)
      setBatchModalOpen(true)
    } else {
      setSubmitted(false)
    }

  }

  function checkBatchprocessingFiles (acceptedFiles) {
    if (acceptedFiles.length > batchProcessingMaxFileCount) {
      setError({
        text: t('batchProcessingErrorFileCount'),
        header: t('batchProcessingErrorHeader'),
      })
      return false
    }
    // let fileSizeSum = 0;
    for (let i = 0, length = acceptedFiles.length; i < length; ++i) {
      let file = acceptedFiles[i]
      // fileSizeSum += file.size;

      if (docUploadAllowedMimeTypes.indexOf(file.type) === -1) {
        setError({
          text: t('batchProcessingErrorMimeTypes'),
          header: t('batchProcessingErrorHeader'),
        })
        return false
      }
    }
    /*
    if (fileSizeSum > batchProcessingMaxFileSizeInBytes) {
        setError({ text: t('batchProcessingErrorFileSize'), header: t('batchProcessingErrorHeader') });
        return false;
    }
*/
    return true
  }

  function handleDropDownMenu () {
    console.log('handleDropDownMenu')
  }

  function dialogHandleFunction (res) {
    setDialogOpen(false)
    if (res === true) {
      switch (dialogData?.type) {
        case 'handleWordDialog':
          break
        /*
        case 'handleModalAccordionOpen' :
            actionAnalyseSetModalAccordion(dialogData.state);
            handleOnAnalyseClick();
            //setMustReload(false);
        break;
        case 'handleTopAccordionOpen' :
            actionAnalyseSetTopAccordion(dialogData.state);
        handleOnAnalyseClick();
        // setMustReload(false);
        break;
        */
        case 'handleRestartAll':
          actionAnalyseClearIgnoreList()
          actionAnalyseClearResult()
          actionAnalyseClearPopovers()
          actionAnalyseClearSaveArchive()
          actionClearAccordionItemsWithCells()
          if (storageLanguageTextType.textType[textLanguage]) {
            actionAnalyseSetUseBenchmark(storageLanguageTextType.textType[textLanguage])
            actionAnalyseSetTextLanguage(storageLanguageTextType.language === 'auto' ? convertLanguagesToFull(i18nLanguage) : storageLanguageTextType.language)
          } else {
            if (!useBenchmark && textLanguage && !isObjectEmpty(benchmarks)) {
              const filteredBenchmark = (filterBenchmark(benchmarks,
                textLanguage, 'locale_name'))
              if (!isObjectsEqual(filteredBenchmark, enabledBenchmarks)) {
                actionAnalyseSetEnabledBenchmark(filteredBenchmark)
                if (isObjectEmpty(filteredBenchmark) &&
                  isObjectEmpty(storageLanguageTextType.textType))
                  actionAnalyseSetUseBenchmark(0)
              }
            } else {
              actionAnalyseSetUseBenchmark((!isObjectEmpty(enabledBenchmarks) ? parseInt(Object.keys(enabledBenchmarks)[0]) : 0))
            }
          }
          setPopupHeader('')
          setPopupText('')
          setPopupComponent(null)
          closePopupWindow()
          setCumulativeEditor({ top: 0, left: 0 })
          setCumulativeElement({ top: 0, left: 0 })
          setElementWidth(0)
          actionAnalyseSetTopAccordion('')
          actionAnalyseSetModalAccordion('')
          actionAnalyseSetBodyBorderShow(true)
          actionAnalyseSetText('')
          break
        default:
          handleOnAnalyseClick()
          break
      }
    }
  }

  function handleModalAccordionOpen (state) {
    if (resultTemplate > 0) {
      closePopupWindow()
      actionAnalyseSetModalAccordion(state)
      keepModalAccordion.current = state
      if (mustReload) {
        handleOnAnalyseClick()
      }
    }
  }

  function handleTopAccordionOpen (state) {
    if (resultTemplate > 0) {
      closePopupWindow()
      actionAnalyseSetModalAccordion('')
      actionAnalyseSetTopAccordion(state)
      keepTopAccordion.current = state
      keepModalAccordion.current = ''
      if (mustReload) {
        handleOnAnalyseClick()
      }
    }
  }

  //  position = -1 -> ignore everywhere
  function handleAddToIgnoreList (
    place, replacement, everywhereText, listName, element, position = -1) {
    //replaceInText(editor, popups, place, replacement, everywhereText);
    element = typeof element === 'string' ? [element] : element
    let newIgnoreList = deepCopy(ignoreList)
    if (newIgnoreList[listName] === undefined) {
      newIgnoreList[listName] = {
        everyWhereList: [],
        byPositionList: {},
      }
    }
    if (position === -1) {
      if (newIgnoreList[listName].everyWhereList.findIndex(
        value => isObjectsEqual(value, element)) === -1) {
        newIgnoreList[listName].everyWhereList.push(element)
      }
      newIgnoreList[listName].byPositionList = Object.entries(
        newIgnoreList[listName].byPositionList)
        .reduce((acc, [key, value]) => !isObjectsEqual(value, element) ? {
          ...acc,
          [key]: element,
        } : { ...acc }, {})
    } else if (!newIgnoreList[listName].everyWhereList[element]) {
      newIgnoreList[listName].byPositionList[position] = element
    }
    ignoreInText(editor, popups, place, replacement, everywhereText)
    actionAnalyseSetText(editor.getContent())
    actionAnalyseSetIgnoreList(newIgnoreList)
    // update editor

  }

  function handleChangeInText (
    place, replacement, everywhereText = '', type = 'negative') {

    switch (type) {
      case 'terminology':
      case 'linguisticClimate':
      case 'spell':
        replaceInText(editor, popups, place, replacement, everywhereText)
        break
      default:
        replaceInText(editor, popups, place, replacement, everywhereText)
    }
    actionAnalyseSetText(editor.getContent())
    console.log('handleChangeInText setMustReload(true)')
    setMustReload(true)
  }

  const proceed = () => {
    alertMsg.current = []
    setShowLoadText(false)
    runRV.current = true
    setShowUploadBar(true)
    setUploadBarNow(0)
    handleRVUpload()
    setErrorModal(false)
    setLoadBar(0)
  }

  const abort = () => {
    setErrorModal(false)
    alertMsg.current = []
    setShowLoadText(false)
    setLoadBar(0)
  }

  const closeIt = () => {
    setErrorModal(false)
    alertMsg.current = []
    setLoadBar(0)
  }
  /**VIEW**/
  return (
    <MainPageWrapper showDouble={true}>
      {submitted && <Spinner/>}
      <Modal className={style.errormodalBox} open={errorModal}
             onClose={() => closeIt()}>
        <div className={`${style.modalMainCont} pb-3`}
             style={{ backgroundColor: bgColors.bgPrimary0 }}>
          <div
            className={`${style.head} d-flex justify-content-between p-1 pl-3`}
            style={{ backgroundColor: bgColors.bgPrimary400 }}>
            <p className={`m-0 ${style.title}`}
               style={{ color: 'white', fontSize: '25px' }}>
              {t('Warning-Message-Same-Textbaustein_keys')}
            </p>
            <button className={`border-0 bg-transparent`}
                    onClick={() => closeIt()}>
              <img src={closeIcon} alt=""/>
            </button>
          </div>
          <br/>
          <div className={style.error}>
            <Collapse in={!!alertMsg.current?.length}>
              {alertMsg.current?.length && alertMsg.current.map((el, i) => {
                return <div key={i}><Alert
                  className={`m-0`}
                  severity="error">
                  <h6 className={`m-0`}>
                    {el}
                  </h6>
                </Alert>
                  <br/>
                </div>
              })
              }
            </Collapse>
          </div>
          <div style={{ display: 'flex' }}>
            <Button color="primary" variant="contained"
                    onClick={() => proceed()}
                    style={{ marginLeft: '40%', marginTop: '4%' }}>
              {t('forWarning_Proceed')}
            </Button>
            <Button color="primary" variant="contained" onClick={() => abort()}
                    style={{ marginLeft: '4%', marginTop: '4%' }}>
              {t('forWarning_Abort')}
            </Button>
          </div>
        </div>
      </Modal>

      {aiMode ? <AiAnalyse handleOnAnalyseClick={handleOnAnalyseClick} handleAnalyseFromAi={handleAnalyseFromAi}/>
        :
        <section className={`${style.mainCont} d-flex justify-content-center`}>
          <ErrorModal error={error} setError={setError}
                      setShowLoadText={setShowLoadText}
          />
          <div className={style.mainContEditor}>
            {resultTemplate > 0 ?
              <TopIndicatorsBar/> :
              <Fields
                handleOnTextTypeChange={handleOnTextTypeChange}
              />
            }
            <div style={{ backgroundColor: bgColors.bgPrimary0 }} className={style.textEditor}>
              <HeadPart
                loadBar={loadBar}
                setShowLoadText={setShowLoadText}
                handleStopRVAnalyse={handleStopRVAnalyse}
                showLoadText={showLoadText}
                checkKeys={checkKeys}
                selectedText={selectedText}
                editor={editor}
                handleDefaultClick={handleDefaultClick}
                handleDownloadAsFile={handleDownloadAsFile}
                handleShowBorders={handleShowBorders}
                handleRestartAll={handleRestartAll}
                handleOnAnalyseClick={handleOnAnalyseClick}
                handleAnalyseFromAi={handleAnalyseFromAi}
                mustReload={mustReload}
                setMustReload={setMustReload}
                handleUploadFile={handleUploadFile}
                handleDropDownMenu={handleDropDownMenu}
                handleBatchProcessingUpload={handleBatchProcessingUpload}
                showUploadBar={showUploadBar}
                uploadBarNow={uploadBarNow}
                closePopupWindow={closePopupWindow}
              />
              <TinyEditor
                text={text}
                editorId="first"
                handleOnSelectorChange={handleOnSelectorChange}
                handleOnEditorKeyUp={handleOnEditorKeyUp}
                handleOnEditorInit={handleOnEditorInit}
                handleOnEditorChange={handleOnEditorChange}
                handleOnMouseOver={handleOnMouseOver}
                handleOnMouseClick={handleOnMouseClick}
                tabIndex={config.tabIndexes.enterEditor}
              />
            </div>
            <Footer>
              <AnalyseFooter
                langTabIndex={config.tabIndexes.analysisLanguage}
                textTypeTabIndex={config.tabIndexes.analysisTextType}
                textTypeInputTabIndex={config.tabIndexes.analysisTextTypeInput}
                charactersCount={resultData?.countCharactersNoSpaces || 0}
                wordsCount={resultData?.countWords || 0}
                handleOnLanguageChange={handleOnLanguageChange}
                handleOnTextTypeChange={handleOnTextTypeChange}
              />
            </Footer>
          </div>
          <Tabs
            handleOnPopupClick={handleOnPopupClick}
            getNextPopup={getNextPopup}
            handleModalAccordionOpen={handleModalAccordionOpen}
            handleTopAccordionOpen={handleTopAccordionOpen}
            handleChangeAnalyseTextType={handleChangeAnalyseTextType}
            handleChangeAnalyseTonality={handleChangeAnalyseTonality}
            handleOnAnalyseClick={handleOnAnalyseClick}
            analyseButtonEnable={analyseButtonEnable}
            setAnalyseButtonEnable={setAnalyseButtonEnable}
            handleSaveAnalyseTonality={handleSaveAnalyseTonality}
          />
          {openModal && Object.entries(config.modalComponents)
            .map(([keyModal, valModal], index) => {
              if (popupComponent === keyModal) {
                const Component = valModal
                return <Fragment key={index}> <Component
                  keyModal={keyModal}
                  modalTitle={modalTitle}
                  innerKey={index}
                  handleChangeInText={handleChangeInText}
                  handleAddToIgnoreList={handleAddToIgnoreList}
                  elementWidth={elementWidth}
                  popupText={popupText}
                  setPopupText={setPopupText}
                  closePopupWindow={closePopupWindow}
                  popupHeader={popupHeader}
                  cumulativeEditor={cumulativeEditor}
                  cumulativeElement={cumulativeElement}
                /> </Fragment>
              }
              return null
            })}
        </section>

      }


      <ModalDialog
        handleClose={dialogHandleFunction}
        open={dialogOpen}
        title={dialogTitle}
        text={dialogText}
        yesText={dialogYesText}
        noText={dialogNoText}
      />
      {/*open={batchModalOpen}*/}
      <BatchModal open={batchModalOpen}
                  setOpen={setBatchModalOpen}
                  files={batchModalFiles}
                  error={error}
                  setError={setError}
      />
    </MainPageWrapper>
  )
}
