import React, { useEffect, useState } from 'react'
import style from './style.module.sass'
import AiHead from './AiHead'
import TinyEditor from '../TinyEditor'
import Footer from '../Footer/Footer'
import AnalyseFooter from './AnalyseFooter'
import ErrorModal from './ModalWindows/ErrorModal'
import { useTheme } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { useActions } from '../../utils/action-helper'
import { analyseSetAiLocalResult, analyseSetAiMode, analyseSetAutoTextLanguage, analyseSetLocalResult, analyseSetOldText, analyseSetText, analyseSetTextLanguage, analyseSetUseBenchmark } from '../../actions'
import TLServiceComponent from '../../contexts/TLServiceComponent'
import { ChevronRight, ChevronLeft, InsertDriveFile, Check } from "@material-ui/icons";
import {
  checkAiText,
  checkTextSize,
  convertJSONToObject,
  convertUTCDateToLocalDate,
  isObjectEmpty,
  printConvertedDate,
  setLanguage,
  stripHtml,
  textClear
} from '../../utils'
import TopIndicatorsBarAi from './TopIndicatorsBar/TopIndicatorsBarAI'
import { startAnalyse } from './analysisFunctions'
import config from '../../config'
import { useTranslation } from 'react-i18next'
import ComponentWithPop from '../common/ComponentWithPop'
import ComBotIconBlack from '../../assets/combot-icon-black.svg'
import ComBotBlack from '../../assets/combot-black.svg'
import Summ from '../../assets/summ-small-without-text.png'
import RoboSpinner from '../RoboSpinner'


export default function AiAnalyse ({handleOnAnalyseClick, handleAnalyseFromAi}) {

  const { t, i18n: { language: i18nLanguage } } = useTranslation()
  const tlService = React.useContext(TLServiceComponent)

  const token = useSelector(state => state.userData.token)
  //const resultData = useSelector(state => state.analyseData.result.resultData || {})
  //const currentBenchmarkSettings = useSelector(state => state.analyseData.result.benchmark)
  //const targetHix = currentBenchmarkSettings?.settings?.formulaHix?.values?.target || 0
  //const enabledBenchmarks = useSelector(state => state.analyseData.enabledBenchmarks)
  const useBenchmark = useSelector(state => state.analyseData.useBenchmark)
  const textLanguage = useSelector(state => state.analyseData.textLanguage)
  const benchmarks = useSelector(state => state.userData.benchmarks)
  const aiMode = useSelector(state => state.analyseData.aiMode)
  const enabledBenchmarks = useSelector(state => state.analyseData.enabledBenchmarks)
  const aiMaxTextSize = useSelector((state) => state.userData.user.ai_settings?.max_text_size || {})
  const userRedirects = convertJSONToObject(useSelector((state) => state.userData?.user?.redirect), config.defaultUserRedirects)
  const enabledAIButtons = Object.fromEntries(Object.entries(userRedirects).map(([key, value]) => [key.replace('redirect/', ''), value]));
  const enabledSections = useSelector(state => state.userData.user?.enabled_frontend_sections || [])

  const {
    actionAnalyseSetText, actionAnalyseSetOldText, aAnalyseSetAiMode, aAnalyseSetAiLocalResult, aAnalyseSetLocalResult, actionAnalyseSetAutoTextLanguage, actionAnalyseSetTextLanguage, actionAnalyseSetUseBenchmark
  } = useActions({
    actionAnalyseSetText: analyseSetText, actionAnalyseSetOldText: analyseSetOldText,  aAnalyseSetAiMode: analyseSetAiMode, aAnalyseSetLocalResult: analyseSetLocalResult, aAnalyseSetAiLocalResult: analyseSetAiLocalResult, actionAnalyseSetAutoTextLanguage: analyseSetAutoTextLanguage, actionAnalyseSetTextLanguage: analyseSetTextLanguage, actionAnalyseSetUseBenchmark: analyseSetUseBenchmark
  })
  const text = useSelector(state => state.analyseData.text)
  const reduxCurrentBenchmarkSettings = useSelector(state => state.analyseData.result.benchmark)
  const localResult = useSelector(state => state.analyseData.result.localAnalyseResultData)
  const localResultAI = useSelector(state => state.analyseData.result.aiLocalAnalyseResultData)
  const storageLanguageTextType = convertJSONToObject(
    useSelector(
      (state) => state.userData?.user?.user_options?.analyse_language_texttype
    ),
    config.defaultAnalyseLanguageTextType
  )
  const { theme: { bgColors, colors } } = useTheme()
  const [translatedText, setTranslatedText] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [error, setError] = useState(null)
  const [enableButtons, setEnableButtons] = useState(false)
  const [enableButtonBack, setEnableButtonBack] = useState(false)
  const [percent, setPercent] = useState(0)
  const [localBenchmark, setLocalBenchmark] = useState(reduxCurrentBenchmarkSettings)
  const [localBenchmarkAI, setLocalBenchmarkAI] = useState({})
  //const [analyseButtonEnable, setAnalyseButtonEnable] = useState(false)
  const [reAnalyse, setReAnalyse] = useState(false)
  const [useAi, setUseAi] = useState(false)
  const [useSumm, setUseSumm] = useState(false)
  const [summUsageData, setSummUsageData] = useState([])
  const [isSummAiDisabled, setIsSummAiDisabled] = useState(true)

  const showSumm = !useAi && useSumm
  const showNormal = !useAi && !useSumm

  const oldText = useSelector(state => state.analyseData.oldText)

  const fetchSummStatus = async () => {
    if(userRedirects.SummUsage) {
      try {
        const res = await tlService.runRedirect(token, {}, 'SummUsage')
        if (res?.data) {
          setSummUsageData(res.data);
        }
      } catch (e) {
        setError({ text: t('summ_ai_timeout_error'), header: t('summ_ai_header_timeout_error') })
        setPercent(0)
        setSubmitted(false)
        return false
      }
    }
  }

  useEffect(() => {
    if(enabledAIButtons?.Summ && enabledSections.includes('ai_summ') && enableButtons) {
      fetchSummStatus()
    }
  }, [enableButtons])

  const regEmpty = /\w/
  useEffect(() => {
    if (isObjectEmpty(enabledBenchmarks) || !regEmpty.test(text)) {
      setEnableButtons(false);
    } else {
      setEnableButtons(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabledBenchmarks, text]);

  useEffect(() => {
    if (regEmpty.test(translatedText)) {
      setEnableButtonBack(true)
    } else {
      setEnableButtonBack(false)
    }

  }, [translatedText])

  useEffect(() => {
    // reanalyse
    if (reAnalyse){
      setReAnalyse(false)
      handleOnAnalyseClick()
    }
  }, [text])

  useEffect(() => {
    if (!isObjectEmpty(summUsageData)) {
      const remainingCharacters = summUsageData.character_limit - summUsageData.character_spent
      const characterExcess = characterCount(text) - remainingCharacters
      const isLicenseExpired = summUsageData.license_end_date ? new Date(summUsageData.license_end_date) < new Date() : false
      setIsSummAiDisabled(characterExcess > 0 || !enableButtons || isLicenseExpired)
    }
  }, [text, summUsageData, enableButtons])

  
  const localAnalyse = async (clearText, ai = false) => {
    if (useBenchmark === 0) {
      //setAnalyseButtonEnable(false)
      return false
    }

    const checkedSizeError = checkTextSize(config, clearText.length,
      stripHtml(clearText).length)
    if (checkedSizeError) {
      console.log('checkedSizeError error ')
      setError({ text: t(checkedSizeError), header: t(checkedSizeError) })
      return false
    }
    let result
    try {
      result = await startAnalyse(useBenchmark, textLanguage, clearText,
        tlService, token, t)
    } catch (e) {
      console.log('error',e)
      return false
    }
    result.error && console.log('result.error ', result.error)  
    setError(result.error)
    if (ai) {
      aAnalyseSetAiLocalResult(result.resultData)
      setLocalBenchmarkAI(benchmarks[result.resultTemplate])
    }
    else {
      aAnalyseSetLocalResult(result.resultData)
      setLocalBenchmark(benchmarks[result.resultTemplate])
      setSubmitted(false)
    }
  }
  
  const handleOurAi = async (textOriginal) => {
    setPercent(0)
    setSubmitted(true)
    setError(null)
    const text = textClear(textOriginal)

    //let benchmarkSettings = {}
    let customPrompt = ''
    if (useBenchmark > 0){
      if(benchmarks[useBenchmark]?.settings?.customPrompt?.values?.enabled){
        customPrompt = benchmarks[useBenchmark]?.settings?.customPrompt?.values?.prompt || ''
        customPrompt = customPrompt.trim()
        if (typeof customPrompt === 'string' && customPrompt.startsWith('[') && customPrompt.endsWith(']')) {
          customPrompt = JSON.parse(customPrompt)
        }
      }
    }

    if (!checkAiText(text, aiMaxTextSize, 'Assistant')) {
      setError({ text: t('ai_text_too_long'), header: t('ai_header_text_too_long') })
      setPercent(0)
      setSubmitted(false)
      return false
    }

    let textResult = text
    if (textResult?.length) {
      let promptToSend = customPrompt;
      if (typeof customPrompt === 'string') {
        promptToSend = [customPrompt] 
      }  
      const history = [] 
      for (const promptToSendElement of promptToSend) {
        if (promptToSendElement.length){
          try {
            const res = await tlService.runRedirect(token, { body: { user: textResult, system: promptToSendElement, history } }, 'Assistant')
            if (res?.data?.text) {
              history.push([promptToSendElement + ' ' + textResult, res?.data?.text])
              textResult = res.data.text.replace(/^\n/, '').replace(/\n$/, '')
            }

          } catch (e) {
            console.log('handleOurAi', e)
            setError({ text: t('ai_timeout_error'), header: t('ai_header_timeout_error') })
            setPercent(0)
            setSubmitted(false)
            return false
          }
        }
      }
    }

    const arrText = textResult.split('\n\n')

    let res = ''
    for (const t of arrText) {
      res += `<p>${t.replaceAll(/\n/g, '<br />')}</p>`
    }


    setTranslatedText(res)

    // run analyse
    await localAnalyse(res, true)
    await localAnalyse(textOriginal)


    setPercent(0)
    setSubmitted(false)
    setUseAi(true)
    setUseSumm(false)
    return true
  }

  const handleSumm = async (textOriginal) => {
    setPercent(0)
    setSubmitted(true)
    setError(null)
    textOriginal = textClear(textOriginal)
    let resText 
    try {
      const res = await tlService.runRedirect(token, { body: { 'input_text': textOriginal } }, 'Summ')
      if (res?.data?.translated_text) {
        resText = res.data.translated_text.replaceAll(/\n/g, '<br>')
      }
    } catch (e) {
      setError({ text: t('summ_ai_timeout_error'), header: t('summ_ai_header_timeout_error') })
      setPercent(0)
      setSubmitted(false)
      return false
    }
    const arrText = resText.split('\n\n')

    let res = ''
    for (const t of arrText) {
      res += `<p>${t.replaceAll(/\n/g, '<br />')}</p>`
    }

    res = res.replaceAll(/\n/g,'<br>')

    setTranslatedText(res)

    // run analyse
    await localAnalyse(res, true)
    await localAnalyse(text)

    setPercent(0)
    setSubmitted(false)
    setUseSumm(true)
    setUseAi(false)
    return true
  }
  function handleOnLanguageChange (value) {
    actionAnalyseSetTextLanguage(value)
    }
  
  function setAutoLanguage (text) {
    const lang = setLanguage(text)
    if (lang) {
      actionAnalyseSetAutoTextLanguage(lang)
      if (lang !== textLanguage) {
        handleOnLanguageChange(lang)
      }
    }
  }
  const handleOnEditorChangeFirst = (newText) => {
    
    newText = newText.replace(/\xAD/g, '')
    actionAnalyseSetText(newText)
    if (storageLanguageTextType?.language === 'auto') {
      setAutoLanguage(newText)
    }
  }

  const handleOnEditorChangeSecond = (newText) => {
    newText = newText.replace(/\xAD/g, '')
    setTranslatedText(newText)
  }

  const handleOnEditorInitFirst = (editor) => {
    editor.target.setContent(text)
  }
  
  const characterCount = (text) => {
   return textClear(text).length 
  }

  const handleAcceptLeftText = () => {
    actionAnalyseSetText(text)
    handleAnalyseFromAi(text);
    aAnalyseSetAiMode(!aiMode)
  }

  const handleDiscardText = () => {
    if(oldText?.length){
    actionAnalyseSetText(oldText)
    handleAnalyseFromAi(oldText);
    }
    aAnalyseSetAiMode(!aiMode)
  }

  const handleTakeSuggestion = () => {
    actionAnalyseSetText(translatedText)
    actionAnalyseSetOldText(translatedText)
    handleAnalyseFromAi(translatedText);
    aAnalyseSetAiMode(!aiMode)
  }

  const handleSendBack = () => {
    actionAnalyseSetText(translatedText)
    localAnalyse(translatedText)
    localAnalyse(translatedText, true)
    setReAnalyse(true)
  }

  const handleRefreshAnalyseAi = () => {
    localAnalyse(translatedText, true)
  }

  const handleRefreshAnalyse = () => {
    localAnalyse(text)
  }

  function handleOnTextTypeChange (value) {
    if (!isObjectEmpty(enabledBenchmarks) && value !== useBenchmark &&
      enabledBenchmarks[value] !== undefined) {
      actionAnalyseSetUseBenchmark(value)
    }
  }

  const summLicenseData = () => {
    let res = ''
    if (!isObjectEmpty(summUsageData)) {
      const remainingCharacters = summUsageData.character_limit - summUsageData.character_spent
      if (remainingCharacters < characterCount(text)) {
        res += `${t('ai_exceeds_characters{{num}}', { num: characterCount(text) - remainingCharacters })}`
      } else {
        res += `${t('ai_remains_characters{{num}}', { num: remainingCharacters })}`
      }
      if (summUsageData.license_active) {
        if (summUsageData.license_end_date) {
          const licence_date = printConvertedDate(convertUTCDateToLocalDate(summUsageData.license_end_date), i18nLanguage)
          if (new Date(summUsageData.license_end_date) > new Date()) {
            res += `${summUsageData.license_end_date ? t('ai_summ_valid_to{{date}}', { date: licence_date }) : ''}`
          } else {
            res += ` ${t('ai_summ_expired{{date}}', { date: licence_date })}`
          }
        }
      } else {
        res += t('ai_summ_inactive_license')
      }
    }
    return res
  }

  return (
    <section className={`${style.mainCont} d-flex justify-content-center`}>
      {percent > 0 && <div className={style.percentage}><strong>{percent}%</strong></div>}
      {submitted &&  <RoboSpinner text={t('ai_spinner_text')}/>}
      <ErrorModal error={error} setError={setError}/>
      <div className={style.mainContEditor}>
        <AiHead handleDiscardText={handleDiscardText} />
        <div style={{ backgroundColor: bgColors.bgPrimary0, paddingTop: 10 }} className={style.textEditor}>
          {
              enableButtons && (
                <div className='d-flex justify-content-between p-2'>
                    <div style={{margin:"2px 3px"}}>
                      {<TopIndicatorsBarAi extResultData={localResult} extCurrentBenchmarkSettings={localBenchmark} handleRefresh={handleRefreshAnalyse} tabIndex={config.tabIndexes.aiRefreshButtonLeft} />}
                    </div>
                 
                    <div style={{margin:"2px 3px"}}>
                      {enableButtonBack && <TopIndicatorsBarAi extResultData={localResultAI} extCurrentBenchmarkSettings={localBenchmarkAI} handleRefresh={handleRefreshAnalyseAi} refreshBtnLeft tabIndex={config.tabIndexes.aiRefreshButtonRight}  />}
                    </div>
                </div>
              )
            }
            <div className='d-flex justify-content-between'>
              <div style={{ display: 'table-cell', width: "48%", position: 'relative' }} className='mr-1'>
                <TinyEditor
                  initialValue=""
                  text={text}
                  editorId="first"
                  handleOnSelectorChange={() => {}}
                  handleOnEditorKeyUp={() => {}}
                  handleOnEditorInit={handleOnEditorInitFirst}
                  handleOnEditorChange={handleOnEditorChangeFirst}
                  handleOnMouseOver={() => {}}
                  handleOnMouseClick={() => {}}
                  tabIndex={config.tabIndexes.aiEditorLeft}
                />
                <div style={{ position: "absolute", top: 8, right: 10, zIndex: 2 }} onClick={handleAcceptLeftText} tabIndex={enableButtons ? config.tabIndexes.aiAcceptLeft : -1}>
                      <ComponentWithPop text={`${t('ai_accept_text')}`}>
                      <button disabled={!enableButtons} style={{ backgroundColor: enableButtons ? "#71a141" : bgColors.bgPrimary400 }} className='d-flex align-items-center px-2 py-1' >
                    <Check style={{ cursor: enableButtons ? "pointer" : "inherit", color: bgColors.bgPrimary0, fontSize: 30 }} />
                      </button>
                 </ComponentWithPop>
                </div>
                 </div>

              <div style={{ display: "table-cell", marginTop: 50 }} >
              {(enabledAIButtons?.Assistant && enabledSections.includes('ai_assistant')) &&
              <div className='mb-1' onClick={() => handleOurAi(text)} tabIndex={enableButtons ? config.tabIndexes.aiRunAinstein : -1}>
                <ComponentWithPop text={`${t('start-our-ai')}`} >
                  {/* eslint-disable-next-line jsx-a11y/no-access-key */}
                  <button disabled={!enableButtons} style={{ backgroundColor: enableButtons ? bgColors.bgBlue1000 : bgColors.bgPrimary400, borderRadius: "5px" }} className='d-flex align-items-center pl-1 pr-1' accessKey={config.accessKeys.runAnalyse}>
                    <img style={{ cursor: enableButtons ? "pointer" : "inherit" }} src={ComBotIconBlack} height={40} width={40} alt="ComLab AI" />

                    <ChevronRight style={{ cursor: enableButtons ? "pointer" : "inherit", color: bgColors.bgPrimary0, fontSize: 40, marginLeft: -12, marginRight: -12 }} />

                  </button>
                </ComponentWithPop>
              </div>
              }
              {(enabledAIButtons?.Summ && enabledSections.includes('ai_summ')) && <div onClick={() => handleSumm(text)} tabIndex={!isSummAiDisabled ? config.tabIndexes.aiRunSumm : -1}>
                  <ComponentWithPop html={true} text={`${t('start-summ-ai')} ${summLicenseData()}`}>
                    {/* eslint-disable-next-line jsx-a11y/no-access-key */}
                    <button disabled={ isSummAiDisabled } style={{ backgroundColor: !isSummAiDisabled ? bgColors.bgProgress60 : bgColors.bgPrimary400, borderRadius: "5px", paddingLeft: "7px" }} className='d-flex align-items-center' accessKey={config.accessKeys.summAi}>
                      <img style={{ cursor: !isSummAiDisabled ? "pointer" : "inherit" }} src={Summ} height={26} width={35} alt="Summ AI" />
                      <ChevronRight style={{ cursor: !isSummAiDisabled ? "pointer" : "inherit", color: bgColors.bgPrimary0, fontSize: 40, marginLeft: -9, marginRight: -9 }} />
                    </button>
                  </ComponentWithPop>
                </div> 
              }
              <div className='mt-4' onClick={handleSendBack} tabIndex={enableButtonBack ? config.tabIndexes.aiMoveTextLeft : -1}>
                <ComponentWithPop text={`${t('start-send-text-back')}`}>
                  <button disabled={!enableButtonBack} style={{ backgroundColor: enableButtonBack ? "#71a141" : bgColors.bgPrimary400, borderRadius: "5px", cursor: enableButtonBack ? "pointer" : "inherit" }} className='d-flex align-items-center pr-1'>
                    <ChevronLeft style={{ color: bgColors.bgPrimary0, fontSize: 40, marginRight: -4, marginLeft: -4 }} />
                    <InsertDriveFile style={{ fontSize: 35, marginLeft: -8 }} />
                  </button>
                </ComponentWithPop>
              </div>
              </div>
              <div style={{ display: 'table-cell', width: "48%", position: "relative" }} className='ml-1'>
                <TinyEditor
                  initialValue=""
                  text={translatedText}
                  editorId="second"
                  handleOnSelectorChange={() => {}}
                  handleOnEditorKeyUp={() => {}}
                  handleOnEditorInit={() => {}}
                  handleOnEditorChange={handleOnEditorChangeSecond}
                  handleOnMouseOver={() => {}}
                  handleOnMouseClick={() => {}}
                  tabIndex={-1}
                />
                 {
                  <div style={{ position: "absolute", top: 8, right: 10, zIndex: 2 }} className='d-flex'>
                   { !showNormal &&  <div className='align-self-end mx-2'> 
                    <span style={{ color: bgColors.bgPrimary400 }}>{t("ai_generate_from")}  </span>
                    {
                      showSumm ? <img style={{ marginLeft: 5 }} src={Summ} height={24} width={26} alt="" /> : <img style={{ marginLeft: 3, paddingBottom: 5 }} src={ComBotBlack} height={26} width={30} alt="" />
                    }
                    </div>}
                    {
                    <div onClick={handleTakeSuggestion} tabIndex={enableButtonBack ? config.tabIndexes.aiAcceptRight : -1} > 
                    <ComponentWithPop text={`${t('ai_accept_text')}`}>
                      <button disabled={!enableButtonBack} style={{ backgroundColor: enableButtonBack ? "#71a141" : bgColors.bgPrimary400 }} className='d-flex align-items-center px-2 py-1'>
                        <Check style={{ cursor: enableButtonBack ? "pointer" : "inherit", color: bgColors.bgPrimary0, fontSize: 30 }} />
                      </button>
                    </ComponentWithPop>
                    </div>
                    }
                  </div>
                }
              </div>
            </div>
        
        </div>
        <div style={{ color: colors.colorPrimary300 }} className='row ml-1 pt-1'>
          <div className='col'>
            <p >{t("Characters")}: <b
              style={{ color: colors.colorProgress20 }}
            >{characterCount(text)}</b>
            </p>
          </div>
          <div className='col ml-5'>

            <p >{t("Characters")}: <b
              style={{ color: colors.colorProgress20 }}
            >{characterCount(translatedText)}</b>
            </p>

          </div>
        </div>
        <Footer>
        <AnalyseFooter
                langTabIndex={config.tabIndexes.aiLanguage}
                textTypeTabIndex={config.tabIndexes.aiTextType}
                textTypeInputTabIndex={config.tabIndexes.aiTextTypeInput}
                handleOnLanguageChange={handleOnLanguageChange}
                handleOnTextTypeChange={handleOnTextTypeChange}
              />
        </Footer>
      </div>
    </section>
  )
}