import { useTheme } from '@material-ui/styles'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import style from './style.module.sass'

const TextTypeCard = ({ title, img, texttype, handleCardSelection, selectedBenchmark, selectedCardId, card, tabIndex }) => {
    const { t } = useTranslation()
    const { theme: { bgColors, borderColors, colors } } = useTheme()
    const [isHovered, setIsHovered] = useState(false)

    const isSelected = selectedCardId === card.id
   
    const cardStyle = {
        borderColor: borderColors.borderPrimary300,
        backgroundColor: isHovered ? bgColors.bgBlue300 : (isSelected ? bgColors.bgBlue1000 : bgColors.bgPrimary0),
    }

    const handleClick = () => {
        handleCardSelection(texttype, card.id)
    }

    const handleMouseEnter = () => {
        setIsHovered(true)
    }

    const handleMouseLeave = () => {
        setIsHovered(false)
    }

    return (
        <div style={cardStyle}
            className={`${style.card} pl-3 d-flex align-items-center mb-2`}
            onClick={handleClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            tabIndex={tabIndex}
            title={t(`accessibility_gen_${title}`)}
            >
            <div style={{ backgroundColor: bgColors.bgBlue1000, width: '42px', height: '42px' }} className={`mr-3 rounded-circle d-flex align-items-center justify-content-center`}>
                <img
                    src={img}
                    alt="ComLab AI Bot"
                    width="28px"
                    height="28px"
                    className='rounded-circle'
                />
            </div>
            <div style={{ color: isSelected ? colors.colorPrimary0 : colors.colorPrimary800}} className={`m-0 ${style.title}`}>
                {t(title)}
            </div>
        </div>
    )
}

export default TextTypeCard
