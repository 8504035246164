import { useTranslation } from "react-i18next";
import { useTheme } from "@material-ui/core/styles";
import AccordionItem from "../AccordionItem";
import { chooseColor, filterByHIXCLIXFlesch, getScale, getValue, roundWithPrecision } from "../../../../../utils";
import AccordionModalDefault from "../AccordionModal/AccordionModalDefault";
import React,{useRef} from "react";
import { useSelector } from "react-redux";
import config from "../../../../../config";

export default function LinguisticClimate({
    handleModalAccordionOpen,
    handleTopAccordionOpen,
    handleOnPopupClick,
    getNextPopup,
    index,
    filter
}) {
    const { t } = useTranslation();
    const { theme: { colors } } = useTheme();
    const resultData = useSelector(state => state.analyseData.result.resultData);
    const currentBenchmarkSettings = useSelector(state => state.analyseData.result.benchmark);
    const displayColor = useRef();

    const themeColors = {
        red: colors.colorProgress20,
        yellow: colors.colorProgress60,
        green: colors.colorProgress100,
        blue: colors.colorProgress120
    };
    const allColors = {
        colorBlue: "rational",
        colorGreen: "konservativ",
        colorYellow: "innovative",
        colorRed: "emotional"
    }
    const allThemeColors = {
        colorBlue: 'blue',
        colorGreen: 'green',
        colorYellow: 'yellow',
        colorRed: 'red',
    }

    let topColor = 'green';
    let num = 0

    const cells = Object.entries(allColors)
        .reduce((acc, [valueName, title]) => {

            if(resultData[valueName] === undefined){
                return [...acc]
            }

            const localSettings = currentBenchmarkSettings?.settings[valueName]?.values;
            const localTarget = getValue(localSettings, "target", 50);
            //const localThreshold = getValue(localSettings, "threshold", 50);
            const localTargetYellowGreen = getValue(localSettings, "yellowGreen", 50);
            const localTargetYellowRed = getValue(localSettings, "yellowRed", 50);
            const localScaleFrom = getValue(localSettings, "scaleFrom", 0);
            const localScaleTo = getValue(localSettings, "scaleTo", 100);
            const fullValueCount = config.fullCountBase[valueName] !== undefined ? config.fullCountBase[valueName] : "countWords";
            const localPercent = resultData[valueName] / (resultData[fullValueCount] / 100);
            const localColor = chooseColor(localPercent, localTargetYellowGreen, localTargetYellowRed);

            // convert real % into  scale size percent
            const [scalePercent, targetPercent] = getScale(localPercent, localTarget, localScaleFrom, localScaleTo);
            // color filter
            // if (!filter[localColor])
            //     return [...acc];
            // cilx + hix filter
            if (filterByHIXCLIXFlesch(filter, currentBenchmarkSettings?.settings[valueName]?.values?.clix, valueName))
                return [...acc];

            num += resultData[valueName] || 0;
            topColor = localColor === 'red' ? 'red' : (localColor === 'yellow' && topColor !== 'red') ? 'yellow' : topColor;

            return [
                ...acc,
                {
                    toolTip: t('linguisticClimate_' + valueName + '_tooltip'),
                    colorBar: themeColors[localColor],
                    colorTop: themeColors[allThemeColors[valueName]],
                    id: 'LinguisticClimate_' + valueName,
                    num: roundWithPrecision(localPercent, 0),
                    target: localTarget,
                    targetTriangle: targetPercent,
                    actual: roundWithPrecision(localPercent, 1),
                    scaleWidth: roundWithPrecision(scalePercent, 1),
                    title: t(title)
                }
            ]
        }, [])

        // const percent = num / (resultData.countWords / 100);
        const percent = cells.length > 0 ? cells.reduce((acc, el) => acc + el.num, 0) : 0;

        if (cells.length === 0)
            return null;

   // text types that do not have color words
    cells?.length && cells.map(el => {
        if (el.num !== 0) {
            displayColor.current = true
        }
    })

    return (<>
          {displayColor.current ?  <AccordionItem
            color={themeColors[topColor]}
            number={roundWithPrecision(percent)}
            title={t("Linguistic climate")}
            index={index}
            handleTopAccordionOpen={handleTopAccordionOpen}
            windowsKey={'LinguisticClimate'}
        >
            <AccordionModalDefault
                title={t("Linguistic climate")}
                handleOnPopupClick={handleOnPopupClick}
                getNextPopup={getNextPopup}
                handleModalAccordionOpen={handleModalAccordionOpen}
                cells={cells}
                windowsKey={'LinguisticClimate'}
            />
        </AccordionItem> :
        <div/>}
    </>
    )
}