import style from "./style.module.sass"
import Button from "@material-ui/core/Button"
import React from "react"
import { useTranslation } from "react-i18next"
import comBotWhite from '../../../../../assets/combot-white.svg'
import comBotGreen from '../../../../../assets/combot-green.svg'
import config from "../../../../../config"

export default function TextGenButton({
    handleOnAnalyseClick,
    analyseButtonEnable,
}) {
    const { t } = useTranslation()
    return (
        <div className={`${style.mainCont}`}>
            <div className={`${style.selectCont}`} onClick={() => {if (analyseButtonEnable) { handleOnAnalyseClick()}}}   title={t('accessibility_gen_ai_button')} tabIndex={analyseButtonEnable ? config.tabIndexes.genNewGenButton : -1}>
                <Button 
                    tabIndex={-1}
                    disabled={!analyseButtonEnable}
                    className={`${style.loader} ${analyseButtonEnable && style.active}`}>
                    <div className={style.figure}>
                        {analyseButtonEnable ? <img src={comBotWhite} alt={''}/> : <img src={comBotGreen} alt={''}/>}
                    </div>
                    <h4 className={`${analyseButtonEnable && style.activeText}`}>{t("ai_gen_generate-text-button")}</h4>
                </Button>
            </div>
        </div>

    )
}